import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { COUNTIES } from '@/lib/counties'
import { STATES } from '@/lib/states'
import { Option } from '@/types'
import { INIT, UPDATE_CORE, UPDATE_DATA } from '@jsonforms/core'
import { JsonForms } from '@jsonforms/react'
import { Cross1Icon } from '@radix-ui/react-icons'
import { toPairs, values } from 'ramda'
import { FormEvent, useCallback, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { z } from 'zod'
import { renderers } from './json-forms/json-form-renderer'

export const propertyAgentSchema = z.object({
  state: z.string().optional(),
  county: z.string().optional(),
  cbg_population: z.string().optional(),
  median_household_income: z.string().optional()
})

export type PropertyAgentFormValue = z.infer<typeof propertyAgentSchema>

type Props = {
  form: UseFormReturn<PropertyAgentFormValue>
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
}

const stateOptionList: Option[] = STATES.map((item) => ({ value: item.OfficialUSPSCode, label: item.Name }))

const cbgPopulationOptionList: Option[] = [
  { value: '0-500', label: '0-500' },
  { value: '500-1000', label: '500-1,000' },
  { value: '1000-5000', label: '1,000-5,000' },
  { value: '5000-10000', label: '5,000-10,000' },
  { value: '10000-20000', label: '10,000-20,000' },
  { value: '20000-50000', label: '20,000-50,000' },
  { value: '50000-1000000', label: '50,000+' }
]

const medianHouseholdIncomeOptionList: Option[] = [
  { value: '0-9999', label: '$0-$9,999' },
  { value: '10000-19999', label: '$10,000-$19,999' },
  { value: '20000-29999', label: '$20,000-$29,999' },
  { value: '30000-39999', label: '$30,000-$39,999' },
  { value: '40000-49999', label: '$40,000-$49,999' },
  { value: '50000-59999', label: '$50,000-$59,999' },
  { value: '60000-74999', label: '$60,000-$74,999' },
  { value: '75000-99999', label: '$75,000-$99,999' },
  { value: '100000-124999', label: '$100,000-$124,999' },
  { value: '125000-149999', label: '$125,000-$149,999' },
  { value: '150000-174999', label: '$150,000-$174,999' },
  { value: '175000-199999', label: '$175,000-$199,999' },
  { value: '200000-224999', label: '$200,000-$224,999' },
  { value: '225000-249999', label: '$225,000-$249,999' },
  { value: '250000-274999', label: '$250,000-$274,999' },
  { value: '275000-299999', label: '$275,000-$299,999' },
  { value: '300000-324999', label: '$300,000-$324,999' },
  { value: '325000-349999', label: '$325,000-$349,999' },
  { value: '350000-374999', label: '$350,000-$374,999' },
  { value: '375000-399999', label: '$375,000-$399,999' },
  { value: '400000-100000000', label: '$400,000+' }
]

export function PromptBuilderPropertyAgentForm(props: Props) {
  const [value, setValue] = useState<PropertyAgentFormValue>(props.form.getValues())

  const countyOptionList: Option[] = COUNTIES.filter((item) => item.StateCode === value['state']).map((item) => ({
    value: String(item.County).replaceAll(' ', '_'),
    label: item.County
  }))

  const schema = {
    properties: {
      state: {
        type: 'string'
      },
      county: {
        type: 'string'
      },
      cbg_population: {
        type: 'string'
      },
      median_household_income: {
        type: 'string'
      }
    }
  }

  const uischema = {
    type: 'VerticalLayout',
    elements: [
      {
        type: 'AccordionLayout',
        label: 'Geography',
        elements: [
          {
            type: 'VerticalLayout',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/state',
                label: 'State',
                placeholder: 'Select a state',
                options: stateOptionList
              },
              {
                type: 'Control',
                scope: '#/properties/county',
                label: 'County',
                placeholder: 'Select a county',
                options: countyOptionList
              }
            ]
          }
        ]
      },
      {
        type: 'AccordionLayout',
        label: 'Demographics',
        elements: [
          {
            type: 'VerticalLayout',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/cbg_population',
                label: 'Census Block Group Population',
                placeholder: 'Select a population for the census block group',
                options: cbgPopulationOptionList
              },
              {
                type: 'Control',
                scope: '#/properties/median_household_income',
                label: 'Median Household Income',
                placeholder: 'Select a median household income',
                options: medianHouseholdIncomeOptionList
              }
            ]
          }
        ]
      }
    ]
  }

  const middleware = useCallback((state: any, action: any, defaultReducer: any) => {
    const newState = defaultReducer(state, action)
    switch (action.type) {
      case INIT:
      case UPDATE_CORE:
      case UPDATE_DATA: {
        // reset county if state was changed
        if (state.data['state'] !== newState.data['state']) {
          newState.data['county'] = ''
        }
        return newState
      }
      default:
        return newState
    }
  }, [])

  return (
    <div>
      <div className="flex flex-col sm:flex-row sm:justify-between">
        <div className="font-semibold mb-4">What are the properties you are interested in?</div>
        <Accordion type="multiple">
          <AccordionItem value="1" className="border-b-0">
            <AccordionTrigger className="flex gap-2 py-0">
              Selected
              <Badge>{values(value).filter(Boolean).length}</Badge>
              <div className="flex-grow" />
            </AccordionTrigger>
            <AccordionContent className="border-b-0 flex flex-col gap-2">
              <ul>
                {toPairs(value)
                  .filter((item): item is NonNullable<typeof item> => Boolean(item?.[1]))
                  .map((item) => (
                    <li key={item[0]} className="flex gap-2 items-center capitalize">
                      {/* transform to camel case from snake case */}
                      {item[0].replaceAll('_', ' ')} - {item[1]}
                      <Button
                        size="sm"
                        variant="ghost"
                        onClick={() => {
                          setValue({
                            ...value,
                            [item[0]]: ''
                          })
                        }}
                      >
                        <Cross1Icon />
                      </Button>
                    </li>
                  ))}
              </ul>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <JsonForms
        data={value}
        schema={schema}
        uischema={uischema}
        renderers={renderers}
        middleware={middleware}
        onChange={(data) => {
          console.log('onChange')
          setValue(data.data)
          props.form.reset(data.data)
        }}
      />
    </div>
  )
}
