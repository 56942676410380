import { Card, CardContent, CardDescription, CardHeader, CardTitle } from './ui/card'
import { Separator } from './ui/separator'
import LoginHeroImg from '@/assets/login-hero@2x.png'
import Logo from '@/assets/logo-rect.svg'

type AuthPageLayoutProps = {
  children: React.ReactNode
  title: string
  description?: string
}

export function AuthPageLayout({ children, title, description }: AuthPageLayoutProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 h-screen bg-purple-light w-full px-1 py-1 md:px-16 md:py-16">
      <Card className="h-full bg-white flex flex-col overflow-y-auto">
        <CardHeader className="text-center px-10 py-5">
          <CardTitle>
            <div className="flex justify-center items-center mb-4 md:hidden">
              <img className="md:mb-[20px] p-0 shrink-0 mr-2 h-10" src={Logo} />
            </div>
            <div className="max-md:grow font-bold">{title}</div>
          </CardTitle>
          {description && <CardDescription>{description}</CardDescription>}
        </CardHeader>
        <Separator />
        <CardContent className="px-10">{children}</CardContent>
      </Card>
      <Card
        className="col-span-2 h-full bg-white bg-no-repeat bg-center bg-cover max-md:hidden"
        style={{ backgroundImage: `url(${LoginHeroImg})` }}
      ></Card>
    </div>
  )
}
