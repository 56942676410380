import { cn } from '@/lib/utils'
import { Children, ReactElement } from 'react'

type SourcesLayoutProps = {
  children: React.ReactNode
  className?: string
}

export function SourcesLayout({ children, className }: SourcesLayoutProps) {
  const a = Children.toArray(children)
  const left = a.find((item) => (item as ReactElement).type === SourcesLayout.Left) ?? null
  const right = a.find((item) => (item as ReactElement).type === SourcesLayout.Right) ?? null

  return (
    <div className={cn('SourcesLayout grid grid-cols-5 gap-4', className)}>
      <div className={cn('SourcesLayoutLeft col-span-5 md:col-span-2 flex flex-col')}>{left}</div>
      <div className={cn('SourcesLayoutRight col-span-5 md:col-span-3 flex flex-col')}>{right}</div>
    </div>
  )
}

type SourcesLayoutLeftProps = {
  children: React.ReactNode
}
SourcesLayout.Left = ({ children }: SourcesLayoutLeftProps) => {
  return children
}

type SourcesLayoutRightProps = {
  children: React.ReactNode
}
SourcesLayout.Right = ({ children }: SourcesLayoutRightProps) => {
  return children
}
