import IconGoogle from '@/assets/icon-google.svg'
import IconMicrosoft from '@/assets/icon-microsoft.svg'
import { AuthPageLayout } from '@/components/auth-page-layout'
import { AuthContext } from '@/components/auth-provider'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { Spinner } from '@/components/ui/spinner'
import { useToast } from '@/components/ui/use-toast'
import { routePath } from '@/router/route-path'
import { zodResolver } from '@hookform/resolvers/zod'
import { Eye, EyeOff } from 'lucide-react'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import z from 'zod'
import { match } from 'ts-pattern'

const formSchema = z.object({
  email: z.string().min(1).email(),
  password: z.string().min(1)
})

type LoginFormValue = z.infer<typeof formSchema>

export function LoginPage() {
  const form = useForm<LoginFormValue>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const toast = useToast()
  const [passwordHidden, setPasswordHidden] = useState(true)
  const authClient = useContext(AuthContext)
  const [loginLoading, setLoginLoading] = useState(false)
  const navigate = useNavigate()

  function handleLogin(provider: 'microsoft' | 'google') {
    return function () {
      const redirectTo = `${window.origin}${routePath.home}`

      match(provider)
        .with('google', () => {
          authClient?.client?.auth.signInWithOAuth({
            provider: 'google',
            options: {
              redirectTo
            }
          })
        })
        .with('microsoft', () => {
          authClient?.client?.auth.signInWithOAuth({
            provider: 'azure',
            options: {
              redirectTo,
              scopes: 'email'
            }
          })
        })
        .exhaustive()
    }
  }

  async function handleSubmit(formValue: LoginFormValue) {
    try {
      setLoginLoading(true)
      const loginResult = await authClient?.client?.auth.signInWithPassword({
        email: formValue.email,
        password: formValue.password
      })

      if (loginResult?.error?.message) {
        toast.toast({
          variant: 'destructive',
          description: loginResult?.error?.message
        })
        return
      }

      navigate({ pathname: routePath.home })
    } catch (e) {
      toast.toast({
        variant: 'destructive',
        description: 'Error occured'
      })
    } finally {
      setLoginLoading(false)
    }
  }

  return (
    <AuthPageLayout title="Log In">
      <div className="h-10" />
      <Button variant="outline" className="w-full flex justify-center mb-5" onClick={handleLogin('microsoft')}>
        <img src={IconMicrosoft} className="mr-[-24px] w-5 h-5" />
        <span className="flex-grow">Log In with Microsoft</span>
      </Button>
      <Button variant="outline" className="w-full flex justify-center mb-5" onClick={handleLogin('google')}>
        <img src={IconGoogle} className="mr-[-24px] w-5 h-5" />
        <span className="flex-grow">Log In with Google</span>
      </Button>
      <div className="flex items-center gap-2 mb-5">
        <Separator className="w-min flex-grow" />
        <div className="text-purple text-xs">or</div>
        <Separator className="flex-grow w-min" />
      </div>

      <Form {...form}>
        <form className="mb-5" onSubmit={form.handleSubmit(handleSubmit)}>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="space-y-1 mb-5">
                <FormLabel className="font-semibold text-xs">Email</FormLabel>
                <FormControl>
                  <Input placeholder="Enter email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="space-y-1 mb-5 relative">
                <FormLabel className="font-semibold text-xs">Password</FormLabel>
                <FormControl>
                  <Input placeholder="Enter password" type={passwordHidden ? 'password' : 'text'} {...field} />
                </FormControl>
                <Button
                  variant="ghost"
                  size="icon"
                  className="absolute right-0 top-6"
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation()
                    setPasswordHidden((prev) => !prev)
                  }}
                >
                  {passwordHidden ? (
                    <Eye size={16} className="text-purple" />
                  ) : (
                    <EyeOff size={16} className="text-purple" />
                  )}
                </Button>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="mb-5">
            <Link
              to={routePath.forgotPassword}
              className="text-sm text-secondary-foreground underline"
              data-testid="btn-forgot-password"
            >
              Forgot Password
            </Link>
          </div>

          <Button type="submit" className="w-full mb-3" disabled={loginLoading}>
            {loginLoading ? <Spinner /> : <span>Log In</span>}
          </Button>
        </form>
      </Form>

      <div className="text-sm text-center text-purple">
        Don't have an account?{' '}
        <Link to={routePath.signup} className="text-secondary-foreground underline" data-testid="btn-signup">
          Sign Up
        </Link>
      </div>
    </AuthPageLayout>
  )
}
