import { deleteScheduledResearchPlanFetcher, updateScheduledResearchPlanFetcher } from '@/api/fetcher'
import { DataTable } from '@/components/data-table'
import { PageLayout } from '@/components/page-layout'
import { RecurrenceDialog } from '@/components/recurrence-dialog'
import { ResearchPlanStatusIcon } from '@/components/research-plan-status-icon'
import { ThreeDotsSpinner } from '@/components/three-dots-spinner'
import { Alert, AlertDescription } from '@/components/ui/alert'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { Separator } from '@/components/ui/separator'
import { Spinner } from '@/components/ui/spinner'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { useEnv } from '@/hooks/use-env'
import { useMount } from '@/hooks/use-mount'
import { parseCronExpression } from '@/lib/cron'
import { formatDateAgo } from '@/lib/date'
import { noop } from '@/lib/function'
import { routePath } from '@/router/route-path'
import { ApiScheduledResearchPlan, Maybe, ScheduledResearhPlan } from '@/types'
import { DialogProps } from '@radix-ui/react-alert-dialog'
import { DotsVerticalIcon } from '@radix-ui/react-icons'
import { captureException } from '@sentry/react'
import { ColumnDef, RowSelectionState, Updater } from '@tanstack/react-table'
import { parseISO } from 'date-fns'
import { Edit, Trash } from 'lucide-react'
import { defaultTo, filter, isEmpty, isNil, keys, map, T } from 'ramda'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'

export function ScheduledResearchPlansPage() {
  const apiScheduledResearchPlanList = useSWR<ApiScheduledResearchPlan[]>('/api/scheduled_research_plans', null, {
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState(0)
  const { isMd } = useBreakpoint()
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  useMount(() => {
    setTableHeight(() => {
      if (!tableContainerRef.current) {
        return 0
      }
      return tableContainerRef.current.getBoundingClientRect().height
    })
  })

  const planList: ScheduledResearhPlan[] = filter(
    (item): item is ScheduledResearhPlan => !isNil(item),
    map(mapApiScheduledResearchPlanToView, defaultTo([], apiScheduledResearchPlanList.data))
  )

  function handleRowSelectionChange(valueUpdater: Updater<RowSelectionState>) {
    setRowSelection((prev) => {
      const next = typeof valueUpdater === 'function' ? valueUpdater(prev) : prev
      return next
    })
  }

  function mapApiScheduledResearchPlanToView(item: ApiScheduledResearchPlan): Maybe<ScheduledResearhPlan> {
    if (!item.id) {
      return null
    }
    return {
      id: item.id,
      updatedAt: parseISO(`${item.updated_at}Z`),
      schedule: item.schedule,
      title: item.parent_research_plan.user_message,
      status: item.parent_research_plan?.status,
      projectId: item.project_id
    }
  }

  const [bulkDeleteOpen, setBulkDeleteOpen] = useState(false)
  const selectedIdList: string[] = keys(filter((value) => value === true, rowSelection))

  function getProjectRoute(id: string) {
    return { pathname: `${routePath.project}/${id}` }
  }

  function handleBulkDeleteClick() {
    setBulkDeleteOpen(T)
  }

  const columns: ColumnDef<ScheduledResearhPlan>[] = isMd
    ? [
        {
          accessorKey: 'select-col',
          maxSize: 10,
          header({ table }) {
            return (
              <Checkbox
                checked={table.getIsSomeRowsSelected() ? 'indeterminate' : table.getIsAllRowsSelected()}
                onCheckedChange={(value) => {
                  const event = { target: { checked: value } }
                  const handler = table.getToggleAllRowsSelectedHandler() // or getToggleAllPageRowsSelectedHandler
                  handler(event)
                }}
              />
            )
          },
          cell({ row }) {
            return (
              <div className="px-4">
                <Checkbox
                  checked={row.getIsSelected()}
                  disabled={!row.getCanSelect()}
                  onCheckedChange={row.getToggleSelectedHandler()}
                />
              </div>
            )
          }
        },
        {
          accessorKey: 'title',
          header() {
            return 'Scheduled Research'
          },
          cell(ctx) {
            return (
              <Link to={getProjectRoute(ctx.row.original.projectId)} className="flex flex-col p-4">
                <p className="text-sm mb-2">{ctx.row.original.title}</p>
                <p className="text-sm text-neutral-500">{parseCronExpression(ctx.row.original.schedule)}</p>
              </Link>
            )
          }
        },
        {
          id: 'status',
          cell({ row: { original } }) {
            return <ResearchPlanStatusIcon status={original.status} />
          }
        },
        {
          accessorKey: 'updatedAt',
          header() {
            return <span className="whitespace-nowrap">Last modified</span>
          },
          cell({ row }) {
            const { updatedAt } = row.original
            if (isNil(updatedAt)) {
              return null
            }
            const now = new Date()
            const diffInSeconds = (now.getTime() - updatedAt.getTime()) / 1000
            const formattedDate = diffInSeconds < 60 ? 'just now' : formatDateAgo(updatedAt, now)
            return <span className="px-4 whitespace-nowrap">{formattedDate}</span>
          }
        },
        {
          id: 'actions',
          cell(ctx) {
            return <ScheduledResearchPlanMenu id={ctx.row.original.id} />
          }
        }
      ]
    : [
        {
          accessorKey: 'select-col',
          maxSize: 10,
          header({ table }) {
            return (
              <Checkbox
                checked={table.getIsSomeRowsSelected() ? 'indeterminate' : table.getIsAllRowsSelected()}
                onCheckedChange={(value) => {
                  const event = { target: { checked: value } }
                  const handler = table.getToggleAllRowsSelectedHandler() // or getToggleAllPageRowsSelectedHandler
                  handler(event)
                }}
              />
            )
          },
          cell({ row }) {
            return (
              <div className="px-4">
                <Checkbox
                  checked={row.getIsSelected()}
                  disabled={!row.getCanSelect()}
                  onCheckedChange={row.getToggleSelectedHandler()}
                />
              </div>
            )
          }
        },
        {
          accessorKey: 'title',
          header() {
            return 'Scheduled Research'
          },
          cell(ctx) {
            const { updatedAt } = ctx.row.original
            return (
              <div className="flex flex-col p-4">
                <div className="flex justify-between gap-4">
                  <Link to={getProjectRoute(ctx.row.original.projectId)} className="text-sm mb-2">
                    {ctx.row.original.title}
                  </Link>
                  <ResearchPlanStatusIcon className="shrink-0 w-10" status={ctx.row.original.status} />
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <p className="text-sm text-neutral-500 mb-2">{parseCronExpression(ctx.row.original.schedule)}</p>
                    {updatedAt && (
                      <p className="text-sm text-neutral-500 self-start">{formatDateAgo(updatedAt, new Date())}</p>
                    )}
                  </div>
                  <ScheduledResearchPlanMenu id={ctx.row.original.id} />
                </div>
              </div>
            )
          }
        }
      ]

  const title = (
    <h3 className="h-[40px] flex items-center justify-between">
      <span className="font-bold">Research Scheduler</span>
    </h3>
  )

  if (apiScheduledResearchPlanList.isLoading) {
    return (
      <PageLayout>
        {title}

        <div className="flex justify-center">
          <ThreeDotsSpinner />
        </div>
      </PageLayout>
    )
  }

  if (apiScheduledResearchPlanList.error) {
    return (
      <PageLayout>
        {title}

        <div className="flex flex-col gap-[10px] h-full text-center text-sm">
          <Alert variant="destructive">
            <AlertDescription>Failed to fetch scheduled research plans.</AlertDescription>
          </Alert>
        </div>
      </PageLayout>
    )
  }

  if (isNil(apiScheduledResearchPlanList.data) || isEmpty(apiScheduledResearchPlanList.data)) {
    return (
      <PageLayout>
        {title}

        <div className="flex flex-col gap-[10px] h-full text-center text-sm">
          <div></div>
          There are no scheduled research plans. You can schedule a research plan by clicking on "Schedule Research"
          button from generated research plan assistant message.
        </div>
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <div className="flex justify-between h-12">
        <span>{title}</span>
        {!isEmpty(rowSelection) && (
          <Button size="icon" variant="outline" onClick={handleBulkDeleteClick}>
            <Trash size={18} />
          </Button>
        )}
        <DeleteScheduledPlanDialog value={selectedIdList} open={bulkDeleteOpen} onOpenChange={setBulkDeleteOpen} />
      </div>

      <div className="flex flex-col gap-[10px] h-full" ref={tableContainerRef}>
        <DataTable
          columns={columns}
          data={planList}
          style={{ height: tableHeight }}
          className="bg-white min-h-full overflow-y-auto"
          sorting={undefined}
          loadingMore={false}
          rowSelection={rowSelection}
          onScrollToBottom={noop}
          onSortingChange={noop}
          onRowSelectionChange={handleRowSelectionChange}
        />
      </div>
    </PageLayout>
  )
}

type ScheduledResearchPlanMenuProps = {
  id: string
}

function ScheduledResearchPlanMenu({ id }: ScheduledResearchPlanMenuProps) {
  const [menuOpened, setMenuOpened] = useState(false)
  const [updateOpened, setUpdateOpened] = useState(false)
  const [deleteOpened, setDeleteOpened] = useState(false)
  const env = useEnv()
  const apiUpdateScheduledResearchPlan = useSWRMutation(
    '/api/scheduled_research_plans',
    updateScheduledResearchPlanFetcher
  )

  async function handleUpdateSubmit(value: any) {
    try {
      await apiUpdateScheduledResearchPlan.trigger(
        {
          schedule: value.cronExpression[0],
          baseUrl: env.APP_API_BASE_URL,
          id
        },
        {
          revalidate: true
        }
      )
    } catch (e) {
      captureException(e)
    }
  }

  return (
    <div>
      <DropdownMenu open={menuOpened} onOpenChange={setMenuOpened}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <DotsVerticalIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuGroup className="space-y-1">
            <DropdownMenuItem
              className="space-x-2"
              onClick={() => {
                setMenuOpened(() => false)
                setUpdateOpened(() => true)
              }}
            >
              <Edit size={16} /> <span>Reschedule</span>
            </DropdownMenuItem>
            <Separator />
            <DropdownMenuItem
              className="space-x-2"
              onClick={() => {
                setMenuOpened(() => false)
                setDeleteOpened(() => true)
              }}
            >
              <Trash size={16} className="text-destructive" /> <span>Delete</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <RecurrenceDialog open={updateOpened} onOpenChange={setUpdateOpened} onSubmit={handleUpdateSubmit} />

      <DeleteScheduledPlanDialog value={[id]} open={deleteOpened} onOpenChange={setDeleteOpened} />
    </div>
  )
}

type DeleteScheduledPlanDialogProps = {
  open: DialogProps['open']
  value?: string[]
  onOpenChange: DialogProps['onOpenChange']
}

function DeleteScheduledPlanDialog({ value = [], open = false, onOpenChange = noop }: DeleteScheduledPlanDialogProps) {
  const env = useEnv()
  const apiDeleteScheduledResearchPlan = useSWRMutation(
    '/api/scheduled_research_plans',
    deleteScheduledResearchPlanFetcher
  )

  async function handleDelete(idList: string[]) {
    try {
      await apiDeleteScheduledResearchPlan.trigger(
        {
          baseUrl: env.APP_API_BASE_URL,
          idList
        },
        {
          revalidate: true
        }
      )
      onOpenChange(false)
    } catch (e) {
      captureException(e)
    }
  }
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete schedule</AlertDialogTitle>
          <AlertDialogDescription />
        </AlertDialogHeader>
        Selected schedules will be permanently removed
        <Button
          disabled={apiDeleteScheduledResearchPlan.isMutating}
          variant="destructive"
          onClick={() => handleDelete(value)}
        >
          {apiDeleteScheduledResearchPlan.isMutating ? <Spinner /> : 'Delete'}
        </Button>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
      </AlertDialogContent>
    </AlertDialog>
  )
}
