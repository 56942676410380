import { AuthGuard } from '@/components/auth-guard'
import { ChatDocumentsSearchFormProvider } from '@/components/chat-documents-search-form-provider'
import { PageLoader } from '@/components/page-loader'
import { useToast } from '@/components/ui/use-toast'
import { ProjectPageContext, ProjectPageContextValue } from '@/contexts/project'
import { mapApiProjectToProject } from '@/lib/domain/project'
import { routePath } from '@/router/route-path'
import { ApiProject, ApiProjectMessage } from '@/types'
import { defaultTo } from 'ramda'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import useSWRImmutable from 'swr/immutable'
import { ChatPage } from './chat'
import { SourcesPage } from './sources'
import { SourcesPageReadOnlyContext } from '@/contexts/sources-page-readonly'

export function ProjectPage() {
  const { projectId } = useParams()
  const { toast } = useToast()

  const projectUrl = projectId ? `/api/projects/${projectId}` : null
  const apiGetProject = useSWRImmutable<ApiProject>(projectUrl)

  const projectMessageListUrl = projectId ? `/api/projects/${projectId}/messages` : null
  const apiGetProjectMessageList = useSWRImmutable<ApiProjectMessage[]>(projectMessageListUrl)

  if (apiGetProject.isLoading || apiGetProjectMessageList.isLoading) {
    return <PageLoader />
  }

  if (!apiGetProject.data || !apiGetProjectMessageList.data || apiGetProject.error || apiGetProjectMessageList.error) {
    toast({
      variant: 'destructive',
      description: 'An error occured while trying to load project page.'
    })
    return <Navigate to={{ pathname: routePath.home }} />
  }

  const contextValue: ProjectPageContextValue = {
    project: mapApiProjectToProject(apiGetProject.data),
    projectMessageList: defaultTo([], apiGetProjectMessageList.data),
    refetch: () => apiGetProject.mutate(),
    refetchMessageList: () => apiGetProjectMessageList.mutate()
  }

  return (
    <ProjectPageContext.Provider value={contextValue}>
      <Routes>
        <Route path="" element={<Navigate to="chat" />} />
        <Route
          path="chat"
          element={
            <AuthGuard>
              <ChatPage />
            </AuthGuard>
          }
        />
        <Route
          path="sources"
          element={
            <AuthGuard>
              <SourcesPageReadOnlyContext.Provider value={false}>
                <ChatDocumentsSearchFormProvider>
                  <SourcesPage />
                </ChatDocumentsSearchFormProvider>
              </SourcesPageReadOnlyContext.Provider>
            </AuthGuard>
          }
        />
      </Routes>
    </ProjectPageContext.Provider>
  )
}
