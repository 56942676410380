import { DurationUnit, formatDuration, intervalToDuration } from 'date-fns'
import { defaultTo, isEmpty } from 'ramda'

export function formatDateAgo(start: Date, end: Date): string {
  const duration = intervalToDuration({ start, end })

  let formatUnitList: DurationUnit[] = []
  if (defaultTo(0, duration.years) > 0) {
    formatUnitList = ['years']
  } else if (defaultTo(0, duration.months) > 0) {
    formatUnitList = ['months']
  } else if (defaultTo(0, duration.days) > 0) {
    formatUnitList = ['days']
  } else if (defaultTo(0, duration.hours) > 0) {
    formatUnitList = ['hours']
  } else if (defaultTo(0, duration.minutes) > 0) {
    formatUnitList = ['minutes']
  } else if (defaultTo(0, duration.seconds) > 0) {
    formatUnitList = ['seconds']
  } else {
    formatUnitList = ['seconds']
  }

  const durationString = formatDuration(duration, {
    format: formatUnitList
  })
  if (isEmpty(durationString)) {
    return 'just now'
  }
  return `${durationString} ago`
}

export function formatDatePass(start: Date, end: Date): string {
  const duration = intervalToDuration({ start, end })
  return [duration.hours ?? 0, duration.minutes ?? 0, duration.seconds ?? 0]
    .map((item) => String(item).padStart(2, '0'))
    .join(':')
}
