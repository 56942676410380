import { Maybe } from '@/types'
import { type ClassValue, clsx } from 'clsx'
import { isNil } from 'ramda'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function isImageUrl(value: Maybe<string>): boolean {
  if (isNil(value)) {
    return false
  }
  return /\.(png|jpg|jpeg|webp|gif)$/i.test(value)
}
