import { useMount } from '@/hooks/use-mount'
import { cn } from '@/lib/utils'
import {
  ColumnDef,
  ColumnSort,
  RowSelectionState,
  SortingState,
  SortingTableState,
  Updater,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import { defaultTo, head, isNil } from 'ramda'
import { useRef, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './ui/table'
import { clear } from '@/lib/list'
import { Maybe } from '@/types'

type DataTableProps<TData, TValue> = {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

type Props<TData, TValue> = DataTableProps<TData, TValue> & {
  style?: any
  className?: string
  sorting?: Maybe<ColumnSort>
  loadingMore: boolean
  rowSelection?: RowSelectionState
  onScrollToBottom(): void
  onSortingChange(item?: ColumnSort): void
  onRowSelectionChange(value: Updater<RowSelectionState>): void
}

export function DataTable<TData, TValue>({
  columns,
  data,
  style,
  className,
  loadingMore,
  sorting,
  rowSelection = {},
  onScrollToBottom,
  onSortingChange,
  onRowSelectionChange
}: Props<TData, TValue>) {
  const [internalSorting, setSorting] = useState<SortingState>(clear([sorting]))

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: !isNil(rowSelection),
    getCoreRowModel: getCoreRowModel(),
    // for DataTable to be used all instances of data have to have unique "id" field as an index
    getRowId(row) {
      return (row as any).id as string
    },
    onSortingChange(next: Updater<SortingTableState['sorting']>) {
      if (typeof next === 'function') {
        const newSortingState = next(internalSorting)
        onSortingChange(head(newSortingState))
      }
      setSorting(next)
    },
    state: {
      sorting: internalSorting,
      rowSelection //pass the row selection state back to the table instance
    },
    onRowSelectionChange: onRowSelectionChange
  })

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0]
      if (first.isIntersecting) {
        if (!loadingMore) {
          onScrollToBottom()
        }
      }
    })
  )

  const paginationAnchorRef = useRef<HTMLDivElement>(null)

  useMount(() => {
    if (paginationAnchorRef.current) {
      observer.current.observe(paginationAnchorRef.current)
    }
    return () => {
      if (paginationAnchorRef.current) {
        observer.current.unobserve(paginationAnchorRef.current)
      }
    }
  })

  const isTableEmpty = defaultTo(0, table.getRowModel().rows?.length) === 0

  if (isTableEmpty) {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    // scrollable container of the table
    <div className={cn('rounded-md border', className)} style={style}>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    // style={{ width: `${header.getSize()}%` }}
                  >
                    {!header.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              data-state={row.getIsSelected() && 'selected'}
              className="hover:bg-secondary hover:text-secondary-foreground"
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell key={cell.id} className="p-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className="h-[100px]" ref={paginationAnchorRef} />
    </div>
  )
}
