import { ResearchSuggestion } from '@/types'
import { groupBy, map } from 'ramda'

type PromptSelectProps = {
  prompts: ResearchSuggestion[]
  onSelect(item: ResearchSuggestion): void
}

export function PromptSelect({ prompts, onSelect }: PromptSelectProps) {
  const itemListByGroup = groupBy((item) => {
    if (!item.category) {
      return 'Other'
    }
    return item.category
  }, prompts)

  return (
    <div data-testid="prompt-select">
      <p className="font-semibold text-center">Research Suggestions</p>
      {map((category) => {
        return (
          <div key={category}>
            <p className="text-sm font-semibold mb-4">{category}</p>
            <div className="pl-2">
              {map(
                (item) => (
                  <PromptItem key={item.id} item={item} onClick={() => onSelect(item)} />
                ),
                itemListByGroup[category] ?? []
              )}
            </div>
          </div>
        )
      }, Object.keys(itemListByGroup))}
    </div>
  )
}

type PromptItemProps = {
  item: ResearchSuggestion
  onClick(): void
}
function PromptItem({ item, onClick }: PromptItemProps) {
  return (
    <div
      key={item.id}
      className="p-2 rounded border hover:bg-secondary cursor-pointer mb-2"
      onClick={onClick}
      data-testid="btn-prompt-item"
    >
      <p className="text-xs font-semibold mb-1">{item.title}</p>
      <p className="text-xs font-normal">{item.content}</p>
    </div>
  )
}
