export const routePath: Readonly<Record<RouteName, string>> = {
  chat: '/chat',
  home: '/home',
  login: '/login',
  signup: '/signup',
  signupEmail: '/signup/email',
  logout: '/logout',
  forgotPassword: '/forgot-password',
  project: '/project',
  projects: '/projects',
  createProject: '/create-project',
  root: '/',
  settings: '/settings',
  sources: '/sources',
  manageSources: '/manage-sources',
  changePassword: '/change-password',
  emailVerification: '/email-verification',
  scheduledResearchPlan: '/scheduled-research-plans'
}

type RouteName =
  | 'root'
  | 'login'
  | 'logout'
  | 'home'
  | 'chat'
  | 'project'
  | 'projects'
  | 'settings'
  | 'sources'
  | 'manageSources'
  | 'signup'
  | 'forgotPassword'
  | 'signupEmail'
  | 'changePassword'
  | 'createProject'
  | 'emailVerification'
  | 'scheduledResearchPlan'
