import { getFileDownloadUrlFetcher } from '@/api/fetcher'
import { AuthContext } from '@/components/auth-provider'
import { useEnv } from '@/hooks/use-env'
import { isImageUrl } from '@/lib/utils'
import { Maybe, ResearchReportAttachment } from '@/types'
import { File } from 'lucide-react'
import { defaultTo, includes, isEmpty, map, not } from 'ramda'
import { useContext, useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogDescription, DialogTrigger } from './ui/dialog'
import { CsvTable } from './csv-table'

type AttachmentListProps = { attachments?: Maybe<ResearchReportAttachment[]> }

export function AttachmentList({ attachments }: AttachmentListProps) {
  const env = useEnv()
  const auth = useContext(AuthContext)
  const [imageSources, setImageSources] = useState<Record<string, string>>({})

  useEffect(() => {
    const fetchImageSources = async () => {
      const sources: Record<string, string> = {}
      for (const attachment of attachments || []) {
        if (isImageUrl(attachment.url)) {
          sources[attachment.url] = await getImageSrc(attachment.url)
        }
      }
      setImageSources(sources)
    }
    fetchImageSources()
  }, [attachments])

  const handleClick = async (url: string) => {
    if (url.startsWith('/')) {
      const downloadUrl = await getFileDownloadUrlFetcher('', {
        arg: {
          baseUrl: env.APP_API_BASE_URL,
          path: url,
          authToken: auth?.session?.access_token
        }
      })
      window.open(downloadUrl, '_blank', 'noopener,noreferrer')
    } else {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  }

  const getFilename = (url: string) => {
    const parts = url.split('/')
    return parts[parts.length - 1]
  }

  const getImageSrc = async (url: string) => {
    if (url.startsWith('/')) {
      const downloadUrl = await getFileDownloadUrlFetcher('', {
        arg: {
          baseUrl: env.APP_API_BASE_URL,
          path: url,
          authToken: auth?.session?.access_token
        }
      })
      return downloadUrl
    } else {
      return url
    }
  }

  const attachmentsWithImages = defaultTo([], attachments).filter((item) => isImageUrl(item.url))
  const attachmentsWithCSV = defaultTo([], attachments).filter((item) => isCsvFiletype(item.filetype))
  const urls: string[] = map((att) => att.url, [...attachmentsWithImages, ...attachmentsWithCSV])
  const attachmentsWithFile = defaultTo([], attachments).filter((item) => not(includes(item.url, urls)))

  function isCsvFiletype(filetype: string): boolean {
    return filetype === '.csv'
  }

  return (
    <div className="mt-4">
      <div className="flex flex-col mb-4">
        {map(
          (item) => (
            <Dialog key={item.url}>
              <DialogTrigger>
                <img alt={item.url} src={imageSources[item.url] || ''} className="max-w-full hover:cursor-zoom-in" />
              </DialogTrigger>
              <DialogContent className="w-[800px] max-w-[800px]">
                <DialogDescription>
                  <img alt={item.url} src={imageSources[item.url] || ''} width={800} />
                </DialogDescription>
              </DialogContent>
            </Dialog>
          ),
          attachmentsWithImages
        )}
      </div>

      {not(isEmpty(attachmentsWithFile)) && (
        <div className="mb-4">
          <p className="text-sm mb-2">Attachments:</p>
          {map(
            (item) => (
              <a
                className="flex flex-col items-center w-fit max-w-[200px] text-blue-500"
                href="#"
                onClick={() => handleClick(item.url)}
                key={item.url}
              >
                <File size={20} />
                <span className="text-xs max-w-[200px] break-words">{getFilename(item.url)}</span>
              </a>
            ),
            attachmentsWithFile
          )}
        </div>
      )}

      {not(isEmpty(attachmentsWithCSV)) && (
        <div className="mb-4 grid grid-cols-1">
          {map((item) => {
            return <CsvTable key={item.url} url={item.url} />
          }, attachmentsWithCSV)}
        </div>
      )}
    </div>
  )
}
