import { DialogProps } from '@radix-ui/react-dialog'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from './ui/dialog'
import { Separator } from './ui/separator'
import { Button } from './ui/button'
import { Trash } from 'lucide-react'
import { Project } from '@/types'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from './ui/form'
import { Input } from './ui/input'

type ProjectDeleteDialogProps = {
  project: Project
  onSubmit(project: Project): void
} & DialogProps

export function ProjectDeleteDialog({ project, onSubmit, ...props }: ProjectDeleteDialogProps) {
  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete "{project.title}"?</DialogTitle>
        </DialogHeader>
        <DialogFooter style={{ justifyContent: 'space-between' }}>
          <Button variant="destructive" onClick={() => onSubmit(project)}>
            <Trash className="mr-1" size={16} /> Yes, Delete It
          </Button>
          <DialogClose asChild>
            <Button>Cancel</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export function ProjectShareDialog(props: DialogProps) {
  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>Share</DialogHeader>
        <Separator />
        <div>not implemented</div>
        <Separator />
        <DialogFooter>OK</DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export type DuplicateProjectSubmitPayload = {
  project: Project
  name: string
}

const formSchema = z.object({
  name: z.string().max(100)
})

export type UpdateProjectFormValue = z.infer<typeof formSchema>

type ProjectUpdateDialogProps = {
  project: Project
  onSubmit(value: Project): void
} & DialogProps

export function ProjectUpdateDialog(props: ProjectUpdateDialogProps) {
  const form = useForm<UpdateProjectFormValue>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: props.project.title ?? ''
    }
  })

  function handleSubmit(value: UpdateProjectFormValue) {
    props.onSubmit({ ...props.project, title: value.name.trim() })

    if (props.onOpenChange) {
      props.onOpenChange(false)
    }
  }

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Update "{props.project.title}"?</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <Separator />
        <Form {...form}>
          <form id="update-project-form" onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold text-xs">Project Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Type in" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>

        <Separator />
        <DialogFooter style={{ justifyContent: 'space-between' }}>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button form="update-project-form" type="submit">
            Update
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

type ProjectPinDialogProps = {
  project: Project
  isPinned: boolean
  onSubmit(value: Project): void
} & DialogProps

export function ProjectPinDialog(props: ProjectPinDialogProps) {
  const actionText = props.isPinned ? 'Unpin' : 'Pin'
  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          {actionText} "{props.project.title}"?
        </DialogHeader>
        <DialogFooter style={{ justifyContent: 'space-between' }}>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button onClick={() => props.onSubmit(props.project)}>{actionText}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
