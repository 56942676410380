import { getFileDownloadUrlFetcher } from '@/api/fetcher'
import { useEnv } from '@/hooks/use-env'
import { useMount } from '@/hooks/use-mount'
import { cn } from '@/lib/utils'
import { Maybe } from '@/types'
import { captureException } from '@sentry/react'
import { isEmpty, isNil, not } from 'ramda'
import { useContext, useState } from 'react'
import { AuthContext } from './auth-provider'
import { Button } from './ui/button'
import Papa from 'papaparse'

type CsvTableProps = {
  url: string
}

export function CsvTable({ url }: CsvTableProps) {
  const env = useEnv()
  const auth = useContext(AuthContext)
  const [headerList, setHeaderList] = useState<string[]>([])
  const [rowList, setRowList] = useState<string[][]>([])
  const [downloadUrl, setDownloadUrl] = useState<Maybe<string>>()
  const MAX_ROW_VISIBLE = 6
  const isMoreThanVisible = rowList.length > MAX_ROW_VISIBLE
  const rowListVisible = rowList.slice(0, MAX_ROW_VISIBLE)

  useMount(() => {
    handleCSVClick(url)
  })

  async function handleCSVClick(url: string) {
    try {
      const downloadUrl = await getFileDownloadUrlFetcher('', {
        arg: {
          baseUrl: env.APP_API_BASE_URL,
          path: url,
          authToken: auth?.session?.access_token
        }
      })

      setDownloadUrl(() => downloadUrl)

      const file: string = await fetch(downloadUrl).then((r) => r.text())
      const csv = Papa.parse(file)
      if (not(isEmpty(csv.errors))) {
        throw new Error(csv.errors[0])
      }
      const [headers, ...json] = csv.data
      setHeaderList(() => headers)
      setRowList(() => json)
    } catch (e) {
      captureException(e)
      console.error(e)
    }
  }

  /**
   * @param url - open in new tab
   */
  function handleDownload(url: string) {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <div className="overflow-x-scroll">
      <div className={cn('grid text-sm mb-4')} style={{ gridTemplateColumns: `repeat(${headerList.length}, auto)` }}>
        {headerList.map((header, index) => {
          return (
            <div key={index} className="px-6 py-3 whitespace-nowrap font-semibold uppercase">
              {header}
            </div>
          )
        })}

        {rowListVisible.map((row, rowIndex) => {
          return headerList.map((_, headerIndex) => {
            return (
              <div
                className={cn(
                  'px-6 py-4 whitespace-nowrap bg-white border-b hover:bg-gray-50',
                  rowIndex === MAX_ROW_VISIBLE - 1 && 'opacity-25'
                )}
                key={`${rowIndex}${headerIndex}`}
              >
                {row[headerIndex]}
              </div>
            )
          })
        })}
      </div>

      {!isNil(downloadUrl) && (
        <div className="flex items-center mb-4">
          {<p className="mr-4 text-sm">Download file {isMoreThanVisible ? 'to see all data' : ''}:</p>}
          <Button size="sm" onClick={() => handleDownload(downloadUrl)}>
            Download
          </Button>
        </div>
      )}
    </div>
  )
}
