import { isEnterHotkey } from '@/lib/hotkey'
import { createAgentSuggestion } from '@/lib/tiptap/agent-suggestion'
import { MentionTicker } from '@/lib/tiptap/ticker-mentions'
import { createTickerSuggestion } from '@/lib/tiptap/ticker-suggestion'
import Mention from '@tiptap/extension-mention'
import { EditorContent, useEditor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import { SendHorizonal } from 'lucide-react'
import { KeyboardEventHandler, useContext, useEffect, useRef } from 'react'
import { Button } from './ui/button'
import { PromptFunctionsContext } from '@/contexts/prompt'
import { Maybe, Project } from '@/types'
import Placeholder from '@tiptap/extension-placeholder'
import Cookies from 'js-cookie'

type Props = {
  overrideValue?: string
  project?: Maybe<Project>
  onSubmit(value: { prompt: string }): void
}

export function PromptInput({ project, overrideValue, onSubmit }: Props) {
  const promptFunctions = useContext(PromptFunctionsContext)

  const editor = useEditor({
    extensions: [
      StarterKit,
      Mention.configure({
        HTMLAttributes: {
          class: 'mention'
        },
        suggestion: createAgentSuggestion()
      }),
      MentionTicker.configure({
        HTMLAttributes: {
          class: 'mention-ticker'
        },
        suggestion: createTickerSuggestion()
      }),
      Placeholder.configure({
        placeholder: 'Enter Research Request…'
      })
    ],
    editorProps: {
      attributes: {
        class: 'w-full pr-10 border-none max-h-32 h-fit min-h-10 outline-none overflow-y-auto p-2'
      },
      handleKeyDown: (_, event: KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          handleSubmit()
          return true // Indicate that the event is handled
        }
        return false // Indicate that the event should be handled by the editor
      }
    },
    content: `<p></p>`,
    onUpdate({ editor }) {
      promptFunctions(project?.id, editor.getText())
    }
  })

  useEffect(() => {
    if (editor) {
      const ctaPrompt = Cookies.get('ctaPrompt')
      if (ctaPrompt) {
        editor.commands.setContent(`<p>${ctaPrompt}</p>`)
        promptFunctions(project?.id, ctaPrompt)
        Cookies.remove('ctaPrompt')
      } else if (overrideValue) {
        editor.commands.setContent(`<p>${overrideValue}</p>`)
        promptFunctions(project?.id, overrideValue)
      }
    }
  }, [editor, overrideValue, project?.id, promptFunctions])

  useEffect(() => {
    editor?.commands.setContent(`<p>${overrideValue}</p>`)
    if (overrideValue) {
      promptFunctions(project?.id, overrideValue)
    }
  }, [overrideValue])

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (isEnterHotkey(e)) {
      e.preventDefault()
      handleSubmit()
    }
  }

  function handleSubmit() {
    const editorText = editor?.getText().trim()
    if (!editorText) {
      return
    }
    onSubmit({ prompt: editorText })
    editor?.commands.clearContent()
  }

  const inputRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'inherit'
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
    }
  }, [editor?.getText()])

  return (
    <div className="relative">
      <EditorContent
        data-testid="input-prompt"
        className=""
        ref={inputRef}
        onKeyDown={handleKeyDown}
        editor={editor}
        style={{ outline: 'none' }}
      />

      <Button
        data-testid="btn-submit-prompt"
        variant="ghost"
        className="absolute right-0 top-0 hover:bg-transparent"
        style={{ marginTop: 0 }}
        onClick={() => handleSubmit()}
      >
        <SendHorizonal size="16" />
      </Button>
    </div>
  )
}
