import { AuthContext } from '@/components/auth-provider'
import { PageLoader } from '@/components/page-loader'
import { routePath } from '@/router/route-path'
import { captureException } from '@sentry/react'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function LogoutPage() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    auth?.client?.auth
      .signOut()
      .then(() => navigate({ pathname: routePath.login }))
      .catch(captureException)
  }, [])

  return <PageLoader />
}
