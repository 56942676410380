import { Search, X } from 'lucide-react'
import { Form, FormControl, FormField, FormItem, FormMessage } from './ui/form'
import { Input } from './ui/input'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from './ui/button'
import { noop } from '@/lib/function'
import { isEmpty, isNil, not } from 'ramda'
import { useEffect } from 'react'
import { cn } from '@/lib/utils'

// should reflect API validations
const MIN = 3
const MAX = 100

const searchFormSchema = z.object({
  search: z
    .union([
      z.string().length(0),
      z.string().min(MIN, `Minimum allowed number of characters is ${MIN}`),
      z.string().max(MAX, `Maximum allowed number of characters is ${MAX}`)
    ])
    .optional()
    .transform((e) => (e === '' ? undefined : e))
})

export type SearchFormValue = z.infer<typeof searchFormSchema>

type SearchInputProps = {
  value?: string
  placeholder?: string
  className?: string
  style?: React.CSSProperties
  onChange?(value: string): void
  onSubmit(value: SearchFormValue['search']): void
  onClear?(): void
  onFocus?(): void
  onBlur?(): void
}

export function SearchInput({
  onSubmit,
  onChange,
  onClear = noop,
  onFocus,
  onBlur = noop,
  value,
  placeholder,
  className,
  style
}: SearchInputProps) {
  const searchForm = useForm<z.infer<typeof searchFormSchema>>({
    resolver: zodResolver(searchFormSchema),
    defaultValues: {
      search: ''
    }
  })

  useEffect(() => {
    if (isNil(value)) {
      return
    }
    searchForm.setValue('search', value)
  }, [])

  const isValueAvailable: boolean = !isEmpty(searchForm.getValues('search') ?? '')

  function handleClear() {
    searchForm.reset()
    onSubmit('')
    onClear()
  }

  return (
    <Form {...searchForm}>
      <form
        onSubmit={searchForm.handleSubmit((item) => {
          onSubmit(item.search)
        })}
        className={className}
        style={style}
      >
        <FormField
          control={searchForm.control}
          name="search"
          render={({ field }) => (
            <FormItem className="relative">
              <FormControl>
                <Input
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                    if (!onChange) {
                      return
                    }
                    onChange(e.target.value)
                  }}
                  onFocus={onFocus}
                  placeholder={placeholder ?? `Enter at least ${MIN} characters`}
                  onBlur={() => {
                    field.onBlur()
                    onBlur()
                  }}
                  className="w-full"
                />
              </FormControl>
              <Button
                variant="ghost"
                className={cn('absolute right-12 top-0', !isValueAvailable && 'right-0')}
                style={{ marginTop: 0 }}
                type="submit"
                disabled={not(searchForm.formState.isValid)}
              >
                <Search size="16" />
              </Button>
              {isValueAvailable && (
                <Button
                  variant="ghost"
                  className="absolute right-0 top-0"
                  style={{ marginTop: 0 }}
                  type="button"
                  onClick={handleClear}
                >
                  <X size="16" />
                </Button>
              )}
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  )
}
