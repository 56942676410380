import { noop } from '@/lib/function'
import { useEffect, useState } from 'react'
import { Recurrence } from 'react-cron-schedule'
import { Button } from './ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from './ui/dialog'

type Props = {
  value?: any
  children?: React.ReactNode
  open?: boolean
  onOpenChange?(value: boolean): void
  onSubmit(value: any): void
}

export function RecurrenceDialog({ value, children, open, onOpenChange = noop, onSubmit = noop }: Props) {
  const [recurrenceDialogValue, setRecurrenceDialogValue] = useState(value)

  useEffect(() => {
    setRecurrenceDialogValue(() => value)
  }, [value])

  function handleRecurrenceDialogOpenedChange(value: boolean) {
    onOpenChange(value)
    if (!value) {
      setRecurrenceDialogValue(() => null)
    }
  }

  function handleRecurrenceDialogChange(value: any) {
    setRecurrenceDialogValue(() => value)
  }

  function handleRecurrenceDialogSave() {
    onSubmit(recurrenceDialogValue)
  }

  return (
    <Dialog open={open} onOpenChange={handleRecurrenceDialogOpenedChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-full h-[100vh] md:max-w-fit md:h-auto">
        <DialogHeader>
          <DialogTitle>Schedule Research</DialogTitle>
          <DialogDescription>Choose recurrence option to schedule research plan</DialogDescription>
        </DialogHeader>

        <Recurrence
          styles={{
            root: {
              fontSize: '12px'
            },
            frequencyContainer: {
              display: 'none'
            },
            repeatLabel: {},
            repeatDropdown: {},
            weekdayBtn: {
              fontSize: '12px',
              color: 'var(--primary)'
            },
            selectedWeekdayBtn: {
              borderRadius: '50%',
              backgroundColor: 'green',
              color: 'white',
              fontSize: '12px'
            },
            onLabel: {
              marginTop: 0,
              fontSize: '12px'
            },
            dayLabel: {
              marginTop: 0,
              fontSize: '12px'
            },
            orLabel: {
              marginTop: 0,
              fontSize: '12px'
            },
            monthContainer: {
              display: 'flex',
              alignItems: 'center'
            },
            dateContainer: {
              display: 'none'
            }
          }}
          value={recurrenceDialogValue}
          onChange={handleRecurrenceDialogChange}
        />

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Close</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="default" onClick={handleRecurrenceDialogSave}>
              Save
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
