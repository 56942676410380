import { ApiProvider } from './components/api-provider'
import { AuthLoader } from './components/auth-loader'
import { AuthProvider } from './components/auth-provider'
import { EnvProvider } from './components/env-provider'
import { ErrorBoundary } from './components/error-boundary'
import { ThemeProvider } from './components/theme-provider'
import { Toaster } from './components/ui/toaster'
import { TooltipProvider } from './components/ui/tooltip'
import { AppRouter } from './router/router'
import { MathJaxContext } from 'better-react-mathjax'
import { PromptProvider } from './components/prompt-provider'
import { Fullstory } from './components/fullstory'
import { CookieProvider } from './components/cookie-provider'

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
        <TooltipProvider>
          <EnvProvider>
            <AuthProvider>
              <AuthLoader>
                <ApiProvider>
                  <MathJaxContext>
                    <PromptProvider>
                      <CookieProvider />
                      <AppRouter />
                    </PromptProvider>
                  </MathJaxContext>
                </ApiProvider>
              </AuthLoader>
            </AuthProvider>
          </EnvProvider>
        </TooltipProvider>
        <Toaster />
      </ThemeProvider>

      <Fullstory />
    </ErrorBoundary>
  )
}

export default App
