import { zodResolver } from '@hookform/resolvers/zod'
import { DialogProps } from '@radix-ui/react-dialog'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Button } from './ui/button'
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from './ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from './ui/form'
import { Input } from './ui/input'
import { Separator } from './ui/separator'

export type CreateFolderSubmitPayload = {
  name: string
}

const formSchema = z.object({
  name: z.string().min(1).max(100)
})

const formId = 'create-folder-form'

export type CreateFolderFormValue = z.infer<typeof formSchema>

type CreateFolderDialogProps = {
  onSubmit(value: CreateFolderSubmitPayload): void
} & DialogProps

export function CreateFolderDialog(props: CreateFolderDialogProps) {
  const form = useForm<CreateFolderFormValue>({
    resolver: zodResolver(formSchema),
    defaultValues: { name: '' }
  })

  function handleSubmit(value: CreateFolderFormValue) {
    props.onSubmit({
      name: value.name.trim()
    })

    if (props.onOpenChange) {
      props.onOpenChange(false)
    }
  }

  return (
    <Dialog {...props}>
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>Create new folder</DialogHeader>
        <Separator />
        <Form {...form}>
          <form id={formId} onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold text-xs">Folder Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Type in" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>

        <Separator />
        <DialogFooter style={{ justifyContent: 'space-between' }}>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button form={formId} type="submit">
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
