import { createProjectFetcher, stripeSubscriptionCheckFetcher } from '@/api/fetcher'
import { AuthContext } from '@/components/auth-provider'
import { PageLoader } from '@/components/page-loader'
import { useToast } from '@/components/ui/use-toast'
import { useEnv } from '@/hooks/use-env'
import { mapApiProjectToProject } from '@/lib/domain/project'
import { result, Result } from '@/lib/result'
import { routePath } from '@/router/route-path'
import { ApiProject, Project } from '@/types'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWRMutation from 'swr/mutation'

export function CreateProjectPage() {
  const env = useEnv()
  const { toast } = useToast()
  const navigate = useNavigate()
  const auth = useContext(AuthContext)

  const apiCreateProject = useSWRMutation(`${env.APP_API_BASE_URL}/api/projects`, createProjectFetcher)
  const apiStripeSubscriptionCheck = useSWRMutation('stripeSubscriptionCheck', stripeSubscriptionCheckFetcher)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const hasSuccessParam = searchParams.get('success') !== null

    if (hasSuccessParam) {
      const timer = setTimeout(() => {
        checkSubscription()
      }, 10000) // 10 seconds delay to wait for webhook to be processed

      return () => clearTimeout(timer)
    } else {
      checkSubscription()
    }
  }, [location.search])

  async function checkSubscription() {
    try {
      const result = await apiStripeSubscriptionCheck.trigger({
        baseUrl: env.APP_API_BASE_URL,
        authToken: auth?.session?.access_token
      })

      if (!result.has_existing_subscription) {
        // if user has no subscription, redirect to stripe
        window.location.href = result.redirect_url
        return
      }

      const projectResult = await createProject()
      if (projectResult.ok) {
        navigateToProject(projectResult.value.id)
      }
    } catch (e) {
      toast({
        variant: 'destructive',
        description: (e as Error).message
      })
      navigate({ pathname: routePath.home })
    }
  }

  async function createProject(): Promise<Result<Project>> {
    try {
      const project: ApiProject = await apiCreateProject.trigger({})
      if (!project.id) {
        throw new Error('Failed to create a project')
      }
      return result.ok(mapApiProjectToProject(project))
    } catch (e) {
      const error = e as Error
      toast({
        variant: 'destructive',
        description: error.message
      })
      return result.failed(error)
    }
  }

  function navigateToProject(projectId: string) {
    const searchParams = new URLSearchParams()
    searchParams.set('promptBuilder', 'true')
    navigate({
      pathname: `${routePath.project}/${projectId}/chat`,
      search: searchParams.toString()
    })
  }

  return <PageLoader />
}
