import { Maybe } from '@/types'
import { defaultTo } from 'ramda'

export function toHtmlId(value?: Maybe<string>): string {
  return defaultTo('', value)
    .split('')
    .filter((char) => {
      return /([a-z])|([A-Z])/.test(char)
    })
    .join('')
}
