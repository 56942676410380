import { cn } from '@/lib/utils'
import ReactMarkdown, { Options as MdOptions } from 'react-markdown'

export function Markdown(props: MdOptions) {
  return (
    <ReactMarkdown
      {...props}
      className={cn(props.className, '[&_ol]:list-decimal', '[&_ol]:pl-6', '[&_h3]:font-semibold')}
    />
  )
}
