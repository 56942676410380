import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import { Separator } from '@/components/ui/separator'
import { PromptBuilderForm } from '@/features/chat/components/prompt-builder-form'
import { noop } from '@/lib/function'
import { defaultTo } from 'ramda'

type Props = {
  open?: boolean
  onOpenChange?: (value: boolean) => void
  onSubmit: (prompt: string) => void
}

export function PromptBuilderDialog(props: Props) {
  function handleSkip() {
    defaultTo(noop, props.onOpenChange)(false)
  }

  function handleSubmit(prompt: string) {
    props.onSubmit(prompt)
  }

  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      <DialogContent className="h-[100vh] md:max-h-[90vh] md:h-[90vh] flex flex-col max-w-2xl">
        <DialogHeader>
          <DialogTitle data-testid="prompt-builder-title">Prompt Builder</DialogTitle>
          <DialogDescription data-testid="prompt-builder-description">
            Follow the next steps to build a valid prompt
          </DialogDescription>
        </DialogHeader>

        <Separator />

        <PromptBuilderForm id="form" onSubmit={handleSubmit} />

        <Separator />

        <DialogFooter className="justify-start">
          <Button type="submit" form="form">
            Submit
          </Button>
          <Button variant="ghost" onClick={handleSkip}>
            Skip
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
