import { useEffect } from 'react'
import Cookies from 'js-cookie'

export const CookieProvider: React.FC = () => {
  useEffect(() => {
    // Get all query parameters from the URL
    const urlParams = new URLSearchParams(window.location.search)

    // Iterate through all query parameters and set cookies
    urlParams.forEach((value, key) => {
      Cookies.set(key, value, {
        expires: 7,
        secure: true,
        sameSite: 'lax'
      })
    })
  }, [])

  return null // This component doesn't render anything
}
