import { Maybe } from '@/types'
import { defaultTo } from 'ramda'

export type StorageKey = 'projectById' | 'project' | 'scrollToResearchPlan'

export function getFromStorage<T>(key: StorageKey): Maybe<T> {
  try {
    return JSON.parse(defaultTo('', localStorage.getItem(key)))
  } catch (e) {
    return null
  }
}

export function setToStorage<T>(key: StorageKey, value: T): void {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {}
}

export function deleteFromStorage(key: StorageKey): void {
  try {
    localStorage.removeItem(key)
  } catch (e) {}
}
