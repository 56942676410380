import { ApiProject, Maybe, Project, ProjectListSorting } from '@/types'
import { ColumnSort } from '@tanstack/react-table'
import { parseISO } from 'date-fns'
import { always, equals, head, isNil } from 'ramda'
import { match } from 'ts-pattern'

export function mapApiProjectToProject(item: ApiProject): Project {
  return {
    id: item.id,
    title: item.title,
    createdAt: parseISO(`${item.created_at}Z`),
    updatedAt: parseISO(`${item.updated_at}Z`),
    researchPlanStatus: head(item.research_plans)?.status
  }
}

export function mapProjectSortToColumnSort(item?: Maybe<ProjectListSorting>): Maybe<ColumnSort> {
  if (isNil(item)) {
    return
  }
  return {
    id: item.sortBy,
    desc: equals(item.sortOrder, 'desc')
  }
}

export function mapColumnSortToProjectSort(item: ColumnSort): ProjectListSorting {
  return { sortBy: item.id as keyof Project, sortOrder: item.desc ? 'desc' : 'asc' }
}

export function mapProjectKeyToApiKey(key: Maybe<keyof Project>): Maybe<keyof ApiProject> {
  if (!key) {
    return
  }
  const apiKey = match<keyof Project, keyof ApiProject>(key)
    .with('createdAt', always('created_at'))
    .with('id', always('id'))
    .with('title', always('title'))
    .with('updatedAt', always('updated_at'))
    // researchPlanStatus doesn't have correct mapping
    .with('researchPlanStatus', always('research_plans'))
    .exhaustive()
  return apiKey
}
