import { PageLoader } from './page-loader'
import { useContext } from 'react'
import { AuthContext } from './auth-provider'

type Props = { children: React.ReactNode }

export function AuthLoader({ children }: Props) {
  const authClient = useContext(AuthContext)

  if (!authClient?.loaded) {
    return <PageLoader />
  }

  return children
}
