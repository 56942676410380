import { Menubar, MenubarMenu, MenubarTrigger } from '@/components/ui/menubar'
import { routePath } from '@/router/route-path'
import { BookOpenText, LogOut, SettingsIcon, FolderOpen } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Button } from './ui/button'
import { useEnv } from '@/hooks/use-env'
import { useMount } from '@/hooks/use-mount'
import { captureException } from '@sentry/react'

export function PageBottomMenu() {
  const env = useEnv()

  useMount(() => {
    if (!env.APP_FAQ_URL) {
      captureException(new Error('FAQ url not configured!'))
    }
  })

  return (
    <Menubar className="flex-col h-auto w-[40px] border-none bg-transparent">
      <MenubarMenu>
        <MenubarTrigger className="p-0 mb-2" asChild>
          <Button variant="outline" size="icon" title="Sources">
            <Link to={routePath.manageSources} style={{ marginLeft: 0 }}>
              <FolderOpen className="h-4 w-4" />
            </Link>
          </Button>
        </MenubarTrigger>
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger className="p-0 mb-2" asChild>
          <Link to={env.APP_FAQ_URL ?? ''} target="_blank" style={{ marginLeft: 0 }}>
            <Button variant="outline" size="icon" title="Help">
              <BookOpenText className="h-4 w-4" />
            </Button>
          </Link>
        </MenubarTrigger>
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger className="p-0 mb-2" asChild>
          <Link to={routePath.settings} style={{ marginLeft: 0 }}>
            <Button variant="outline" size="icon" title="Settings">
              <SettingsIcon className="h-4 w-4" />
            </Button>
          </Link>
        </MenubarTrigger>
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger className="p-0" asChild title="Logout">
          <Link to={routePath.logout} style={{ marginLeft: 0 }}>
            <Button variant="outline" size="icon" data-testid="btn-signout">
              <LogOut className="h-4 w-4" />
            </Button>
          </Link>
        </MenubarTrigger>
      </MenubarMenu>
    </Menubar>
  )
}
