import { getTickerListFetcher } from '@/api/fetcher'
import { AuthContext } from '@/components/auth-provider'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { useEnv } from '@/hooks/use-env'
import { ReactRenderer } from '@tiptap/react'
import debounce from 'lodash.debounce'
import { map } from 'ramda'
import { useContext, useEffect } from 'react'
import useSWR from 'swr'
import tippy, { Instance } from 'tippy.js'
import { findTickerSuggestionMatch } from './find-ticker-suggestion-match'

export function createTickerSuggestion() {
  return {
    char: '$',
    render: () => {
      let reactRenderer: ReactRenderer
      let popup: Instance
      return {
        onExit() {
          if (reactRenderer) {
            reactRenderer.destroy()
          }
          if (popup) {
            popup.destroy()
          }
        },
        onUpdate: debounce((props: any) => {
          reactRenderer.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup.setProps({
            getReferenceClientRect: props.clientRect as () => DOMRect
          })
        }, 500),
        onStart: (props: any) => {
          if (!props.clientRect) {
            return
          }

          reactRenderer = new ReactRenderer(
            (mentionPopupProps: any) => {
              const { query: searchQuery, range } = mentionPopupProps
              const env = useEnv()
              const auth = useContext(AuthContext)
              const searchParams = new URLSearchParams({ search: searchQuery, limit: '10' })
              const url = `${env.APP_API_BASE_URL}/api/tickers?${searchParams.toString()}`
              const api = useSWR<string[]>(
                searchQuery ? [url, auth?.session?.access_token] : null,
                getTickerListFetcher as any
              )
              const items = api.data ?? []

              useEffect(() => {
                if (popup) {
                  popup.setProps({
                    getReferenceClientRect: props.clientRect as () => DOMRect
                  })
                }
              }, [api.data])

              function handleItemClick(item: string) {
                props.editor.commands.deleteRange({ ...range, to: range.to - 1 })
                return props.command({ id: item })
              }

              return (
                <Card>
                  <CardContent className="flex flex-col p-0">
                    {map(
                      (item) => (
                        <Button
                          key={item}
                          variant="ghost"
                          className="w-full rounded-none justify-start"
                          onClick={() => handleItemClick(item)}
                        >
                          {item}
                        </Button>
                      ),
                      items as any[]
                    )}
                  </CardContent>
                </Card>
              )
            },
            {
              props,
              editor: props.editor
            }
          )

          popup = tippy(document.body, {
            getReferenceClientRect: props.clientRect as () => DOMRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start'
          })
        }
      }
    },
    findSuggestionMatch: findTickerSuggestionMatch
  }
}
