import { Maybe, ResearchPlanStatus } from '@/types'
import { AlertTriangle, CheckSquare } from 'lucide-react'
import { always } from 'ramda'
import { match } from 'ts-pattern'
import { Spinner } from './ui/spinner'
import { cn } from '@/lib/utils'

type Props = {
  status?: Maybe<ResearchPlanStatus>
  className?: string
}

export function ResearchPlanStatusIcon({ status, className }: Props) {
  return match(status)
    .with('CANCELLED', 'FAILED', always(<AlertTriangle size={18} className={cn('text-red-500', className)} />))
    .with('IN_PROGRESS', always(<Spinner size={18} className={className} />))
    .with('COMPLETED', always(<CheckSquare size={18} className={cn('text-green-500', className)} />))
    .with('PENDING', always(null))
    .with(null, always(null))
    .with(undefined, always(null))
    .exhaustive()
}
