import { ProjectFilterValue, ProjectListSorting } from '@/types'
import { SearchInput } from './search-input'
import { Button } from './ui/button'
import { ArrowDown, ArrowDownUp, ArrowUp, Filter } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from './ui/dropdown-menu'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from './ui/dialog'
import { useState } from 'react'

type ProjectFilterProps = {
  className?: string
  onChange(value: ProjectFilterValue): void
  onSortingChange(value: ProjectListSorting): void
}

export function ProjectFilter({ className, onChange, onSortingChange }: ProjectFilterProps) {
  const [searchValue, setSearchValue] = useState('')
  const [filterDialogOpen, setFilterDialogOpen] = useState(false)

  function handleInputSubmit(val: string | undefined) {
    onChange({ name: val })
    setFilterDialogOpen(() => false)
  }

  function handleSearchValueChange(value: string) {
    setSearchValue(() => value)
  }

  function handleSortingChange(key: ProjectListSorting['sortBy'], order: ProjectListSorting['sortOrder']) {
    onSortingChange({ sortBy: key, sortOrder: order })
  }

  function handleFilterDialogOpenChange(value: boolean) {
    setSearchValue(() => '')
    setFilterDialogOpen(() => value)
  }

  return (
    <div className={className}>
      <SearchInput
        placeholder="Search Projects"
        value={searchValue}
        onChange={handleSearchValueChange}
        onSubmit={handleInputSubmit}
        className="flex-shrink-0 transition-all max-md:hidden"
      />

      <div className="md:hidden">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="secondary" size="icon">
              <ArrowDownUp size={18} />
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent align="end">
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={() => handleSortingChange('title', 'asc')}>
                <ArrowUp className="mr-2" size={16} /> Title asc
              </DropdownMenuItem>

              <DropdownMenuItem onClick={() => handleSortingChange('title', 'desc')}>
                <ArrowDown className="mr-2" size={16} /> Title desc
              </DropdownMenuItem>

              <DropdownMenuItem onClick={() => handleSortingChange('updatedAt', 'asc')}>
                <ArrowUp className="mr-2" size={16} /> Last updated asc
              </DropdownMenuItem>

              <DropdownMenuItem onClick={() => handleSortingChange('updatedAt', 'desc')}>
                <ArrowDown className="mr-2" size={16} /> Last updated desc
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>

        <Dialog open={filterDialogOpen} onOpenChange={handleFilterDialogOpenChange}>
          <DialogTrigger asChild onClick={() => setFilterDialogOpen(() => true)}>
            <Button variant="secondary" size="icon">
              <Filter size={18} />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Filter project</DialogTitle>
              <DialogDescription />
            </DialogHeader>

            <SearchInput
              placeholder="Search"
              value={searchValue}
              onChange={handleSearchValueChange}
              onSubmit={handleInputSubmit}
              className="flex-shrink-0 transition-all"
            />

            <DialogFooter className="flex justify-between flex-row">
              <DialogClose asChild>
                <Button variant="secondary">Cancel</Button>
              </DialogClose>
              <Button onClick={() => handleInputSubmit(searchValue)}>Apply</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}
