import Logo from '@/assets/logo.svg'
import LogoText from '@/assets/logo-rect.svg'
import { PageBottomMenu } from './page-bottom-menu'
import { PageMenu } from './page-menu'
import { PageMenuMobile } from './page-menu-mobile'

type Props = { children: React.ReactNode }

export function PageLayout({ children }: Props) {
  return (
    <section className="grid grid-cols-[1fr] grid-rows-[auto_1fr] md:grid-cols-[40px_auto] md:grid-rows-1 gap-2 md:gap-8 h-screen px-2 md:px-8 bg-secondary">
      {/* desktop */}
      <aside className="hidden md:flex md:flex-col gap-1 bg-secondary py-2 md:py-5">
        <img className="md:mb-[20px] p-0 shrink-0 size-10" src={Logo} />
        <PageMenu />
        <div className="grow"></div>
        <PageBottomMenu />
      </aside>

      {/* small screen */}
      <aside className="flex items-center md:hidden bg-secondary py-2">
        <PageMenuMobile />

        <div className="flex items-center gap-2 grow justify-center">
          <img className="md:mb-[20px] p-0 shrink-0 h-8" src={LogoText} />
        </div>
      </aside>

      <main className="flex flex-col md:py-5 bg-secondary">{children}</main>
    </section>
  )
}
