import { Nullable } from '@/types'
import { useEffect, useRef, useState } from 'react'

type UseRefHeightReturn<T extends HTMLElement> = {
  ref: React.MutableRefObject<Nullable<T>>
  height: number
  width: number
}

export function useRefSize<T extends HTMLElement>(): UseRefHeightReturn<T> {
  const [value, setValue] = useState({ height: 0, width: 0 })
  const ref = useRef<T>(null)

  useEffect(() => {
    onResize()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  function onResize() {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      setValue(() => rect)
    }
  }

  return {
    ref,
    height: value.height,
    width: value.width
  }
}
