import { cn } from '@/lib/utils'
import { DocumentSource } from '@/types'
import { Checkbox } from './ui/checkbox'
import { Label } from './ui/label'
import { Button } from './ui/button'
import { ChevronRight } from 'lucide-react'

type DocumentSourceItemProps = {
  value: DocumentSource
  active: boolean
  readOnly: boolean
  onClick(value: DocumentSource): void
  onCheckedChange(value: boolean): void
  onOnlyClick(): void
}

export function DocumentSourceItem({
  value,
  active,
  readOnly,
  onClick,
  onCheckedChange,
  onOnlyClick
}: DocumentSourceItemProps) {
  return (
    <div
      className={cn('flex items-center h-10 py-3 px-5 group', active ? 'bg-primary' : 'hover:bg-secondary')}
      role="button"
      data-testid="btn-source-item"
      onClick={() => onClick(value)}
    >
      {!readOnly ? (
        <>
          <Checkbox
            id={value.id}
            className={cn(
              'mr-2',
              active &&
                'bg-purple-light data-[state=checked]:bg-purple-light [&>span]:data-[state=checked]:text-primary'
            )}
            checked={value.selectionState}
            onCheckedChange={onCheckedChange}
            onClick={(e) => e.stopPropagation()}
          ></Checkbox>
          <Label
            htmlFor={value.id}
            className={cn('font-normal', active && 'text-purple-light')}
            onClick={(e) => {
              if (!readOnly) {
                e.stopPropagation()
              }
            }}
          >
            <div className="flex items-center">
              <span>{value.title}</span>
              {value.is_experimental && (
                <span className="ml-2 px-1 py-0.5 text-xs bg-yellow-100 text-yellow-800 rounded">Experimental</span>
              )}
            </div>
          </Label>
        </>
      ) : (
        <div className={cn('font-normal text-sm', active && 'text-purple-light')}>
          <div className="flex items-center">
            <span>{value.title}</span>
            {value.is_experimental && (
              <span className="ml-2 px-1 py-0.5 text-xs bg-yellow-100 text-yellow-800 rounded">Experimental</span>
            )}
          </div>
        </div>
      )}

      <div className="grow"></div>

      {!readOnly && (
        <Button
          size="sm"
          onClick={(e) => {
            e.stopPropagation()
            onOnlyClick()
          }}
          variant={active ? 'default' : 'outline'}
          className={cn(
            'hidden h-6 mr-4 font-normal',
            active ? 'border border-purple-light block' : 'border border-primary bg-transparent group-hover:block'
          )}
        >
          Only
        </Button>
      )}
      <ChevronRight size={16} className={cn(active && 'text-purple-light')} />
    </div>
  )
}
