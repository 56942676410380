import { AuthContext } from '@/components/auth-provider'
import { PageLayout } from '@/components/page-layout'
// import {
//   AlertDialog,
//   AlertDialogAction,
//   AlertDialogCancel,
//   AlertDialogContent,
//   AlertDialogDescription,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogTitle,
//   AlertDialogTrigger
// } from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { routePath } from '@/router/route-path'
import { useContext } from 'react'
import { Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { ChangePasswordPage } from './change-password'
import { stripeCustomerPortalFetcher } from '@/api/fetcher'
import { useState } from 'react'
import { useEnv } from '@/hooks/use-env'
import useSWRMutation from 'swr/mutation'
import { captureException } from '@sentry/react'
import { Badge } from '@/components/ui/badge'
import { useEffect } from 'react'

export function SettingsPage() {
  const authClient = useContext(AuthContext)
  const user = authClient?.session?.user

  if (!user) {
    return <Navigate to={{ pathname: routePath.logout }} />
  }

  const navigate = useNavigate()

  function handleChangePasswordClose() {
    navigate({ pathname: routePath.settings })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [isSubscriptionManaged, setIsSubscriptionManaged] = useState(false)
  const [subscriptionStatus, setSubscriptionStatus] = useState<'Subscribed' | 'Not Subscribed'>('Not Subscribed')
  const [isCheckingSubscription, setIsCheckingSubscription] = useState(true)
  const env = useEnv()
  const apiStripeCustomerPortal = useSWRMutation('/api/stripe/customer_portal', stripeCustomerPortalFetcher)

  useEffect(() => {
    checkSubscriptionStatus()
  }, [])

  async function checkSubscriptionStatus() {
    setIsCheckingSubscription(true)
    try {
      const result = await apiStripeCustomerPortal.trigger(
        {
          baseUrl: env.APP_API_BASE_URL,
          authToken: authClient?.session?.access_token
        },
        {
          revalidate: false
        }
      )
      setSubscriptionStatus(result.has_existing_subscription ? 'Subscribed' : 'Not Subscribed')
    } catch (e) {
      captureException(e)
      console.error('Error checking subscription status:', e)
      // You might want to show an error message to the user here
    } finally {
      setIsCheckingSubscription(false)
    }
  }

  async function handleManageSubscription() {
    setIsLoading(true)
    try {
      const result = await apiStripeCustomerPortal.trigger(
        {
          baseUrl: env.APP_API_BASE_URL,
          authToken: authClient?.session?.access_token
        },
        {
          revalidate: false
        }
      )
      setSubscriptionStatus(result.has_existing_subscription ? 'Subscribed' : 'Not Subscribed')
      window.location.href = result.redirect_url
      setIsSubscriptionManaged(true)
    } catch (e) {
      captureException(e)
      console.error('Error fetching Stripe Customer Portal URL:', e)
      // You might want to show an error message to the user here
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PageLayout>
            <Card className="lg:w-1/2">
              <CardHeader>
                <CardTitle className="text-sm text-secondary-foreground">My profile</CardTitle>
              </CardHeader>

              <CardContent>
                <Label htmlFor="name">Name</Label>
                <Input id="name" value={user.user_metadata.full_name} readOnly />

                <Separator className="my-2 bg-transparent" />

                <Label htmlFor="email">Email</Label>
                <Input id="email" value={user.email} readOnly />

                <Separator className="my-2 bg-transparent" />

                <Label htmlFor="email">Password</Label>
                <div className="flex flex-col justify-start items-start lg:justify-between lg:flex-row lg:items-center">
                  <div className="text-purple">*********</div>

                  <Button variant="secondary" asChild>
                    <Link to={{ pathname: 'change-password' }}>Change Password</Link>
                  </Button>
                </div>

                <Separator className="my-4" />

                <div className="flex flex-col items-start">
                  <h3 className="text-lg font-semibold mb-2">Subscription</h3>
                  <div className="flex items-center gap-4">
                    <Button 
                      onClick={handleManageSubscription} 
                      disabled={isLoading || apiStripeCustomerPortal.isMutating || isSubscriptionManaged}
                    >
                      {isLoading || apiStripeCustomerPortal.isMutating 
                        ? 'Loading...' 
                        : 'Manage My Subscription'
                      }
                    </Button>
                    {isCheckingSubscription ? (
                      <span>Checking subscription...</span>
                    ) : (
                      <Badge variant={subscriptionStatus === 'Subscribed' ? 'secondary' : 'outline'}>
                        {subscriptionStatus}
                      </Badge>
                    )}
                  </div>
                </div>

                <Routes>
                  <Route path="change-password" element={<ChangePasswordPage onClose={handleChangePasswordClose} />} />
                </Routes>
              </CardContent>

              {/* <CardFooter className="py-6 px-5 border-t-2 border-t-secondary flex flex-col gap-2 items-start">
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button variant="destructive">Delete my account</Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you absolutely sure? (not implemented)</AlertDialogTitle>
                      <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete your account and remove your data
                        from our servers.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogAction>Continue</AlertDialogAction>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
                <p className="text-secondary-foreground text-sm">
                  Permanently delete the account and remove access from all workspaces.
                </p>
              </CardFooter> */}
            </Card>
          </PageLayout>
        }
      />
    </Routes>
  )
}
