export type USState = {
  Name: string
  FIPSStateNumericCode: string
  OfficialUSPSCode: string
}

export const STATES: USState[] = [
  { Name: 'Alabama', FIPSStateNumericCode: '01', OfficialUSPSCode: 'AL' },
  { Name: 'Alaska', FIPSStateNumericCode: '02', OfficialUSPSCode: 'AK' },
  { Name: 'Arizona', FIPSStateNumericCode: '04', OfficialUSPSCode: 'AZ' },
  { Name: 'Arkansas', FIPSStateNumericCode: '05', OfficialUSPSCode: 'AR' },
  { Name: 'California', FIPSStateNumericCode: '06', OfficialUSPSCode: 'CA' },
  { Name: 'Colorado', FIPSStateNumericCode: '08', OfficialUSPSCode: 'CO' },
  { Name: 'Connecticut', FIPSStateNumericCode: '09', OfficialUSPSCode: 'CT' },
  { Name: 'Delaware', FIPSStateNumericCode: '10', OfficialUSPSCode: 'DE' },
  { Name: 'District of Columbia', FIPSStateNumericCode: '11', OfficialUSPSCode: 'DC' },
  { Name: 'Florida', FIPSStateNumericCode: '12', OfficialUSPSCode: 'FL' },
  { Name: 'Georgia', FIPSStateNumericCode: '13', OfficialUSPSCode: 'GA' },
  { Name: 'Hawaii', FIPSStateNumericCode: '15', OfficialUSPSCode: 'HI' },
  { Name: 'Idaho', FIPSStateNumericCode: '16', OfficialUSPSCode: 'ID' },
  { Name: 'Illinois', FIPSStateNumericCode: '17', OfficialUSPSCode: 'IL' },
  { Name: 'Indiana', FIPSStateNumericCode: '18', OfficialUSPSCode: 'IN' },
  { Name: 'Iowa', FIPSStateNumericCode: '19', OfficialUSPSCode: 'IA' },
  { Name: 'Kansas', FIPSStateNumericCode: '20', OfficialUSPSCode: 'KS' },
  { Name: 'Kentucky', FIPSStateNumericCode: '21', OfficialUSPSCode: 'KY' },
  { Name: 'Louisiana', FIPSStateNumericCode: '22', OfficialUSPSCode: 'LA' },
  { Name: 'Maine', FIPSStateNumericCode: '23', OfficialUSPSCode: 'ME' },
  { Name: 'Maryland', FIPSStateNumericCode: '24', OfficialUSPSCode: 'MD' },
  { Name: 'Massachusetts', FIPSStateNumericCode: '25', OfficialUSPSCode: 'MA' },
  { Name: 'Michigan', FIPSStateNumericCode: '26', OfficialUSPSCode: 'MI' },
  { Name: 'Minnesota', FIPSStateNumericCode: '27', OfficialUSPSCode: 'MN' },
  { Name: 'Mississippi', FIPSStateNumericCode: '28', OfficialUSPSCode: 'MS' },
  { Name: 'Missouri', FIPSStateNumericCode: '29', OfficialUSPSCode: 'MO' },
  { Name: 'Montana', FIPSStateNumericCode: '30', OfficialUSPSCode: 'MT' },
  { Name: 'Nebraska', FIPSStateNumericCode: '31', OfficialUSPSCode: 'NE' },
  { Name: 'Nevada', FIPSStateNumericCode: '32', OfficialUSPSCode: 'NV' },
  { Name: 'New Hampshire', FIPSStateNumericCode: '33', OfficialUSPSCode: 'NH' },
  { Name: 'New Jersey', FIPSStateNumericCode: '34', OfficialUSPSCode: 'NJ' },
  { Name: 'New Mexico', FIPSStateNumericCode: '35', OfficialUSPSCode: 'NM' },
  { Name: 'New York', FIPSStateNumericCode: '36', OfficialUSPSCode: 'NY' },
  { Name: 'North Carolina', FIPSStateNumericCode: '37', OfficialUSPSCode: 'NC' },
  { Name: 'North Dakota', FIPSStateNumericCode: '38', OfficialUSPSCode: 'ND' },
  { Name: 'Ohio', FIPSStateNumericCode: '39', OfficialUSPSCode: 'OH' },
  { Name: 'Oklahoma', FIPSStateNumericCode: '40', OfficialUSPSCode: 'OK' },
  { Name: 'Oregon', FIPSStateNumericCode: '41', OfficialUSPSCode: 'OR' },
  { Name: 'Pennsylvania', FIPSStateNumericCode: '42', OfficialUSPSCode: 'PA' },
  { Name: 'Rhode Island', FIPSStateNumericCode: '44', OfficialUSPSCode: 'RI' },
  { Name: 'South Carolina', FIPSStateNumericCode: '45', OfficialUSPSCode: 'SC' },
  { Name: 'South Dakota', FIPSStateNumericCode: '46', OfficialUSPSCode: 'SD' },
  { Name: 'Tennessee', FIPSStateNumericCode: '47', OfficialUSPSCode: 'TN' },
  { Name: 'Texas', FIPSStateNumericCode: '48', OfficialUSPSCode: 'TX' },
  { Name: 'Utah', FIPSStateNumericCode: '49', OfficialUSPSCode: 'UT' },
  { Name: 'Vermont', FIPSStateNumericCode: '50', OfficialUSPSCode: 'VT' },
  { Name: 'Virginia', FIPSStateNumericCode: '51', OfficialUSPSCode: 'VA' },
  { Name: 'Washington', FIPSStateNumericCode: '53', OfficialUSPSCode: 'WA' },
  { Name: 'West Virginia', FIPSStateNumericCode: '54', OfficialUSPSCode: 'WV' },
  { Name: 'Wisconsin', FIPSStateNumericCode: '55', OfficialUSPSCode: 'WI' },
  { Name: 'Wyoming', FIPSStateNumericCode: '56', OfficialUSPSCode: 'WY' }
] as const
