import { noop } from '@/lib/function'
import { createContext, useMemo, useState } from 'react'

type ChatDocumentsSearchFormContextValue = string

type ChatDocumentsSearchFormFunctionsContextValue = {
  setValue(value: string): void
}

export const ChatDocumentsSearchFormContext = createContext<ChatDocumentsSearchFormContextValue>('')

export const ChatDocumentsSearchFormFunctionsContext = createContext<ChatDocumentsSearchFormFunctionsContextValue>({
  setValue: noop
})

type ChatDocumentsSearchFormProviderProps = {
  children: React.ReactNode
}

export function ChatDocumentsSearchFormProvider({ children }: ChatDocumentsSearchFormProviderProps) {
  const [value, setValue] = useState('')

  const functionsValue = useMemo(
    () => ({
      setValue: (value: string) => setValue(() => value)
    }),
    []
  )

  return (
    <ChatDocumentsSearchFormContext.Provider value={value}>
      <ChatDocumentsSearchFormFunctionsContext.Provider value={functionsValue}>
        {children}
      </ChatDocumentsSearchFormFunctionsContext.Provider>
    </ChatDocumentsSearchFormContext.Provider>
  )
}
