export async function copyToClipboard(container?: HTMLElement) {
  if (!container) {
    return
  }

  const selection = window.getSelection()
  if (!selection) {
    return
  }
  const range = document.createRange()
  range.selectNodeContents(container)
  selection.removeAllRanges()
  selection.addRange(range)

  document.execCommand('copy')
  selection.collapseToStart()
}
