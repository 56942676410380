import { WithAuthToken, apiCall } from '@/lib/api'
import {
  ApiProject,
  ApiProjectMessage,
  ApiSourceSelection,
  Maybe,
  Project,
  ProjectApiKey,
  ProjectListFilter,
  ResearchPlan,
  SortOrder
} from '@/types'
import { defaultTo, isNil, map } from 'ramda'
import { Fetcher } from 'swr'
import { MutationFetcher } from 'swr/mutation'

export async function createProjectFetcher(key: string, options: { arg: { authToken?: string } }): Promise<ApiProject> {
  return await apiCall({ url: key, method: 'POST', token: options.arg.authToken })
}

export type CreateFolderFetcher = MutationFetcher<
  unknown,
  string,
  {
    parent?: string
    name: string
    sourceId?: string
    baseUrl?: string
  } & WithAuthToken
>

export const createFolderFetcher: CreateFolderFetcher = async (_key, options) => {
  if (!options.arg.parent || !options.arg.baseUrl || !options.arg.sourceId) {
    throw new Error('createFolderFetcher: required parameters not provided')
  }
  const searchParams = new URLSearchParams()
  searchParams.append('parent_folder', options.arg.parent)
  searchParams.append('title', options.arg.name)
  const query = searchParams.toString()
  return await apiCall({
    url: `${options.arg.baseUrl}/api/sources/${options.arg.sourceId}/folders?${query}`,
    method: 'POST',
    token: options.arg.authToken
  })
}

export type DeleteFolderFetcher = MutationFetcher<
  unknown,
  string,
  {
    path?: string
    sourceId?: string
    baseUrl?: string
  } & WithAuthToken
>

export const deleteFolderFetcher: DeleteFolderFetcher = async (_key, options) => {
  if (!options.arg.path || !options.arg.baseUrl || !options.arg.sourceId) {
    throw new Error('deleteFolderFetcher: required parameters not provided')
  }
  const searchParams = new URLSearchParams()
  searchParams.append('folder_path', options.arg.path)
  const query = searchParams.toString()
  return await apiCall({
    url: `${options.arg.baseUrl}/api/sources/${options.arg.sourceId}/folders?${query}`,
    method: 'DELETE',
    token: options.arg.authToken
  })
}

export type DeleteFileFetcher = MutationFetcher<
  unknown,
  string,
  {
    path?: string
    sourceId?: string
    baseUrl?: string
  } & WithAuthToken
>

export const deleteFileFetcher: DeleteFileFetcher = async (_key, options) => {
  if (!options.arg.path || !options.arg.baseUrl || !options.arg.sourceId) {
    throw new Error('deleteFileFetcher: required parameters not provided')
  }
  const searchParams = new URLSearchParams()
  searchParams.append('file_path', options.arg.path)
  const query = searchParams.toString()
  return await apiCall({
    url: `${options.arg.baseUrl}/api/sources/${options.arg.sourceId}/files?${query}`,
    method: 'DELETE',
    token: options.arg.authToken
  })
}

export type UpdateSelectionFetcherArg = {
  include: string[]
  exclude: string[]
  sourceId?: string
  projectId?: string
  baseUrl?: string
} & WithAuthToken

export type UpdateSelectionFetcher = MutationFetcher<ApiSourceSelection, string, UpdateSelectionFetcherArg>

export const updateSelectionFetcher: UpdateSelectionFetcher = async (_key, options) => {
  if (!options.arg.baseUrl || !options.arg.sourceId || !options.arg.projectId) {
    throw new Error('updateSelectionFetcher: required parameters not provided')
  }
  const searchParams = new URLSearchParams()
  searchParams.append('project_id', options.arg.projectId)
  const query = searchParams.toString()
  return await apiCall({
    url: `${options.arg.baseUrl}/api/sources/${options.arg.sourceId}/selections?${query}`,
    method: 'PUT',
    token: options.arg.authToken,
    body: JSON.stringify({
      include: options.arg.include,
      exclude: options.arg.exclude
    })
  })
}

export type GetSelectionByIdFetcher = Fetcher<Record<string, ApiSourceSelection>, string>

export const getSelectionByIdFetcher: GetSelectionByIdFetcher = async (key) => {
  const options = JSON.parse(key) as {
    key: string
    baseUrl?: string
    projectId?: string
    sourceIdList?: string[]
    authToken?: string
  }
  if (!options.sourceIdList || !options.baseUrl || !options.projectId) {
    throw new Error('getSelectionByIdFetcher: required parameters not provided')
  }
  const searchParams = new URLSearchParams()
  searchParams.append('project_id', options.projectId)
  const query = searchParams.toString()

  const response = await Promise.allSettled(
    map((item) => {
      return apiCall({
        url: `${options.baseUrl}/api/sources/${item}/selections?${query}`,
        method: 'GET',
        token: options.authToken
      }).then((r) => {
        return { id: item, selection: r }
      })
    }, options.sourceIdList)
  )
  return response.reduce((acc, item) => {
    if (item.status !== 'fulfilled') {
      return acc
    }
    return { ...acc, [item.value.id]: item.value.selection }
  }, {})
}

export type UpdateSelectionByIdFetcherArg = {
  baseUrl?: string
  projectId?: string
  sourceIdSelectionPairList: Array<[string, ApiSourceSelection]>
} & WithAuthToken

export type UpdateSelectionByIdFetcher = MutationFetcher<
  Record<string, ApiSourceSelection>,
  string,
  UpdateSelectionByIdFetcherArg
>

export const updateSelectionByIdFetcher: UpdateSelectionByIdFetcher = async (_key, options) => {
  if (!options.arg.baseUrl || !options.arg.projectId) {
    throw new Error('updateSelectionByIdFetcher: required parameters not provided')
  }
  const searchParams = new URLSearchParams()
  searchParams.append('project_id', options.arg.projectId)
  const query = searchParams.toString()

  const response = await Promise.allSettled(
    map(([id, selection]) => {
      return apiCall({
        url: `${options.arg.baseUrl}/api/sources/${id}/selections?${query}`,
        method: 'PUT',
        token: options.arg.authToken,
        body: JSON.stringify(selection)
      }).then((r) => {
        return { id, selection: r }
      })
    }, options.arg.sourceIdSelectionPairList)
  )
  return response.reduce((acc, item) => {
    if (item.status !== 'fulfilled') {
      return acc
    }
    return { ...acc, [item.value.id]: item.value.selection }
  }, {})
}

export async function deleteProjectFetcher(
  _key: ProjectApiKey,
  options: {
    arg: {
      baseUrl: string
      projectIdList: string[]
    } & WithAuthToken
  }
): Promise<unknown> {
  return await apiCall({
    url: `${options.arg.baseUrl}/api/projects`,
    method: 'DELETE',
    token: options.arg.authToken,
    body: JSON.stringify(options.arg.projectIdList)
  })
}

export async function updateProjectFetcher(
  _key: any,
  options: {
    arg: {
      baseUrl: string
      project: Project
    } & WithAuthToken
  }
): Promise<unknown> {
  const searchParams = new URLSearchParams()
  searchParams.append('title', defaultTo('', options.arg.project.title))
  return await apiCall({
    url: `${options.arg.baseUrl}/api/projects/${options.arg.project.id}?${searchParams.toString()}`,
    method: 'PUT',
    token: options.arg.authToken
  })
}

export async function duplicateProjectFetcher(
  _key: ProjectApiKey,
  options: {
    arg: {
      baseUrl: string
      projectId: string
    } & WithAuthToken
  }
): Promise<ApiProject> {
  return await apiCall({
    url: `${options.arg.baseUrl}/api/projects/${options.arg.projectId}/duplicate`,
    method: 'POST',
    token: options.arg.authToken
  })
}

export type ProjectListFetcherArg = {
  baseUrl: string
  sortBy?: Maybe<keyof ApiProject>
  sortOrder?: Maybe<SortOrder>
  page: number
  searching?: Maybe<ProjectListFilter>
} & WithAuthToken

export async function getProjectListFetcher(key: ProjectApiKey): Promise<ApiProject[]> {
  const [baseUrl, sortBy, sortOrder, page, searching, authToken] = key
  const searchParams = new URLSearchParams()
  if (sortBy) {
    searchParams.append('sort_by', sortBy)
  }
  if (sortOrder) {
    searchParams.append('sort_order', sortOrder)
  }
  searchParams.append('page', String(page))
  if (!isNil(searching)) {
    searchParams.append('query', String(searching.search))
  }
  const url: string = `${baseUrl}/api/projects${searching ? '/search' : ''}`
  return await apiCall({ url, token: authToken, search: searchParams })
}

type StartResearchFetcherOptions = {
  arg: {
    planId: string
    baseUrl?: string
  } & WithAuthToken
}

export async function startResearchFetcher(_key: string, options: StartResearchFetcherOptions) {
  const url = `${options.arg.baseUrl}/api/research/plans/${options.arg.planId}/start`
  return apiCall({ url, method: 'POST', token: options.arg.authToken })
}

type StopResearchFetcherOptions = {
  arg: {
    planId: string
    baseUrl?: string
  } & WithAuthToken
}

export async function stopResearchFetcher(_key: string, options: StopResearchFetcherOptions) {
  const url = `${options.arg.baseUrl}/api/research/plans/${options.arg.planId}/stop`
  return apiCall({ url, method: 'POST', token: options.arg.authToken })
}

type GetResearchPlanFetcherOptions = {
  arg: {
    researchPlanId: string
    baseUrl?: string
  } & WithAuthToken
}

export async function getResearchPlanFetcher(
  _key: string,
  options: GetResearchPlanFetcherOptions
): Promise<ResearchPlan> {
  const url = `${options.arg.baseUrl}/api/research/plans/${options.arg.researchPlanId}`
  return apiCall({ url, method: 'GET', token: options.arg.authToken })
}

export type GetFileDownloadUrlFetcherArg = {
  baseUrl?: string
  path: string
} & WithAuthToken

export type GetFileDownloadUrlFetcher = MutationFetcher<string, string, GetFileDownloadUrlFetcherArg>

export const getFileDownloadUrlFetcher: GetFileDownloadUrlFetcher = async (_key, options) => {
  if (!options.arg.baseUrl || !options.arg.path) {
    throw new Error('getFileDownloadUrlFetcher: required parameters not provided')
  }

  const searchParams = new URLSearchParams()
  searchParams.append('path', options.arg.path)
  const query = searchParams.toString()

  return await apiCall({
    url: `${options.arg.baseUrl}/api/files/download?${query}`,
    method: 'GET',
    token: options.arg.authToken
  })
}

export function createVaultTokenFetcher(
  _key: string,
  options: { arg: { baseUrl?: string } & WithAuthToken }
): Promise<{ token: string }> {
  return apiCall({
    url: `${options.arg.baseUrl}/api/sources/drive/vault-token`,
    method: 'POST',
    token: options.arg.authToken
  })
}

export const getTickerListFetcher = async (key: [url: string, authToken?: string]): Promise<string[]> => {
  const [url, authToken] = key
  return await apiCall({
    url,
    method: 'GET',
    token: authToken
  })
}

export type CreateAssistantMessageFetcherOptions = {
  arg: {
    projectId: string
    researchPlanId?: string
    userMessage?: string
    baseUrl?: string
  } & WithAuthToken
}

export const createAssistantMessageFetcher = async (
  _key: string,
  options: CreateAssistantMessageFetcherOptions
): Promise<ApiProjectMessage> => {
  if (!options.arg.researchPlanId && !options.arg.userMessage) {
    throw new Error('createAssistantMessageFetcher: required parameters not provided')
  }

  // Append the user message or research plan id to the query string if they are provided
  const searchParams = new URLSearchParams()
  if (options.arg.userMessage) {
    searchParams.append('user_message', options.arg.userMessage)
  } else if (options.arg.researchPlanId) {
    searchParams.append('retry', options.arg.researchPlanId)
  }
  const query = searchParams.toString()

  return await apiCall({
    url: `${options.arg.baseUrl}/api/projects/${options.arg.projectId}/messages?${query}`,
    method: 'POST',
    token: options.arg.authToken
  })
}

export async function createScheduledResearchPlanFetcher(
  key: string,
  options: { arg: { authToken?: string; researchPlanId: string; schedule: string; timezone?: string } }
): Promise<ApiProject> {
  return await apiCall({
    url: key,
    method: 'POST',
    token: options.arg.authToken,
    body: JSON.stringify({
      research_plan_id: options.arg.researchPlanId,
      schedule: options.arg.schedule,
      timezone: options.arg.timezone
    })
  })
}

export async function updateScheduledResearchPlanFetcher(
  key: string,
  options: { arg: { authToken?: string; schedule: string; baseUrl?: string; id: string } }
): Promise<ApiProject> {
  const search = new URLSearchParams({ schedule: options.arg.schedule })
  return await apiCall({
    url: `${options.arg.baseUrl}${key}/${options.arg.id}`,
    method: 'PUT',
    token: options.arg.authToken,
    search
  })
}

export type DeleteScheduledResearchPlanFetcher = MutationFetcher<
  unknown,
  string,
  {
    idList: string[]
    baseUrl?: string
  } & WithAuthToken
>

export const deleteScheduledResearchPlanFetcher: DeleteScheduledResearchPlanFetcher = async (_key, options) => {
  if (!options.arg.idList || !options.arg.baseUrl) {
    throw new Error('deleteScheduledResearchPlanFetcher: required parameters not provided')
  }
  return await apiCall({
    url: `${options.arg.baseUrl}/api/scheduled_research_plans`,
    method: 'DELETE',
    token: options.arg.authToken,
    body: JSON.stringify(options.arg.idList)
  })
}

export type StripeSubscriptionCheckFetcher = MutationFetcher<
  { has_existing_subscription: boolean; redirect_url: string },
  string,
  { baseUrl?: string } & WithAuthToken
>

export const stripeSubscriptionCheckFetcher: StripeSubscriptionCheckFetcher = async (_key, options) => {
  if (!options.arg.baseUrl) {
    throw new Error('stripeSubscriptionCheckFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${options.arg.baseUrl}/api/stripe/subscription_check`,
    method: 'POST',
    token: options.arg.authToken
  })
}

export type StripeCustomerPortalFetcher = MutationFetcher<
  { has_existing_subscription: boolean; redirect_url: string },
  string,
  { baseUrl?: string } & WithAuthToken
>

export const stripeCustomerPortalFetcher: StripeCustomerPortalFetcher = async (_key, options) => {
  if (!options.arg.baseUrl) {
    throw new Error('stripeCustomerPortalFetcher: baseUrl not provided')
  }
  return await apiCall({
    url: `${options.arg.baseUrl}/api/stripe/customer_portal`,
    method: 'POST',
    token: options.arg.authToken
  })
}