export type Result<T = unknown, E extends Error = Error> = ResultOk<T> | ResultFailed<E>

export type ResultOk<T = unknown> = { ok: true; value: T }

export type ResultFailed<T extends Error = Error> = { ok: false; error: T }

export const result = {
  ok<T>(value: T): ResultOk<T> {
    return { ok: true, value }
  },
  failed<T extends Error>(error: T): ResultFailed<T> {
    return { ok: false, error }
  }
}
