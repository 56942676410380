import { useEnv } from '@/hooks/use-env'
import React, { useContext } from 'react'
import { Middleware, SWRConfig, SWRConfiguration } from 'swr'
import { AuthContext, AuthFunctionsContext } from './auth-provider'
import { apiCall } from '@/lib/api'
import { captureException } from '@sentry/react'

type Props = {
  children: React.ReactNode
}

export const ApiProvider: React.FC<Props> = ({ children }) => {
  const { APP_API_BASE_URL } = useEnv()
  const auth = useContext(AuthContext)
  const authFunctions = useContext(AuthFunctionsContext)

  const config: SWRConfiguration = {
    fetcher: (key) => {
      return apiCall({
        url: `${APP_API_BASE_URL}${key}`,
        token: auth?.session?.access_token
      })
    },
    use: [createAddAuthTokenMiddleware(auth?.session?.access_token), createApiUnauthorisedMiddleware()]
  }

  return <SWRConfig value={config}>{children}</SWRConfig>

  function createApiUnauthorisedMiddleware() {
    const middleware: Middleware = (useSWRNext) => (key, fetcher, config) => {
      const extendedFetcher: any = async (key: any, options: any) => {
        try {
          if (!fetcher) {
            return null
          }
          const res = await fetcher(key, options)
          return res
        } catch (e: any) {
          if (e.status === 401) {
            authFunctions.setApiUnauthorized()
          } else {
            captureException(e)
            console.error(e)
            throw e
          }
        }
      }
      return useSWRNext(key, extendedFetcher, config)
    }
    return middleware
  }
}

function createAddAuthTokenMiddleware(authToken?: string) {
  const authMiddleware: Middleware = (useSWRNext) => (key, fetcher, config) => {
    const extendedFetcher: any = (key: any, options?: any) => {
      if (!fetcher) {
        return null
      }
      const extendedOptions = {
        arg: {
          ...(options?.arg ?? {}),
          authToken
        }
      }
      return fetcher(key, extendedOptions)
    }
    return useSWRNext(key, extendedFetcher, config)
  }

  return authMiddleware
}
