import { Multiselect } from '@/components/multiselect'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { FormEvent } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { z } from 'zod'
import { FilingType, OutputValue } from '../types'

export const secFilingsSchema = z.object({
  targetCompany: z.string().min(1, { message: 'Target company is required' }),
  timePeriod: z.string().min(1, { message: 'Time period is required' }),
  filingType: z.array(z.nativeEnum(FilingType)).min(1, { message: 'Filing type is required' }),
  targetMetric: z.string().min(1, { message: 'Target metric is required' }),
  outputs: z.array(z.string())
})

const filingTypeOptionList: { value: FilingType; label: string }[] = Object.values(FilingType).map((item) => ({
  value: item,
  label: item
}))

const secFilingOutputsOptionList: { value: OutputValue; label: string }[] = Object.values(OutputValue)
  .filter((item) => item !== OutputValue.PythonScript)
  .map((item) => ({
    value: item,
    label: item
  }))

export type SecFilingsFormValue = z.infer<typeof secFilingsSchema>

type Props = {
  form: UseFormReturn<SecFilingsFormValue>
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
}

export function PromptBuilderSecFilingsForm({ form, onSubmit }: Props) {
  return (
    <Form key="prompt-builder-sec-filings-form" {...form}>
      <form key="prompt-builder-sec-filings-form" onSubmit={onSubmit}>
        <FormField
          control={form.control}
          name="targetCompany"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold text-xs">
                What are the target companies (company name or $ticker)? (use comma separator) *
              </FormLabel>
              <FormControl>
                <Input
                  placeholder="$MRK, $LLY, $ABT"
                  {...field}
                  data-testid="prompt-builder-sec-filings-target-company-input"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="h-5" />

        <FormField
          control={form.control}
          name="timePeriod"
          render={({ field }) => {
            const currentYear = new Date().getFullYear()
            return (
              <FormItem>
                <FormLabel className="font-semibold text-xs">What is the time period? (fiscal period) *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={`${currentYear - 1} to ${currentYear}`}
                    {...field}
                    data-testid="prompt-builder-sec-filings-time-period-input"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />

        <div className="h-5" />

        <FormField
          control={form.control}
          name="filingType"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold text-xs">What are the filing types? *</FormLabel>
              <FormControl>
                <Multiselect
                  placeholder="Select"
                  value={field.value}
                  options={filingTypeOptionList}
                  onChange={field.onChange}
                  data-testid="prompt-builder-sec-filings-filing-type-multiselect"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="h-5" />

        <FormField
          control={form.control}
          name="targetMetric"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold text-xs">What are the target metrics? *</FormLabel>
              <FormControl>
                <Input
                  placeholder="Quarterly product revenues, executive compensation, changes in risk factors, etc."
                  {...field}
                  data-testid="prompt-builder-sec-filings-target-metric-input"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Accordion type="multiple" className="">
          <AccordionItem value="1" className="border-b-0">
            <AccordionTrigger className="font-notmal text-sm hover:no-underline text-muted-foreground">
              Advanced
            </AccordionTrigger>
            <AccordionContent className="p-1">
              <FormField
                control={form.control}
                name="outputs"
                render={({ field }) => (
                  <FormItem className="">
                    <FormLabel className="font-semibold text-xs">Outputs</FormLabel>
                    {secFilingOutputsOptionList.map((item) => (
                      <div key={item.value} className="flex items-center space-x-2 mb-2">
                        <Checkbox
                          id={item.value}
                          checked={(field.value as string[])?.includes(item.value)}
                          onCheckedChange={(e) => {
                            const value = field.value ?? []
                            if (e) {
                              field.onChange([...value, item.value])
                            } else {
                              field.onChange((value as string[]).filter((value) => value !== item.value))
                            }
                          }}
                        />
                        <label htmlFor={item.value} className="font-normal text-xs text-muted-foreground mt-0">
                          {item.label}
                        </label>
                      </div>
                    ))}
                  </FormItem>
                )}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        <div className="h-5" />
      </form>
    </Form>
  )
}
