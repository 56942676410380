import { AuthContext } from '@/components/auth-provider'
import { Button } from '@/components/ui/button'
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { Spinner } from '@/components/ui/spinner'
import { useToast } from '@/components/ui/use-toast'
import { zodResolver } from '@hookform/resolvers/zod'
import { Eye, EyeOff } from 'lucide-react'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import z from 'zod'

const formSchema = z
  .object({
    password: z.string().min(1),
    confirmPassword: z.string().min(1)
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
        path: ['confirmPassword']
      })
    }
  })

type FormValue = z.infer<typeof formSchema>

type ChangePasswordPageProps = { onClose(): void }

export function ChangePasswordPage({ onClose }: ChangePasswordPageProps) {
  const form = useForm<FormValue>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  })

  const auth = useContext(AuthContext)
  const { toast } = useToast()
  const [loading, setLoading] = useState(false)
  const [passwordHidden, setPasswordHidden] = useState(true)
  const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(true)

  async function handleSubmit(formValue: FormValue) {
    try {
      setLoading(true)
      if (!auth?.client) {
        return
      }

      const { error } = await auth.client.auth.updateUser({
        password: formValue.password
      })

      if (error) {
        throw error
      }

      toast({
        variant: 'default',
        description: 'Password successfully updated.'
      })

      onClose()
    } catch (e) {
      const error = e as Error
      toast({
        variant: 'destructive',
        description: error.message
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      open
      onOpenChange={(value) => {
        if (!value) {
          onClose()
        }
      }}
    >
      <DialogContent className="px-0">
        <DialogHeader className="px-6">
          <DialogTitle>Change password</DialogTitle>
        </DialogHeader>

        <Separator />

        <Form {...form}>
          <form id="change-password-form" className="mb-5 px-6" onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="space-y-1 mb-5 relative">
                  <FormLabel className="font-semibold text-xs">Password</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter password" type={passwordHidden ? 'password' : 'text'} {...field} />
                  </FormControl>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="absolute right-0 top-6"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation()
                      setPasswordHidden((prev) => !prev)
                    }}
                  >
                    {passwordHidden ? (
                      <Eye size={16} className="text-purple" />
                    ) : (
                      <EyeOff size={16} className="text-purple" />
                    )}
                  </Button>
                  <p className="text-xs text-purple font-normal">
                    Minimum of 8 characters, including both lowercase and uppercase letters, and at least one number.
                  </p>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem className="space-y-1 mb-5 relative">
                  <FormLabel className="font-semibold text-xs">Confirm Password</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Repeat password"
                      type={confirmPasswordHidden ? 'password' : 'text'}
                      {...field}
                    />
                  </FormControl>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="absolute right-0 top-6"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation()
                      setConfirmPasswordHidden((prev) => !prev)
                    }}
                  >
                    {confirmPasswordHidden ? (
                      <Eye size={16} className="text-purple" />
                    ) : (
                      <EyeOff size={16} className="text-purple" />
                    )}
                  </Button>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* <Button type="submit" className="w-full mb-3" disabled={loading}>
              {loading ? <Spinner /> : 'Submit'}
            </Button> */}
          </form>
        </Form>
        <Separator />

        <DialogFooter className="px-6">
          <div className="flex justify-between w-full">
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>

            <Button form="change-password-form" type="submit">
              {loading ? <Spinner /> : 'Save Changes'}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
