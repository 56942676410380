import { cn } from '@/lib/utils'

type Props = {
  className?: string
}

export function ThreeDotsSpinner({ className }: Props) {
  const classes = cn(['ThreeDotsSpinner h-8 w-8', className])
  return (
    <svg viewBox="0 0 32 32" className={classes}>
      <style>
        {`
          .ThreeDotsSpinner circle {
            r: 2px;
            opacity: 0.5;
            cy: 16px;
          }
          .ThreeDotsSpinner circle.first {
            cx: 2px;
            animation: firstDotAnimation 3s infinite;
          }
          .ThreeDotsSpinner circle.second {
            r: 6px;
            cx: 16px;
            opacity: 1;
            animation: secondDotAnimation 3s infinite;
          }
          .ThreeDotsSpinner circle.third {
            cx: 30px;
            animation: thirdDotAnimation 3s infinite;
          }

          @keyframes firstDotAnimation {
            0% {
              cx: 2px;
              opacity: 0.5;
              r: 2px;
            }
            33% {
              cx: 16px;
              opacity: 1;
              r: 6px;
            }
            66% {
              cx: 30px;
              opacity: 0.5;
              r: 2px;
            }
            100% {
              cx: 2px;
              opacity: 0.5;
              r: 2px;
            }
          }

          @keyframes secondDotAnimation {
            0% {
              cx: 16px;
              opacity: 1;
              r: 6px;
            }
            33% {
              cx: 30px;
              opacity: 0.5;
              r: 2px;
            }
            66% {
              cx: 2px;
              opacity: 0.5;
              r: 2px;
            }
            100% {
              cx: 16px;
              opacity: 1;
              r: 6px;
            }
          }

          @keyframes thirdDotAnimation {
            0% {
              cx: 30px;
              opacity: 0.5;
              r: 2px;
            }
            33% {
              cx: 2px;
              opacity: 0.5;
              r: 2px;
            }
            66% {
              cx: 16px;
              opacity: 1;
              r: 6px;
            }
            100% {
              cx: 30px;
              opacity: 0.5;
              r: 2px;
            }
          }
        `}
      </style>
      <circle className="fill-primary first" cx={2} cy={16} />
      <circle className="fill-primary second" cx={16} cy={16} />
      <circle className="fill-primary third" cx={30} cy={16} />
    </svg>
  )
}
