import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { FormEvent } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { z } from 'zod'
import { OutputValue } from '../types'

export const transcriptsSchema = z.object({
  targetCompany: z.string().min(1, { message: 'Target company is required' }),
  timePeriod: z.string().min(1, { message: 'Time period is required' }),
  targetMetric: z.string().min(1, { message: 'Target metric is required' }),
  outputs: z.array(z.string().optional()).optional()
})

const transcriptsOutputsOptionList: { value: OutputValue; label: string }[] = Object.values(OutputValue)
  .filter((item) => item !== OutputValue.PythonScript)
  .map((item) => ({
    value: item,
    label: item
  }))

export type TranscriptsFormValue = z.infer<typeof transcriptsSchema>

type Props = {
  form: UseFormReturn<TranscriptsFormValue>
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
}

export function PromptBuilderTranscriptsForm({ form, onSubmit }: Props) {
  return (
    <Form key="prompt-builder-transcripts-form" {...form}>
      <form key="prompt-builder-transcripts-form" onSubmit={onSubmit}>
        <FormField
          control={form.control}
          name="targetCompany"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold text-xs">
                What are the target companies (company name or $ticker)? (use comma separator) *
              </FormLabel>
              <FormControl>
                <Input
                  placeholder="$MRK, $LLY, $ABT"
                  {...field}
                  data-testid="prompt-builder-transcripts-target-company-input"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="h-5" />

        <FormField
          control={form.control}
          name="timePeriod"
          render={({ field }) => {
            const currentYear = new Date().getFullYear()
            return (
              <FormItem>
                <FormLabel className="font-semibold text-xs">What is the time period? (fiscal period) *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={`${currentYear - 1} to ${currentYear}`}
                    {...field}
                    data-testid="prompt-builder-transcripts-time-period-input"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />

        <div className="h-5" />

        <FormField
          control={form.control}
          name="targetMetric"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold text-xs">What are the target metrics? *</FormLabel>
              <FormControl>
                <Input
                  placeholder="Eg., extract the guidance for each metric mentioned by management"
                  {...field}
                  data-testid="prompt-builder-transcripts-target-metric-input"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Accordion type="multiple" className="">
          <AccordionItem value="1" className="border-b-0">
            <AccordionTrigger className="font-notmal text-sm hover:no-underline text-muted-foreground">
              Advanced
            </AccordionTrigger>
            <AccordionContent className="p-1">
              <FormField
                control={form.control}
                name="outputs"
                render={({ field }) => (
                  <FormItem className="">
                    <FormLabel className="font-semibold text-xs">Outputs</FormLabel>
                    {transcriptsOutputsOptionList.map((item) => (
                      <div key={item.value} className="flex items-center space-x-2 mb-2">
                        <Checkbox
                          id={item.value}
                          checked={(field.value as string[])?.includes(item.value)}
                          onCheckedChange={(e) => {
                            const value = field.value ?? []
                            if (e) {
                              field.onChange([...value, item.value])
                            } else {
                              field.onChange((value as string[]).filter((value) => value !== item.value))
                            }
                          }}
                        />
                        <label htmlFor={item.value} className="font-normal text-xs text-muted-foreground mt-0">
                          {item.label}
                        </label>
                      </div>
                    ))}
                    <FormMessage />
                  </FormItem>
                )}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        <div className="h-5" />
      </form>
    </Form>
  )
}
