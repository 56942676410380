import { useContext, useState } from 'react'
import { Button } from './ui/button'
import { ProjectPageContext } from '@/contexts/project'
import { ProjectUpdateDialog } from './project-dialog'
import { useEnv } from '@/hooks/use-env'
import { useToast } from './ui/use-toast'
import { Project } from '@/types'
import useSWRMutation from 'swr/mutation'
import { updateProjectFetcher } from '@/api/fetcher'

export function UpdateProjectButton() {
  const projectContext = useContext(ProjectPageContext)
  const env = useEnv()
  const { toast } = useToast()
  const [updateProjectOpen, setUpdateProjectOpen] = useState(false)
  const apiUpdateProject = useSWRMutation('updateProject', updateProjectFetcher)
  async function handleUpdate(item: Project) {
    try {
      if (!env.APP_API_BASE_URL) {
        throw new Error('Failed to update project. Application misconfigured.')
      }
      await apiUpdateProject.trigger({ baseUrl: env.APP_API_BASE_URL, project: item })
      projectContext?.refetch()
    } catch (e) {
      const error = e as Error
      toast({ variant: 'destructive', description: error.message })
    }
  }
  return (
    <>
      <Button
        variant="ghost"
        className="text-lg text-bold text-black whitespace-normal h-fit"
        data-testid="btn-update-project"
        onClick={() => setUpdateProjectOpen(true)}
      >
        {projectContext?.project.title}
      </Button>

      {updateProjectOpen && projectContext?.project && (
        <ProjectUpdateDialog
          open={updateProjectOpen}
          onOpenChange={setUpdateProjectOpen}
          project={projectContext.project}
          onSubmit={handleUpdate}
        />
      )}
    </>
  )
}
