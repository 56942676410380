import { AuthPageLayout } from '@/components/auth-page-layout'
import { AuthContext } from '@/components/auth-provider'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Spinner } from '@/components/ui/spinner'
import { useToast } from '@/components/ui/use-toast'
import { routePath } from '@/router/route-path'
import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowLeft } from 'lucide-react'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import z from 'zod'

const formSchema = z.object({
  email: z.string().min(1).email()
})

type FormValue = z.infer<typeof formSchema>

export function ForgotPasswordPage() {
  const form = useForm<FormValue>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: ''
    }
  })

  const auth = useContext(AuthContext)
  const { toast } = useToast()
  const [loading, setLoading] = useState(false)

  async function handleSubmit(formValue: FormValue) {
    try {
      setLoading(true)
      if (!auth?.client) {
        return
      }

      const { error } = await auth.client.auth.resetPasswordForEmail(formValue.email, {
        redirectTo: `${window.location.origin}${routePath.settings}${routePath.changePassword}`
      })

      if (error) {
        throw error
      }

      toast({
        variant: 'default',
        description: 'Confirmation sent to specified email.'
      })
    } catch (e) {
      toast({
        variant: 'destructive',
        description: 'There was an error while sending confirmation email.'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthPageLayout title="Forgot Password">
      <div className="h-10" />

      <Link to={routePath.login} className="flex justify-start items-center gap-2">
        <ArrowLeft size={12} className="text-purple" />
        <span className="text-xs">Back</span>
      </Link>

      <div className="h-10" />

      <Form {...form}>
        <form className="mb-5" onSubmit={form.handleSubmit(handleSubmit)}>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="space-y-1 mb-5">
                <FormLabel className="font-semibold text-xs">Email</FormLabel>
                <FormControl>
                  <Input placeholder="Enter email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button type="submit" className="w-full mb-3" disabled={loading}>
            {loading ? <Spinner /> : 'Reset Password'}
          </Button>
        </form>
      </Form>
    </AuthPageLayout>
  )
}
