import { AlertCircle } from 'lucide-react'
import { Alert, AlertDescription, AlertTitle } from './ui/alert'

export function PageError() {
  return (
    <div className="flex justify-center items-center h-screen w-full">
      <Alert variant="destructive" className="w-[300px]">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>There was an error. Reload the page and try again.</AlertDescription>
      </Alert>
    </div>
  )
}
