import { ResearchReportItem } from '@/components/research-report-item'
import { ThreeDotsSpinner } from '@/components/three-dots-spinner'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Separator } from '@/components/ui/separator'
import { UpdateProjectButton } from '@/components/update-project-button'
import { useMount } from '@/hooks/use-mount'
import { cn } from '@/lib/utils'
import { ResearchReport } from '@/types'
import { ChevronLeft, ChevronRight, FileIcon } from 'lucide-react'
import { isEmpty, map } from 'ramda'
import { useRef, useState } from 'react'
import { match } from 'ts-pattern'

type ChatResultProps = {
  expanded: boolean
  loading?: boolean
  reportList?: ResearchReport[]
  className?: string
  style?: React.CSSProperties
  onExpand(value: boolean): void
}

export function ProjectReports({
  className,
  style,
  expanded,
  loading = false,
  reportList = [],
  onExpand
}: ChatResultProps) {
  const contentContainerRef = useRef<HTMLDivElement | null>(null)

  const [height, setHeight] = useState(0)
  useMount(() => {
    setScrollableAreaHeight()
  })

  function setScrollableAreaHeight() {
    if (contentContainerRef.current) {
      const rect = contentContainerRef.current.getBoundingClientRect()
      setHeight(() => document.body.offsetHeight - rect.top - 54)
    }
  }

  return (
    <Card className={cn('flex flex-col', className)} style={style}>
      <CardHeader>
        <CardTitle className="flex items-center">
          <Button variant="ghost" size="icon" className="mr-2 max-md:hidden" onClick={() => onExpand(!expanded)}>
            {expanded ? (
              <>
                <ChevronRight className="-mr-4 text-purple" />
                <ChevronRight className="text-purple" />
              </>
            ) : (
              <>
                <ChevronLeft className="-mr-4 text-purple" />
                <ChevronLeft className="text-purple" />
              </>
            )}
          </Button>
          <UpdateProjectButton />
          <div className="grow" />
        </CardTitle>
      </CardHeader>
      <Separator />
      <CardContent className="p-4 grow" ref={contentContainerRef}>
        <ScrollArea style={{ height }}>
          {match(reportList)
            .when(
              () => loading,
              () => (
                <div className="flex justify-center">
                  <ThreeDotsSpinner />
                </div>
              )
            )
            .when(
              (input) => !isEmpty(input),
              (input) => map((item) => <ResearchReportItem report={item} key={item.id} />, input)
            )
            .otherwise(() => (
              <EmptyReportContent />
            ))}
        </ScrollArea>
      </CardContent>
    </Card>
  )
}

function EmptyReportContent() {
  return (
    <div className="flex flex-col justify-center items-center p-6">
      <FileIcon />
      <div className="text-primary font-normal mb-4 text-center">
        Provide Octagon with your research objectives to generate a report.
      </div>
    </div>
  )
}
