import { Maybe, ResearchReportSource } from '@/types'
import { defaultTo, map, prop, sortBy } from 'ramda'
import { getFileDownloadUrlFetcher } from '@/api/fetcher'
import { AuthContext } from '@/components/auth-provider'
import { useContext } from 'react'
import { useEnv } from '@/hooks/use-env'

type SourceListProps = {
  sources?: Maybe<ResearchReportSource[]>
}

export function SourceList({ sources }: SourceListProps) {
  const env = useEnv()
  const auth = useContext(AuthContext)

  const handleClick = async (url: Maybe<string>) => {
    if (!url) {
      return
    }
    if (url.startsWith('/')) {
      const downloadUrl = await getFileDownloadUrlFetcher('', {
        arg: {
          baseUrl: env.APP_API_BASE_URL,
          path: url,
          authToken: auth?.session?.access_token
        }
      })
      window.open(downloadUrl, '_blank', 'noopener,noreferrer')
    } else {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  }

  const sorted = sortBy(prop('order'), defaultTo([], sources))

  return (
    <div>
      {map(
        (sourceItem) => (
          <div className="text-xs pb-2" key={sourceItem.order}>
            <span>[{sourceItem.order}]</span>&nbsp;
            {sourceItem.url ? (
              <a href="#" onClick={() => handleClick(sourceItem.url)} className="text-blue-500">
                {sourceItem.content}
              </a>
            ) : (
              <span>{sourceItem.content}</span>
            )}
          </div>
        ),
        sorted
      )}
    </div>
  )
}
