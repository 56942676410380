import { AuthPageLayout } from '@/components/auth-page-layout'
import { AuthContext } from '@/components/auth-provider'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Spinner } from '@/components/ui/spinner'
import { useToast } from '@/components/ui/use-toast'
import { useEnv } from '@/hooks/use-env'
import { routePath } from '@/router/route-path'
import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowLeft, Eye, EyeOff } from 'lucide-react'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import z from 'zod'

const formSchema = z
  .object({
    fullName: z.string().min(1),
    email: z.string().min(1).email(),
    password: z.string().min(1),
    confirmPassword: z.string().min(1)
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
        path: ['confirmPassword']
      })
    }
  })

type FormValue = z.infer<typeof formSchema>

export function SignupEmailPage() {
  const form = useForm<FormValue>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: '',
      email: '',
      password: '',
      confirmPassword: ''
    }
  })

  const [passwordHidden, setPasswordHidden] = useState(true)
  const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(true)
  const authClient = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const { toast } = useToast()
  const env = useEnv()

  const redirectToAfterVerification = `${window.location.origin}${routePath.emailVerification}`

  async function handleSubmit(value: FormValue) {
    try {
      setLoading(true)
      const result = await authClient?.client?.auth.signUp({
        email: value.email,
        password: value.password,
        options: {
          emailRedirectTo: redirectToAfterVerification,
          data: {
            full_name: value.fullName,
            name: value.fullName
          }
        }
      })

      if (result?.error) {
        throw result.error
      }

      // navigate({ pathname: routePath.home })
      setIsCompleted(true)
    } catch (e) {
      const error = e as Error
      toast({
        variant: 'destructive',
        description: error.message
      })
    } finally {
      setLoading(false)
    }
  }

  async function handleResendVerificationClick() {
    try {
      await authClient?.client?.auth.resend({
        type: 'signup',
        email: form.getValues('email'),
        options: {
          emailRedirectTo: redirectToAfterVerification
        }
      })
      toast({
        variant: 'default',
        description: 'Verification email sent to your email'
      })
    } catch (e) {
      const error = e as Error
      toast({
        variant: 'destructive',
        description: error.message
      })
    }
  }

  return (
    <AuthPageLayout title="Create account" description="Start your Professional plan. Cancel any time">
      <div className="h-10" />

      <Link to={routePath.signup} className="flex justify-start items-center gap-2">
        <ArrowLeft size={12} className="text-purple" />
        <span className="text-xs">Back</span>
      </Link>

      <div className="h-10" />

      {isCompleted ? (
        <div>
          <div>We've sent a verification email to:</div>
          <div className="font-semibold">{form.getValues('email')}</div>
          <div className="h-4" />
          <div className="text-sm">
            Click the link in your email to verify your account. If you can't find the email check your spam folder or{' '}
            <Button
              className="p-0 h-fit text-green-500 hover:text-green"
              variant="ghost"
              size="sm"
              onClick={handleResendVerificationClick}
            >
              click here to resend
            </Button>
          </div>
        </div>
      ) : (
        <Form {...form}>
          <form className="mb-5" onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name="fullName"
              render={({ field }) => (
                <FormItem className="space-y-1 mb-5">
                  <FormLabel className="font-semibold text-xs">Full Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter full name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="space-y-1 mb-5">
                  <FormLabel className="font-semibold text-xs">Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="space-y-1 mb-5 relative">
                  <FormLabel className="font-semibold text-xs">Password</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter password" type={passwordHidden ? 'password' : 'text'} {...field} />
                  </FormControl>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="absolute right-0 top-6"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation()
                      setPasswordHidden((prev) => !prev)
                    }}
                  >
                    {passwordHidden ? (
                      <Eye size={16} className="text-purple" />
                    ) : (
                      <EyeOff size={16} className="text-purple" />
                    )}
                  </Button>
                  <p className="text-xs text-purple font-normal">
                    Minimum of 8 characters, including both lowercase and uppercase letters, and at least one number.
                  </p>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem className="space-y-1 mb-5 relative">
                  <FormLabel className="font-semibold text-xs">Confirm Password</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Repeat password"
                      type={confirmPasswordHidden ? 'password' : 'text'}
                      {...field}
                    />
                  </FormControl>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="absolute right-0 top-6"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation()
                      setConfirmPasswordHidden((prev) => !prev)
                    }}
                  >
                    {confirmPasswordHidden ? (
                      <Eye size={16} className="text-purple" />
                    ) : (
                      <EyeOff size={16} className="text-purple" />
                    )}
                  </Button>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="text-sm text-center text-purple mb-3">
              Signing up for an Octagon account means you agree to the{' '}
              <a href={env.APP_PRIVACY_POLICY_URL} target="_blank" className="text-blue-400 underline">
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href={env.APP_TERMS_URL} target="_blank" className="text-blue-400 underline">
                Terms of Service
              </a>
              .
            </div>

            <Button type="submit" className="w-full mb-3" disabled={loading}>
              {loading ? <Spinner /> : 'Create Account'}
            </Button>

            <div className="text-sm text-center text-purple">
              Already have an account?{' '}
              <Link to={routePath.login} className="text-secondary-foreground underline">
                Log in
              </Link>
            </div>
          </form>
        </Form>
      )}
    </AuthPageLayout>
  )
}
