import { ResearchSuggestion } from '@/types'
import { ThreeDotsSpinner } from './three-dots-spinner'
import { Button } from './ui/button'
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover'
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react'
import { PromptSelect } from './prompt-select'

type SuggestionSelectProps = {
  suggestionList: ResearchSuggestion[]
  popupWidth: number
  popupHeight: number
  open: boolean
  loading: boolean
  onOpenChange(value: boolean): void
  onSelect(value: ResearchSuggestion): void
}

export function SuggestionSelect({
  suggestionList,
  popupWidth,
  popupHeight,
  open,
  loading,
  onOpenChange,
  onSelect
}: SuggestionSelectProps) {
  if (loading) {
    return (
      <div className="my-2 relative">
        <div className="flex flex-nowrap gap-2 h-6">
          <ThreeDotsSpinner />
        </div>
      </div>
    )
  }
  return (
    <div className="my-2 relative">
      <div className="flex flex-nowrap gap-2 h-6">
        {suggestionList.map((item) => (
          <Button key={item.id} variant="outline" className="h-6 font-normal text-xs" onClick={() => onSelect(item)}>
            {item.title}
          </Button>
        ))}

        <div className="flex absolute right-0 top-0 w-20 text-right">
          <div className="flex-grow bg-gradient-to-l from-secondary to-transparent" />
          <Popover open={open} onOpenChange={onOpenChange}>
            <PopoverTrigger asChild>
              <Button size="icon" className="h-6 w-6" data-testid="btn-toggle-suggestions">
                {open ? <ChevronDownIcon /> : <ChevronUpIcon />}
              </Button>
            </PopoverTrigger>
            <PopoverContent
              className="w-[500px] h-[500px] overflow-y-auto"
              style={{ width: popupWidth, height: popupHeight }}
              align="end"
              alignOffset={0}
              sideOffset={10}
              side="left"
            >
              <PromptSelect prompts={suggestionList} onSelect={onSelect} />
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </div>
  )
}
