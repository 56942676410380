import { useEnv } from '@/hooks/use-env'
import { apiCall } from '@/lib/api'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { z } from 'zod'
import { Button } from './ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from './ui/form'
import { Input } from './ui/input'
import { Spinner } from './ui/spinner'
import { useToast } from './ui/use-toast'
import { noop } from '@/lib/function'

type SnowflakeFormProps = {
  onSubmit?(value: SnowflakeFormValue): void
}

const formSchema = z.object({
  accountName: z.string().min(1),
  userName: z.string().min(1),
  password: z.string().min(1),
  database: z.string().min(1)
})

type SnowflakeFormValue = z.infer<typeof formSchema>

export function SnowflakeForm({ onSubmit = noop }: SnowflakeFormProps) {
  const env = useEnv()
  const { toast } = useToast()
  const form = useForm<SnowflakeFormValue>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      accountName: '',
      userName: '',
      database: '',
      password: ''
    }
  })

  useSWR<{
    credentials?: {
      account: string
      user: string
      database?: string
    }
  }>('/api/sources/snowflake', null, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: true,
    onSuccess(data) {
      const valueFromApi: SnowflakeFormValue = {
        accountName: data?.credentials?.account ?? '',
        userName: data?.credentials?.user ?? '',
        password: '',
        database: data?.credentials?.database ?? ''
      }
      form.setValue('accountName', valueFromApi.accountName)
      form.setValue('userName', valueFromApi.userName)
      form.setValue('database', valueFromApi.database)
      form.setValue('password', valueFromApi.password)
    },
    onError() {
      toast({
        variant: 'destructive',
        description: 'Failed to retrieve Snowflake credentials! Try again.'
      })
    }
  })

  const apiUpdateSnowflake = useSWRMutation(
    '/api/sources/snowflake',
    (
      key: string,
      options: {
        arg: {
          value: SnowflakeFormValue
          authToken?: string
        }
      }
    ) => {
      return apiCall({
        url: `${env.APP_API_BASE_URL}${key}`,
        method: 'PUT',
        token: options.arg.authToken,
        body: JSON.stringify({
          credentials: {
            account: options.arg.value.accountName,
            user: options.arg.value.userName,
            database: options.arg.value.database,
            password: options.arg.value.password
          }
        })
      })
    },
    {
      onSuccess() {
        toast({
          variant: 'default',
          description: 'Snowflake credentials successfully saved!'
        })
      },
      onError() {
        toast({
          variant: 'destructive',
          description: 'Failed to save Snowflake credentials! Try again.'
        })
      }
    }
  )

  function handleSubmit(value: SnowflakeFormValue) {
    onSubmit(value)
    apiUpdateSnowflake.trigger({ value })
  }

  return (
    <div className="bg-white rounded flex flex-col h-full px-20 pt-10">
      <div className="text-xl font-bold text-center">Snowflake</div>
      <div className="font-normal text-purple text-center pb-5">Run research projects on your Snowflake data</div>
      <div className="text-sm font-bold pb-5">
        Snowflake Credentials -{' '}
        <a className="text-blue-500" href="https://google.com" target="_blank">
          Setup Instructions
        </a>
      </div>
      <Form {...form}>
        <form className="flex flex-col gap-4" onSubmit={form.handleSubmit(handleSubmit)}>
          <FormField
            control={form.control}
            name="accountName"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-semibold text-xs">Account Name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter account name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="userName"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-semibold text-xs">Username</FormLabel>
                <FormControl>
                  <Input placeholder="Enter username" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-semibold text-xs">Password</FormLabel>
                <FormControl>
                  <Input type="password" placeholder="Enter password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="database"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-semibold text-xs">Database</FormLabel>
                <FormControl>
                  <Input placeholder="Enter database" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button type="submit" disabled={apiUpdateSnowflake.isMutating}>
            {apiUpdateSnowflake.isMutating ? <Spinner /> : 'Save'}
          </Button>
        </form>
      </Form>
    </div>
  )
}
