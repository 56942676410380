import { cn } from '@/lib/utils'
import { routePath } from '@/router/route-path'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Button } from './ui/button'
import { SourcesPageReadOnlyContext } from '@/contexts/sources-page-readonly'
import { useContext } from 'react'

export function ChatTabs() {
  const readOnly = useContext(SourcesPageReadOnlyContext)
  const routeParams = useParams()
  const location = useLocation()

  const activeTab = {
    chat: new RegExp(routePath.chat).test(location.pathname),
    sources: new RegExp(routePath.sources).test(location.pathname)
  }

  const activeCss = 'bg-primary text-primary-foreground'
  const inactiveCss = 'bg-secondary text-secondary-foreground'

  function getCss(tabName: keyof typeof activeTab) {
    return activeTab[tabName] ? activeCss : inactiveCss
  }

  return (
    <>
      {!readOnly && (
        <div className="p-0 border-purple border-2 rounded-lg h-fit">
          <Button
            data-testid="btn-chat-tab"
            asChild
            variant={activeTab.chat ? 'default' : 'outline'}
            className={cn('h-8 font-normal', getCss('chat'))}
          >
            <Link to={`${routePath.project}/${routeParams.projectId}${routePath.chat}`}>Research</Link>
          </Button>
          <Button
            data-testid="btn-source-tab"
            asChild
            variant={activeTab.sources ? 'default' : 'outline'}
            className={cn('h-8 font-normal', getCss('sources'))}
          >
            <Link to={`${routePath.project}/${routeParams.projectId}${routePath.sources}`}>Sources</Link>
          </Button>
        </div>
      )}
    </>
  )
}
