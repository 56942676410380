import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react'
import { Button } from './ui/button'
import { Column } from '@tanstack/react-table'
import { Project } from '@/types'

export function SortableTableHeader({ column, children }: { column: Column<Project>; children: React.ReactNode }) {
  return (
    <Button variant="ghost" className="pl-0" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
      {children}
      {!column.getIsSorted() ? null : column.getIsSorted() === 'asc' ? (
        <ChevronUpIcon className="ml-2 h-4 w-4" />
      ) : (
        <ChevronDownIcon className="ml-2 h-4 w-4" />
      )}
    </Button>
  )
}
