import { createContext, useEffect, useMemo, useState } from 'react'

import { Session, SupabaseClient, createClient } from '@supabase/supabase-js'
import { useEnv } from '@/hooks/use-env'
import { Maybe } from '@/types'
import { PageLoader } from './page-loader'
import { useToast } from './ui/use-toast'
import { noop } from '@/lib/function'

export type AuthContextValue = {
  client: Maybe<SupabaseClient>
  session: Maybe<Session>
  loaded: boolean
  passwordRecovery: boolean
  apiAuthorized: boolean
}

export const AuthContext = createContext<Maybe<AuthContextValue>>(null)

export const AuthFunctionsContext = createContext<{
  setApiUnauthorized(): void
}>({
  setApiUnauthorized: noop
})

type AuthProviderProps = { children: React.ReactNode }

export function AuthProvider({ children }: AuthProviderProps) {
  const env = useEnv()
  const { toast } = useToast()
  const [authContextValue, setAuthContextValue] = useState<AuthContextValue>({
    client: null,
    session: null,
    loaded: false,
    passwordRecovery: false,
    apiAuthorized: true
  })

  useEffect(() => {
    if (!env.APP_SUPABASE_AUTH_URL || !env.APP_SUPABASE_AUTH_PUBLIC_TOKEN) {
      return
    }
    const supabase = createClient(env.APP_SUPABASE_AUTH_URL, env.APP_SUPABASE_AUTH_PUBLIC_TOKEN)

    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'INITIAL_SESSION') {
        setAuthContextValue((prev) => ({ ...prev, client: supabase, session, loaded: true }))
      } else if (event === 'SIGNED_IN') {
        setAuthContextValue((prev) => ({ ...prev, client: supabase, session, loaded: true }))
      } else if (event === 'SIGNED_OUT') {
        setAuthContextValue((prev) => ({ ...prev, session }))
      } else if (event === 'PASSWORD_RECOVERY') {
        setAuthContextValue((prev) => ({ ...prev, session, passwordRecovery: true }))
      } else if (event === 'TOKEN_REFRESHED') {
        setAuthContextValue((prev) => ({ ...prev, session }))
      } else if (event === 'USER_UPDATED') {
        setAuthContextValue((prev) => ({ ...prev, session }))
      }
    })
    return () => data.subscription.unsubscribe()
  }, [])

  const authFunctionsContextValue = useMemo(
    () => ({
      setApiUnauthorized() {
        setAuthContextValue((prev) => ({ ...prev, apiAuthorized: false }))
      }
    }),
    []
  )

  if (!env.APP_SUPABASE_AUTH_URL || !env.APP_SUPABASE_AUTH_PUBLIC_TOKEN) {
    toast({
      variant: 'destructive',
      description: 'Error occured. Application misconfigured.'
    })
    return <PageLoader />
  }

  if (!authContextValue.loaded) {
    return <PageLoader />
  }

  return (
    <AuthContext.Provider value={authContextValue}>
      <AuthFunctionsContext.Provider value={authFunctionsContextValue}>{children}</AuthFunctionsContext.Provider>
    </AuthContext.Provider>
  )
}
