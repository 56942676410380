import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Command, CommandGroup, CommandItem } from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { CaretSortIcon } from '@radix-ui/react-icons'
import { append, reject } from 'ramda'
import { useEffect, useRef, useState } from 'react'

type MultiselectProps = {
  placeholder?: string
  options: { value: string; label: string }[]
  value: string[]
  'data-testid'?: string
  onChange: (value: string[]) => void
}

export function Multiselect({
  placeholder = 'Select',
  options,
  value,
  onChange,
  'data-testid': dataTestId
}: MultiselectProps) {
  const [open, setOpen] = useState(false)

  const searchButtonRef = useRef<HTMLButtonElement>(null)
  const [searchButtonWidth, setSearchButtonWidth] = useState(0)

  useEffect(() => {
    if (searchButtonRef.current) {
      const { width } = searchButtonRef.current.getBoundingClientRect()
      setSearchButtonWidth(width)
    }
  }, [])

  function handleSearchOpenChange(value: boolean) {
    setOpen(value)
  }

  function handleSearchQueryChange() {}

  const triggerText = value.length > 0 ? value.join(', ') : placeholder

  return (
    <Popover open={open} onOpenChange={handleSearchOpenChange}>
      <PopoverTrigger asChild data-testid={dataTestId}>
        <Button
          ref={searchButtonRef}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between font-normal"
        >
          {triggerText}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0" style={{ width: searchButtonWidth, pointerEvents: 'all' }}>
        <Command onChange={handleSearchQueryChange} shouldFilter={false}>
          <CommandGroup>
            {options.map((item) => {
              const optionAdded = value.some((valueItem) => valueItem === item.value)
              return (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  className="flex justify-start items-center border-b h-11"
                  data-testid={`multiselect-option-${item.value}`}
                  onSelect={() => {
                    const nextValue: string[] = optionAdded
                      ? reject((valueItem) => valueItem === item.value, value)
                      : append(item.value, value)
                    onChange(nextValue)
                  }}
                >
                  <Checkbox className="mr-3" checked={optionAdded} />
                  <span className="">{item.label}</span>
                </CommandItem>
              )
            })}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
