import { ActiveSelection } from '@/types'
import { CheckedState } from '@radix-ui/react-checkbox'

/**
 * Issues:
 * 1. returns False if all items from path selected one by one, e.g. "includes" contains each item
 * 2. returns indeterminate if all items from path deselected one by one, e.g. "excludes" contains each item
 */
export function isPathSelected(evalPath: string, include: string[] = [], exclude: string[] = []): CheckedState {
  if (exclude.filter((path) => path === evalPath).length === 1) {
    return false
  }

  if (exclude.some((path) => path.startsWith(evalPath))) {
    return 'indeterminate'
  }

  if (include.some((path) => evalPath.startsWith(path))) {
    return true
  }

  for (let path of include) {
    if (path === '/' || evalPath.startsWith(path + '/')) {
      return true
    }
  }

  return false
}

export function createRootSourceSelection(): ActiveSelection {
  return {
    path: [],
    name: ''
  }
}
