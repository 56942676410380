import Logo from '@/assets/logo-rect.svg'
import { useEnv } from '@/hooks/use-env'
import { routePath } from '@/router/route-path'
import { Pencil2Icon } from '@radix-ui/react-icons'
import { BookOpenText, Clock, FolderOpen, LayoutGrid, LogOut, MenuIcon, SettingsIcon } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Button } from './ui/button'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from './ui/sheet'

export function PageMenuMobile() {
  const env = useEnv()
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button size="icon" variant="ghost" data-testid="page-menu-mobile-trigger">
          <MenuIcon></MenuIcon>
        </Button>
      </SheetTrigger>

      <SheetContent side="left" className="w-[300px] flex flex-col">
        <SheetHeader>
          <SheetTitle>
            <div className="flex items-center gap-2 mb-8">
              <img className="md:mb-[20px] p-0 shrink-0 h-8" src={Logo} />
            </div>
          </SheetTitle>
          <SheetDescription></SheetDescription>
        </SheetHeader>

        <div className="flex flex-col gap-4 grow">
          <Link to={routePath.createProject} className="flex items-center">
            <Pencil2Icon className="h-4 w-4 mr-2" />
            <span>New Project</span>
          </Link>

          <Link to={routePath.projects} className="flex items-center">
            <LayoutGrid className="h-4 w-4 mr-2" />
            <span>Projects</span>
          </Link>

          <Link to={routePath.scheduledResearchPlan} className="flex items-center">
            <Clock className="h-4 w-4 mr-2" />
            <span>Scheduled Research Plans</span>
          </Link>

          <div className="grow" />

          <Link to={routePath.manageSources} className="flex items-center">
            <FolderOpen className="h-4 w-4 mr-2" />
            <span>Sources</span>
          </Link>

          <Link to={env.APP_FAQ_URL ?? ''} target="_blank" className="flex items-center">
            <BookOpenText className="h-4 w-4 mr-2" />
            <span>FAQ</span>
          </Link>

          <Link to={routePath.settings} className="flex items-center">
            <SettingsIcon className="h-4 w-4 mr-2" />
            <span>Settings</span>
          </Link>

          <Link to={routePath.logout} className="flex items-center" data-testid="btn-signout">
            <LogOut className="h-4 w-4 mr-2" />
            <span>Sign Out</span>
          </Link>
        </div>
      </SheetContent>
    </Sheet>
  )
}
