import { ApiProject, ApiProjectMessage, Maybe, Project } from '@/types'
import { createContext } from 'react'

export type ProjectPageContextValue = {
  project: Project
  projectMessageList: ApiProjectMessage[]
  refetch(): Promise<Maybe<ApiProject>>
  refetchMessageList(): Promise<Maybe<ApiProjectMessage[]>>
}
export const ProjectPageContext = createContext<Maybe<ProjectPageContextValue>>(null)
