import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from './auth-provider'
import { isNil } from 'ramda'

type Props = { children: React.ReactNode }

export function AuthGuard({ children }: Props) {
  const authClient = useContext(AuthContext)
  const isSessionInvalid = isNil(authClient?.session)

  if (isSessionInvalid) {
    return <Navigate to="/login" />
  }

  if (!authClient.apiAuthorized) {
    return <Navigate to="/logout" />
  }

  return children
}
