import { PromptContext, PromptFunctionsContext } from '@/contexts/prompt'
import { Maybe } from '@/types'
import React, { useRef } from 'react'

type PromptProviderProps = {
  children: React.ReactNode
}

export function PromptProvider(props: PromptProviderProps) {
  const ref = useRef<Record<string, string>>({})

  function handleUpdateValue(projectId: Maybe<string>, value: string) {
    if (projectId) {
      ref.current[projectId] = value
    }
  }

  return (
    <PromptContext.Provider value={ref}>
      <PromptFunctionsContext.Provider value={handleUpdateValue}>{props.children}</PromptFunctionsContext.Provider>
    </PromptContext.Provider>
  )
}
