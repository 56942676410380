import { captureException } from '@sentry/react'
import React from 'react'
import { PageError } from './page-error'

type Props = { children: React.ReactNode }

type State = { hasError: boolean }

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error)
    captureException(error)
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return <PageError />
    }

    return this.props.children
  }
}
