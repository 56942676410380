import { noop } from '@/lib/function'
import { Project } from '@/types'
import { Edit, Trash } from 'lucide-react'
import { useState } from 'react'
import { ProjectDeleteDialog, ProjectPinDialog, ProjectShareDialog, ProjectUpdateDialog } from './project-dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from './ui/dropdown-menu'
import { Separator } from './ui/separator'

type ProjectMenuProps = {
  project: Project
  isPinned?: boolean
  children: React.ReactNode
  onPin?(value: Project): void
  onDuplicate(value: Project): void
  onDelete(value: Project): void
  onUpdate(value: Project): void
}

export function ProjectMenu({
  project,
  isPinned = false,
  children,
  onPin = noop,
  onDelete,
  onUpdate
}: ProjectMenuProps) {
  const [menuOpened, setMenuOpened] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  const [updateOpen, setUpdateOpen] = useState(false)
  const [pinOpen, setPinOpen] = useState(false)

  return (
    <>
      <DropdownMenu open={menuOpened} onOpenChange={setMenuOpened}>
        <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuGroup className="space-y-1">
            <DropdownMenuItem
              className="space-x-2"
              onClick={() => {
                setMenuOpened(() => false)
                setUpdateOpen(() => true)
              }}
            >
              <Edit size={16} /> <span>Rename</span>
            </DropdownMenuItem>
            <Separator />
            <DropdownMenuItem
              className="space-x-2"
              onClick={() => {
                setMenuOpened(() => false)
                setDeleteOpen(() => true)
              }}
            >
              <Trash size={16} className="text-destructive" /> <span>Delete</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <ProjectDeleteDialog
        project={project}
        open={deleteOpen}
        onOpenChange={setDeleteOpen}
        onSubmit={(e) => {
          setDeleteOpen(false)
          onDelete(e)
        }}
      />

      <ProjectShareDialog open={shareOpen} onOpenChange={setShareOpen} />

      <ProjectUpdateDialog
        open={updateOpen}
        project={project}
        onOpenChange={setUpdateOpen}
        onSubmit={(e) => {
          setUpdateOpen(false)
          onUpdate(e)
        }}
      />

      <ProjectPinDialog
        open={pinOpen}
        project={project}
        isPinned={isPinned}
        onOpenChange={setPinOpen}
        onSubmit={(e) => {
          setPinOpen(false)
          onPin(e)
        }}
      />
    </>
  )
}
