import { ActiveSelection, DocumentSource } from '@/types'
import { MoreVertical } from 'lucide-react'
import { isEmpty, last, map } from 'ramda'
import { Fragment } from 'react'
import { Button } from './ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from './ui/dropdown-menu'
import { createRootSourceSelection } from '@/lib/domain/selection'

type SourceBreadcrumbProps = {
  breadcrumbList: ActiveSelection[]
  source: DocumentSource
  onChange(item: ActiveSelection): void
}

export function SourceBreadcrumb({ breadcrumbList, source, onChange }: SourceBreadcrumbProps) {
  if (isEmpty(breadcrumbList)) {
    return <div className="flex items-center gap-1 h-10">/</div>
  }

  function handleSelectionClick(item: ActiveSelection) {
    onChange(item)
  }

  const lastItem = last(breadcrumbList)

  return (
    <div className="flex items-center gap-1 h-10 overflow-x-auto">
      {breadcrumbList.length <= 1 ? (
        map((item) => {
          return (
            <Fragment key={item.path.join('/')}>
              <Button
                variant="ghost"
                className="text-md px-1"
                onClick={() => handleSelectionClick(createRootSourceSelection())}
              >
                {source.title} /
              </Button>
              <Button variant="ghost" className="text-md px-1" onClick={() => handleSelectionClick(item)}>
                {item.name}
              </Button>
            </Fragment>
          )
        }, breadcrumbList)
      ) : (
        <>
          <Button
            variant="ghost"
            className="text-md px-1"
            onClick={() => handleSelectionClick(createRootSourceSelection())}
          >
            {source.title} /
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="text-md px-1">
                <MoreVertical size={16} className="text-purple" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                {map(
                  (item) => (
                    <DropdownMenuItem
                      key={item.path.join('/')}
                      className="h-10"
                      onClick={() => handleSelectionClick(item)}
                    >
                      {[...item.path, item.name].join('/')}
                    </DropdownMenuItem>
                  ),
                  breadcrumbList.slice(0, breadcrumbList.length - 1).reverse()
                )}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
          <div>/</div>
          <Button variant="ghost" className="text-md px-1">
            {lastItem && lastItem.name}
          </Button>
        </>
      )}
    </div>
  )
}
