import { Menubar, MenubarMenu, MenubarTrigger } from '@/components/ui/menubar'
import { routePath } from '@/router/route-path'
import { Pencil2Icon } from '@radix-ui/react-icons'
import { CalendarClock, LayoutGrid } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Button } from './ui/button'

export function PageMenu() {
  return (
    <Menubar className="flex-col h-auto w-[40px] border-none bg-transparent">
      <MenubarMenu>
        <MenubarTrigger className="p-0 mb-[8px]">
          <Button variant="default" size="icon" asChild title="New Project">
            <Link to={routePath.createProject}>
              <Pencil2Icon className="h-4 w-4 text-white" />
            </Link>
          </Button>
        </MenubarTrigger>
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger className="p-0 mb-[8px]" style={{ marginLeft: 0 }}>
          <Button variant="outline" size="icon" asChild title="Projects">
            <Link to={routePath.projects}>
              <LayoutGrid className="h-4 w-4" />
            </Link>
          </Button>
        </MenubarTrigger>
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger className="p-0 mb-[8px]" style={{ marginLeft: 0 }}>
          <Button variant="outline" size="icon" asChild title="Scheduled Research">
            <Link to={routePath.scheduledResearchPlan}>
              <CalendarClock className="h-4 w-4" />
            </Link>
          </Button>
        </MenubarTrigger>
      </MenubarMenu>
    </Menubar>
  )
}
