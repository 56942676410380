import {
  UpdateSelectionByIdFetcherArg,
  UpdateSelectionFetcherArg,
  createFolderFetcher,
  createVaultTokenFetcher,
  deleteFileFetcher,
  deleteFolderFetcher,
  getSelectionByIdFetcher,
  updateSelectionByIdFetcher,
  updateSelectionFetcher
} from '@/api/fetcher'
import { AuthContext } from '@/components/auth-provider'
import { DocumentSourceItem } from '@/components/chat-document-source-item'
import { ChatDocuments } from '@/components/chat-documents'
import { ChatDocumentsSearchFormFunctionsContext } from '@/components/chat-documents-search-form-provider'
import { SourcesLayout } from '@/components/chat-source-layout'
import { ChatTabs } from '@/components/chat-tabs'
import { CreateFolderFormValue } from '@/components/create-folder-dialog'
import { PageLayout } from '@/components/page-layout'
import { SnowflakeForm } from '@/components/snowflake-form'
import { Button } from '@/components/ui/button'
import { Spinner } from '@/components/ui/spinner'
import { ProjectPageContext } from '@/contexts/project'
import { SourcesPageReadOnlyContext } from '@/contexts/sources-page-readonly'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { useEnv } from '@/hooks/use-env'
import { createRootSourceSelection, isPathSelected } from '@/lib/domain/selection'
import { cn } from '@/lib/utils'
import {
  ActiveSelection,
  ApiSource,
  ApiSourceFile,
  ApiSourceSelection,
  ApiSourceType,
  ChatFilesViewMode,
  DocumentSource,
  DocumentSourceSelection,
  DocumentSourceSelectionFile,
  DocumentSourceSelectionFolder,
  Maybe
} from '@/types'
import { useVault } from '@apideck/vault-react'
import { CheckedState } from '@radix-ui/react-checkbox'
import { ArrowLeft } from 'lucide-react'
import {
  adjust,
  append,
  concat,
  defaultTo,
  either,
  equals,
  findIndex,
  groupBy,
  head,
  isEmpty,
  map,
  not,
  prop,
  reject,
  startsWith,
  toPairs,
  uniq
} from 'ramda'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { match } from 'ts-pattern'

const ROOT_PATH = '/'

const EMPTY_SELECTION: Readonly<ApiSourceSelection> = { include: [], exclude: [] }

export function SourcesPage() {
  const readOnly = useContext(SourcesPageReadOnlyContext)
  const env = useEnv()
  const projectContext = useContext(ProjectPageContext)
  const projectId = projectContext?.project.id
  const searchFormFunctions = useContext(ChatDocumentsSearchFormFunctionsContext)
  const auth = useContext(AuthContext)
  const { open: openVault } = useVault()

  const [breadcrumbList, setBreadcrumbList] = useState<ActiveSelection[]>([])
  const [activeSourceId, setActiveSourceId] = useState<string>()
  const [activeFolder, setActiveFolder] = useState<ActiveSelection>()
  const [searchQuery, setSearchQuery] = useState<string>()

  const apiGetSourceList = useSWR<ApiSource[], Error>('/api/sources', null, {
    revalidateOnFocus: false,
    refreshInterval: 5000
  })
  const activeFolderPath = activeFolder ? `/${[...activeFolder.path, activeFolder.name].join('/')}` : undefined
  const fileListUrl = createFileUrl(activeSourceId, activeFolderPath)
  const apiGetFileList = useSWR<ApiSourceFile[]>(fileListUrl, null, {
    revalidateOnFocus: false
  })
  const activeSelectionUrl = createSelectionUrl(activeSourceId, projectId)
  useSWR<ApiSourceSelection>(activeSelectionUrl, null, {
    revalidateOnFocus: false
  })
  const apiCreateFolder = useSWRMutation(`createFolderKey`, createFolderFetcher)
  const apiDeleteFolder = useSWRMutation(`deleteFolderKey`, deleteFolderFetcher)
  const apiDeleteFile = useSWRMutation(`deleteFileKey`, deleteFileFetcher)

  const getKey = () => {
    if (!apiGetSourceList.data) {
      return null
    }
    const sourceIdList = map(prop('id'), apiGetSourceList.data)
    return JSON.stringify({
      key: 'getSelectionById',
      baseUrl: env.APP_API_BASE_URL,
      projectId,
      sourceIdList,
      authToken: auth?.session?.access_token
    })
  }

  const apiGetSelectionById = useSWR(getKey(), getSelectionByIdFetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false
  })

  const apiUpdateSelection = useSWRMutation(getKey(), updateSelectionFetcher, {
    rollbackOnError: true,
    revalidate: false
  })

  const apiUpdateSelectionById = useSWRMutation('updateSelectionById', updateSelectionByIdFetcher)
  const apiGetFileListSearch = useSWR<ApiSourceFile[]>(
    createFileListSearchUrl({
      sourceId: activeSourceId,
      searchQuery: searchQuery
    })
  )
  const apiGetVaultToken = useSWRMutation('/api/vault/token', createVaultTokenFetcher)

  const apiLoading =
    apiGetSourceList.isLoading ||
    apiGetFileList.isLoading ||
    apiCreateFolder.isMutating ||
    apiDeleteFolder.isMutating ||
    apiDeleteFile.isMutating ||
    apiUpdateSelection.isMutating ||
    apiGetSelectionById.isLoading ||
    apiUpdateSelectionById.isMutating ||
    apiGetFileListSearch.isLoading

  const activeSelection: Maybe<ApiSourceSelection> =
    activeSourceId && apiGetSelectionById.data ? apiGetSelectionById.data[activeSourceId] : null

  const includeList = activeSelection?.include ?? []
  const excludeList = activeSelection?.exclude ?? []

  const sourceList: DocumentSource[] = useMemo(
    () =>
      (apiGetSourceList.data ?? []).map((item) => {
        const selection = apiGetSelectionById.data ? apiGetSelectionById.data[item.id] : null
        return {
          id: item.id,
          title: item.name,
          count: defaultTo(0, item.meta_data?.file_count),
          type: item.type,
          selectionState: isPathSelected(ROOT_PATH, selection?.include, selection?.exclude),
          description: item.meta_data?.description,
          order: item.order,
          is_experimental: item.is_experimental,
        }
      }),
    [apiGetSourceList.data, apiGetSelectionById.data]
  )

  const activeSource: Maybe<DocumentSource> = sourceList.find((item) => item.id === activeSourceId)

  const sourceByType = groupBy((item) => item.type, sourceList)

  const isSearchActive = not(isEmpty(defaultTo('', searchQuery)))

  const viewMode: ChatFilesViewMode = isSearchActive ? 'search' : 'default'

  const fileListOriginal = match<ChatFilesViewMode, ApiSourceFile[]>(viewMode)
    .with('default', () => defaultTo([], apiGetFileList.data))
    .with('search', () => defaultTo([], apiGetFileListSearch.data))
    .exhaustive()

  const fileList: DocumentSourceSelection[] = fileListOriginal.map(mapApiSourceFileToSelection)

  const selectAll: CheckedState = match(viewMode)
    .with('default', () => {
      return activeFolderPath ? isPathSelected(activeFolderPath, includeList, excludeList) : false
    })
    .with('search', () => {
      if (fileList.every((item) => isPathSelected(item.path, includeList, excludeList) === false)) {
        return false
      }
      if (fileList.every((item) => isPathSelected(item.path, includeList, excludeList) === true)) {
        return true
      }
      return 'indeterminate'
    })
    .exhaustive()

  useEffect(() => {
    if (!readOnly) {
      apiGetSelectionById.mutate()
    }
  }, [projectId, apiGetSourceList.data, readOnly])

  // autoselect first source from the list on first load for screens larger md
  const { isMd } = useBreakpoint()
  const [sourceAutoselected, setSourceAutoselected] = useState(false)
  useEffect(() => {
    if (sourceAutoselected || !isMd) {
      return
    }
    const firstSource = head(sourceList)
    if (!firstSource) {
      return
    }
    setSourceAutoselected(() => true)
    setActiveSourceId(() => firstSource.id)
    setActiveFolder(() => createRootSourceSelection())
  }, [sourceList, sourceAutoselected])

  async function handleDocumentUploaded() {
    apiGetSourceList.mutate()
    apiGetFileList.mutate()
  }

  function handleSourceClick(item: DocumentSource) {
    setActiveSourceId(() => item.id)
    setActiveFolder(() => createRootSourceSelection())
    setBreadcrumbList(() => [])

    searchFormFunctions.setValue('')
    setSearchQuery(() => '')
    apiGetFileListSearch.mutate([])
  }

  async function handleSourceCheckedChange(value: CheckedState, item: DocumentSource) {
    const selection = apiGetSelectionById.data ? apiGetSelectionById.data[item.id] : null
    if (!selection) {
      return
    }

    const include = value
      ? append(ROOT_PATH, selection.include)
      : reject(either(equals(ROOT_PATH), startsWith(ROOT_PATH)), selection.include)

    const exclude = value
      ? reject(either(equals(ROOT_PATH), startsWith(ROOT_PATH)), selection.exclude)
      : append(ROOT_PATH, selection.exclude)

    await apiUpdateSelection.trigger(
      {
        baseUrl: env.APP_API_BASE_URL,
        sourceId: item.id,
        projectId,
        include,
        exclude
      },
      {
        optimisticData(currentData = EMPTY_SELECTION) {
          return { ...currentData, [item.id]: { include, exclude } }
        },
        populateCache(result, currentData = EMPTY_SELECTION) {
          return { ...currentData, [item.id]: result }
        }
      }
    )
  }

  async function handleSourceOnlyClick(source: DocumentSource) {
    const payload: UpdateSelectionByIdFetcherArg = {
      baseUrl: env.APP_API_BASE_URL,
      projectId,
      sourceIdSelectionPairList: map<DocumentSource, [string, ApiSourceSelection]>((item) => {
        if (item.id === source.id) {
          return [item.id, { include: ['/'], exclude: [] }]
        }
        return [item.id, { include: [], exclude: ['/'] }]
      }, sourceList)
    }
    await apiUpdateSelectionById.trigger(payload)
    apiGetSelectionById.mutate()
  }

  async function handleOnlyClick(item: DocumentSourceSelection) {
    if (!activeFolderPath) {
      return
    }
    const selectionPathList: string[] = map((item) => item.path, fileList)
    const selectionPathListWithoutItem: string[] = reject(equals(item.path), selectionPathList)
    const payload: UpdateSelectionFetcherArg = {
      baseUrl: env.APP_API_BASE_URL,
      sourceId: activeSourceId,
      projectId,
      include: uniq(append(item.path, includeList)),
      exclude: uniq(concat(reject(equals(item.path), excludeList), selectionPathListWithoutItem))
    }
    await apiUpdateSelection.trigger(payload, {
      optimisticData(currentData = EMPTY_SELECTION) {
        return { ...currentData, [item.sourceId]: { include: payload.include, exclude: payload.exclude } }
      },
      populateCache(result, currentData = EMPTY_SELECTION) {
        return { ...currentData, [item.sourceId]: result }
      }
    })
  }

  async function handleAddCouldStorage() {
    const result = await apiGetVaultToken.trigger({ baseUrl: env.APP_API_BASE_URL })
    openVault({
      token: result.token,
      onConnectionChange() {
        apiGetSourceList.mutate()
      },
      onConnectionDelete() {
        apiGetSourceList.mutate()
      }
    })
  }

  function mapApiSourceFileToSelection(item: ApiSourceFile): DocumentSourceSelection {
    switch (item.type) {
      case 'file':
      case 'url':
        return {
          id: item.id,
          sourceId: item.source_id,
          type: 'file',
          path: item.path,
          parentPathList: item.parent_folders,
          title: item.name,
          createdAt: item.created_at,
          selected: isPathSelected(item.path, includeList, excludeList)
        }
      case 'folder':
        return {
          id: item.id,
          sourceId: item.source_id,
          type: 'folder',
          path: item.path,
          parentPathList: item.parent_folders,
          title: item.name,
          createdAt: item.created_at,
          selected: isPathSelected(item.path, includeList, excludeList),
          count: defaultTo(0, item.meta_data?.file_count),
          children: []
        }
    }
  }

  async function handleCheckedChange(item: DocumentSourceSelection, value: boolean) {
    const include = value ? append(item.path, includeList) : reject(equals(item.path), includeList)
    const exclude = value
      ? reject(either(equals(item.path), equals(ROOT_PATH)), excludeList)
      : append(item.path, excludeList)

    await apiUpdateSelection.trigger(
      {
        baseUrl: env.APP_API_BASE_URL,
        sourceId: activeSourceId,
        projectId,
        include,
        exclude
      },
      {
        optimisticData: { ...apiGetSelectionById.data, [item.sourceId]: { include, exclude } },
        populateCache(result, currentData) {
          return { ...currentData, [item.sourceId]: result }
        }
      }
    )
  }

  function handleSelectionChange(value: DocumentSourceSelectionFolder) {
    const nextActiveSelection: ActiveSelection = { path: value.parentPathList, name: value.title }
    setActiveFolder(nextActiveSelection)
    setBreadcrumbList(activeSelectionToBreadcrumbList(nextActiveSelection))

    // clear search
    setSearchQuery(() => '')
    apiGetFileListSearch.mutate([])
  }

  function activeSelectionToBreadcrumbList(value: ActiveSelection): ActiveSelection[] {
    const list: ActiveSelection[] = []
    value.path.forEach((item, index) => {
      const prev = list[index - 1]
      if (prev) {
        list.push({ path: [...prev.path, prev.name], name: item })
      } else {
        list.push({ path: [], name: item })
      }
    })
    list.push(value)
    return list
  }

  async function handleSelectAllChange(value: boolean) {
    switch (viewMode) {
      case 'default':
        selectAllActiveFolder(value)
        break
      case 'search':
        selectAllSearch(value)
        break
    }
  }

  async function selectAllActiveFolder(value: boolean) {
    const activePath = activeFolderPath
    if (!activePath || !activeSourceId) {
      throw new Error('handleSelectAllChange: no active path available')
    }
    const payload = {
      baseUrl: env.APP_API_BASE_URL,
      sourceId: activeSourceId,
      projectId,
      include: value
        ? append(activePath, includeList)
        : reject(either(equals(activePath), startsWith(activePath)), includeList),
      exclude: value
        ? reject(either(equals(activePath), startsWith(activePath)), excludeList)
        : append(activePath, excludeList)
    }
    await apiUpdateSelection.trigger(payload, {
      optimisticData(currentData = EMPTY_SELECTION) {
        return { ...currentData, [activeSourceId]: { include: payload.include, exclude: payload.exclude } }
      },
      populateCache(result, currentData = EMPTY_SELECTION) {
        return { ...currentData, [activeSourceId]: result }
      }
    })
  }

  async function selectAllSearch(value: boolean) {
    if (!activeSourceId) {
      return
    }
    const payload = {
      baseUrl: env.APP_API_BASE_URL,
      sourceId: activeSourceId,
      projectId,
      include: value ? [...includeList, ...map((item) => item.path, fileList)] : includeList,
      exclude: value
        ? reject((item) => fileList.some((file) => file.path === item), excludeList)
        : [...excludeList, ...map((item) => item.path, fileList)]
    }
    await apiUpdateSelection.trigger(payload, {
      optimisticData(currentData = EMPTY_SELECTION) {
        return { ...currentData, [activeSourceId]: { include: payload.include, exclude: payload.exclude } }
      },
      populateCache(result, currentData = EMPTY_SELECTION) {
        return { ...currentData, [activeSourceId]: result }
      }
    })
  }

  async function handleCreateFolder(value: CreateFolderFormValue) {
    await apiCreateFolder.trigger({
      sourceId: activeSourceId,
      name: value.name,
      parent: activeFolderPath,
      baseUrl: env.APP_API_BASE_URL
    })
    apiGetSourceList.mutate()
    apiGetFileList.mutate()
  }

  async function handleDeleteFolder(value: DocumentSourceSelectionFolder) {
    await apiDeleteFolder.trigger({
      sourceId: activeSourceId,
      path: value.path,
      baseUrl: env.APP_API_BASE_URL
    })

    const index = findIndex((item) => item.id === value.id, apiGetSourceList.data ?? [])
    const optimisticSourceList = adjust(
      index,
      (item) => setSourceCount(item, defaultTo(0, item.meta_data?.file_count) - value.count),
      apiGetSourceList.data ?? []
    )
    apiGetSourceList.mutate(optimisticSourceList)

    const optimisticFileList = (apiGetFileList.data ?? []).filter((item) => item.id !== value.id)
    apiGetFileList.mutate(optimisticFileList)

    const optimisticFileListSearch = (apiGetFileListSearch.data ?? []).filter((item) => item.id !== value.id)
    apiGetFileListSearch.mutate(optimisticFileListSearch)
  }

  async function handleDeleteFile(value: DocumentSourceSelectionFile) {
    await apiDeleteFile.trigger({
      sourceId: activeSourceId,
      path: value.path,
      baseUrl: env.APP_API_BASE_URL
    })

    const index = findIndex((item) => item.id === value.id, apiGetSourceList.data ?? [])
    const optimisticSourceList = adjust(
      index,
      (item) => setSourceCount(item, defaultTo(0, item.meta_data?.file_count) - 1),
      apiGetSourceList.data ?? []
    )
    apiGetSourceList.mutate(optimisticSourceList)

    const optimisticFileList = (apiGetFileList.data ?? []).filter((item) => item.id !== value.id)
    apiGetFileList.mutate(optimisticFileList)

    const optimisticFileListSearch = (apiGetFileListSearch.data ?? []).filter((item) => item.id !== value.id)
    apiGetFileListSearch.mutate(optimisticFileListSearch)
  }

  function setSourceCount(item: ApiSource, count: number): ApiSource {
    return {
      ...item,
      meta_data: {
        ...(item.meta_data ?? {}),
        file_count: count
      }
    }
  }

  function handleBreadcrumbChange(selection: ActiveSelection) {
    setActiveFolder(selection)
    setBreadcrumbList(activeSelectionToBreadcrumbList(selection))
  }

  function handleSearchSubmit(value: string) {
    setSearchQuery(value)
  }

  function handleBackClick() {
    setActiveSourceId(() => undefined)
  }

  return (
    <PageLayout>
      <div className="flex flex-col h-full">
        <div className="flex items-center gap-2 mb-2">
          {readOnly ? (
            <h3 className="h-[40px] flex items-center justify-between">
              <span className="font-bold">Sources</span>
            </h3>
          ) : (
            <>
              <ChatTabs />
              {apiLoading && <Spinner />}
            </>
          )}
        </div>

        <SourcesLayout className={cn('flex-grow', activeSourceId && '[&>.SourcesLayoutLeft]:max-md:hidden')}>
          <SourcesLayout.Left>
            <div className="bg-white rounded flex-grow">
              {map((pair) => {
                if (!pair) {
                  return null
                }
                const [key, itemList = []] = pair

                return (
                  <Fragment key={key}>
                    <div className="h-10 text-purple text-sm py-3 px-5 border-b border-t">{keyHeaderMap[key]}</div>

                    {map(
                      (item) => (
                        <DocumentSourceItem
                          key={item.id}
                          readOnly={readOnly}
                          value={item}
                          active={activeSourceId === item.id}
                          onCheckedChange={(value) => handleSourceCheckedChange(value, item)}
                          onClick={handleSourceClick}
                          onOnlyClick={() => handleSourceOnlyClick(item)}
                        />
                      ),
                      itemList
                    )}
                  </Fragment>
                )
              }, toPairs(sourceByType))}

              <div className="h-4" />

              <Button
                variant="secondary"
                className={cn('flex items-center h-10 py-3 px-5 font-normal m-auto')}
                onClick={handleAddCouldStorage}
              >
                Add Cloud Storage
              </Button>
            </div>
          </SourcesLayout.Left>
          <SourcesLayout.Right>
            {activeSource &&
              match(activeSource.type)
                .with('drive', 'uploads', () => {
                  if (activeFolder && activeFolderPath && activeSourceId) {
                    return (
                      <ChatDocuments
                        selection={activeFolder}
                        selectionList={fileList}
                        breadcrumbList={breadcrumbList}
                        selectAll={selectAll}
                        viewMode={viewMode}
                        source={activeSource}
                        onSelectionChange={handleSelectionChange}
                        onSelectionCheckedChange={handleCheckedChange}
                        onSelectAllChange={handleSelectAllChange}
                        onCreateFolder={handleCreateFolder}
                        onDeleteFolder={handleDeleteFolder}
                        onDeleteFile={handleDeleteFile}
                        onBreadcrumbChange={handleBreadcrumbChange}
                        onUploaded={handleDocumentUploaded}
                        onOnlyClick={handleOnlyClick}
                        onSearchSubmit={handleSearchSubmit}
                        onBackClick={handleBackClick}
                      />
                    )
                  }
                  return null
                })
                .with('sql', () => {
                  return (
                    <div className="h-full">
                      <Button variant="secondary" className="md:hidden w-fit mb-4" onClick={handleBackClick}>
                        <ArrowLeft size={16} className="mr-2" />
                        Back
                      </Button>
                      <SnowflakeForm />
                    </div>
                  )
                })
                .with('public', () => {
                  return (
                    <div className="bg-white rounded flex flex-col h-full px-4 md:px-20 pt-10">
                      <Button variant="secondary" className="md:hidden w-fit mb-4" onClick={handleBackClick}>
                        <ArrowLeft size={16} className="mr-2" />
                        Back
                      </Button>
                      <div className="text-xl font-bold text-center">{activeSource.title}</div>
                      <div className="font-normal text-purple text-center pb-5">{activeSource.description}</div>
                    </div>
                  )
                })
                .exhaustive()}
          </SourcesLayout.Right>
        </SourcesLayout>
      </div>
    </PageLayout>
  )
}

function createFileUrl(sourceId?: string, folderPath?: string): Maybe<string> {
  if (!sourceId || !folderPath) {
    return null
  }
  const searchParams = new URLSearchParams()
  searchParams.append('folder_path', folderPath)
  return `/api/sources/${sourceId}/files?${searchParams}`
}

function createSelectionUrl(sourceId?: string, projectId?: string): Maybe<string> {
  if (!sourceId || !projectId) {
    return null
  }
  const searchParams = new URLSearchParams()
  searchParams.append('project_id', projectId)
  return `/api/sources/${sourceId}/selections?${searchParams}`
}

const keyHeaderMap: Record<ApiSourceType, string> = {
  uploads: 'File Explorer',
  public: 'Public Data',
  drive: 'Cloud Drives',
  sql: 'Cloud Databases'
}

function createFileListSearchUrl(options: { sourceId?: string; searchQuery?: string }) {
  if (!options.searchQuery || !options.sourceId) {
    return
  }
  const search = new URLSearchParams()
  search.append('query', options.searchQuery)
  const url = `/api/sources/${options.sourceId}/files/search?${search.toString()}`
  return url
}
