import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Option } from '@/types'
import {
  JsonFormsCellRendererRegistryEntry,
  JsonFormsRendererRegistryEntry,
  rankWith,
  scopeEndsWith,
  UISchemaElement,
  uiTypeIs
} from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsControlProps, withJsonFormsLayoutProps } from '@jsonforms/react'
import { defaultTo } from 'ramda'
import { ComponentType } from 'react'

const nameControlTester = rankWith(
  3, //increase rank as needed
  scopeEndsWith('name')
)

interface InputControlProps {
  data: any
  handleChange(path: string, value: any): void
  path: string
}

const InputControl = ({ data, handleChange, path }: InputControlProps) => (
  <Input value={data} onChange={(e) => handleChange(path, e.target.value)} />
)

const InputRenderer = withJsonFormsControlProps(InputControl)

const stateControlTester = rankWith(
  3, //increase rank as needed
  scopeEndsWith('state')
)

interface SelectControlProps {
  data: any
  handleChange(path: string, value: any): void
  path: string
  uischema: any
}

const SelectControl = ({ data, handleChange, path, uischema }: SelectControlProps) => {
  return (
    <div className="p-1">
      <div>{uischema.label}</div>
      <Select value={data} onValueChange={(value) => handleChange(path, value)}>
        <SelectTrigger>
          <SelectValue placeholder={uischema.placeholder || 'Select'} />
        </SelectTrigger>
        <SelectContent>
          {defaultTo([], uischema.options as Option[]).map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

const StateRenderer = withJsonFormsControlProps(SelectControl)

const VerticalLayout: ComponentType<any> = ({
  schema,
  uischema,
  path,
  renderers = [] as JsonFormsRendererRegistryEntry[],
  cells = [] as JsonFormsCellRendererRegistryEntry[],
  enabled,
  visible
}) => {
  return (
    <div className="flex flex-col gap-2">
      {visible &&
        uischema.elements.map((child: UISchemaElement, index: number) => (
          <JsonFormsDispatch
            schema={schema}
            uischema={child}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
            key={index}
          />
        ))}
    </div>
  )
}

const AccordionLayout: ComponentType<any> = ({
  schema,
  uischema,
  path,
  renderers = [] as JsonFormsRendererRegistryEntry[],
  cells = [] as JsonFormsCellRendererRegistryEntry[],
  enabled,
  visible
}) => {
  return (
    <Accordion type="multiple">
      <AccordionItem value="1">
        <AccordionTrigger>{uischema.label}</AccordionTrigger>
        <AccordionContent>
          {visible &&
            uischema.elements.map((child: UISchemaElement, index: number) => (
              <JsonFormsDispatch
                schema={schema}
                uischema={child}
                path={path}
                enabled={enabled}
                renderers={renderers}
                cells={cells}
                key={index}
              />
            ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

const verticalLayoutTester = rankWith(1000, uiTypeIs('VerticalLayout'))

const VerticalLayoutRenderer = withJsonFormsLayoutProps(VerticalLayout)

const accordionLayoutTester = rankWith(1000, uiTypeIs('AccordionLayout'))

const AccordionLayoutRenderer = withJsonFormsLayoutProps(AccordionLayout)

const countyControlTester = rankWith(
  3, //increase rank as needed
  scopeEndsWith('county')
)

const CountyRenderer = withJsonFormsControlProps(SelectControl)

const cbgPopulationControlTester = rankWith(
  3, //increase rank as needed
  scopeEndsWith('cbg_population')
)

const CBGPopulationRenderer = withJsonFormsControlProps(SelectControl)

const medianHouseholdIncomeControlTester = rankWith(
  3, //increase rank as needed
  scopeEndsWith('median_household_income')
)

const MedianHouseholdIncomeRenderer = withJsonFormsControlProps(SelectControl)

export const renderers = [
  { tester: verticalLayoutTester, renderer: VerticalLayoutRenderer },
  { tester: accordionLayoutTester, renderer: AccordionLayoutRenderer },

  { tester: nameControlTester, renderer: InputRenderer },
  { tester: stateControlTester, renderer: StateRenderer },
  { tester: countyControlTester, renderer: CountyRenderer },
  { tester: cbgPopulationControlTester, renderer: CBGPopulationRenderer },
  { tester: medianHouseholdIncomeControlTester, renderer: MedianHouseholdIncomeRenderer }
]
