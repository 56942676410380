import { createScheduledResearchPlanFetcher, startResearchFetcher, stopResearchFetcher } from '@/api/fetcher'
import Logo from '@/assets/logo.svg'
import { useEnv } from '@/hooks/use-env'
import { formatDateAgo, formatDatePass } from '@/lib/date'
import { toHtmlId } from '@/lib/dom'
import { noop } from '@/lib/function'
import { setToStorage } from '@/lib/storage'
import { cn } from '@/lib/utils'
import { routePath } from '@/router/route-path'
import { ApiResearchMessage, Maybe, MessageView, ResearchPlan } from '@/types'
import { captureException } from '@sentry/react'
import { RealtimeChannel } from '@supabase/supabase-js'
import { parseISO } from 'date-fns'
import { AlertCircle, ArrowRight, CalendarClock, RefreshCw } from 'lucide-react'
import { always, append, isNil, map } from 'ramda'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation from 'swr/mutation'
import { match } from 'ts-pattern'
import { AuthContext } from './auth-provider'
import { Markdown } from './markdown'
import { ThreeDotsSpinner } from './three-dots-spinner'
import { Alert, AlertDescription, AlertTitle } from './ui/alert'
import { Avatar, AvatarImage } from './ui/avatar'
import { Button } from './ui/button'
import { Separator } from './ui/separator'
import { getResearchPlanFetcher } from '@/api/fetcher'
import { RecurrenceDialog } from './recurrence-dialog'

type Props = {
  message: MessageView
  onStartResearch?(item: MessageView): void
  onStopResearch?(item: MessageView): void
  onReloadResearch?(prompt: string): void
  onRetryResearch?(item: MessageView): void
  onReportViewClick?(item: MessageView): void
  onMessageReceive?(): void
}
export function ChatBotMessage({
  message,
  onStartResearch = noop,
  onStopResearch = noop,
  onReloadResearch = noop,
  onRetryResearch = noop,
  onReportViewClick = noop,
  onMessageReceive = noop
}: Props) {
  const env = useEnv()
  const [messageList, setMessageList] = useState<ApiResearchMessage[]>([])
  const planUrl = message.researchPlanId ? `/api/research/plans/${message.researchPlanId}` : null
  const apiPlan = useSWRImmutable<ResearchPlan>(planUrl)
  const startPlan = useSWRMutation('startPlan', startResearchFetcher)
  const stopPlan = useSWRMutation('stopPlan', stopResearchFetcher)
  const plan = apiPlan.data
  const dateView = formatDateAgo(message.date, new Date())
  const client = useContext(AuthContext)?.client ?? null
  const startResearchButtonRef = useRef<HTMLButtonElement>(null)

  const apiGetResearchPlan = useSWRMutation('getResearchPlan', getResearchPlanFetcher, { revalidate: false })
  const apiCreateScheduledResearchPlan = useSWRMutation(
    `${env.APP_API_BASE_URL}/api/scheduled_research_plans`,
    createScheduledResearchPlanFetcher
  )
  const elapseTimeString: Maybe<string> = getElapseTime()

  useEffect(() => {
    if (!plan || !message.researchPlanId || !client) {
      return
    }

    // Clear the message list
    setMessageList([])

    // Load all previous research logs for the given research_plan_id
    const fetchPreviousLogs = async () => {
      try {
        const { data, error } = await client
          .from('research_logs')
          .select('*')
          .eq('research_plan_id', message.researchPlanId)
          .order('created_at', { ascending: true })

        if (error) {
          throw error
        }

        // Append the previous research logs to the message list
        setMessageList((prev) => {
          onMessageReceive()
          return [...data, ...prev]
        })
      } catch (error) {
        console.error('Error fetching previous research logs:', error)
      }
    }

    fetchPreviousLogs()

    if (plan?.status !== 'IN_PROGRESS') {
      return
    }

    let researchLogsSubscription: RealtimeChannel | null = null
    let researchPlanSubscription: RealtimeChannel | null = null

    try {
      // Subscribe to new rows in the research_logs table for the given research_plan_id
      researchLogsSubscription = client
        .channel('research_logs_insert')
        .on(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'research_logs',
            filter: `research_plan_id=eq.${message.researchPlanId}`
          },
          (payload: any) => {
            const researchLog = payload.new
            handleResearchMessage(researchLog)
          }
        )
        .subscribe()

      // Subscribe to changes in the research_plans.status for the given research_plan_id
      researchPlanSubscription = client
        .channel('research_plans_update')
        .on(
          'postgres_changes',
          {
            event: 'UPDATE',
            schema: 'public',
            table: 'research_plans',
            filter: `id=eq.${message.researchPlanId}`
          },
          (payload: any) => {
            const researchPlan = payload.new
            apiPlan.mutate(researchPlan, { revalidate: false })

            if (researchPlan.status !== 'IN_PROGRESS') {
              // If the research plan status is no longer IN_PROGRESS, unsubscribe from both events
              client.removeChannel(researchLogsSubscription!)
              client.removeChannel(researchPlanSubscription!)
            }
          }
        )
        .subscribe()
    } catch (error) {
      console.error('Error subscribing to events:', error)
    }

    // Clean up the subscriptions when the component unmounts or the plan status changes
    return () => {
      try {
        if (researchLogsSubscription) {
          client.removeChannel(researchLogsSubscription)
        }
        if (researchPlanSubscription) {
          client.removeChannel(researchPlanSubscription)
        }
      } catch (error) {
        console.error('Error unsubscribing from events:', error)
        // Handle the error, e.g., log the error for debugging purposes
      }
    }
  }, [plan?.status, client])

  async function handleStartResearchClick() {
    if (!message.researchPlanId || !startResearchButtonRef.current) {
      return
    }
    startResearchButtonRef.current.disabled = true
    onStartResearch(message)
    await startPlan.trigger({
      baseUrl: env.APP_API_BASE_URL,
      planId: message.researchPlanId
    })

    if (plan?.project_id) {
      apiPlan.mutate({ ...plan, status: 'IN_PROGRESS' }, { revalidate: false })
    }
  }

  async function handleStopResearchClick() {
    if (!message.researchPlanId || !plan) {
      return
    }
    await apiPlan.mutate({ ...plan, status: 'CANCELLED' }, { revalidate: false })
    await stopPlan.trigger({
      baseUrl: env.APP_API_BASE_URL,
      planId: message.researchPlanId
    })
    await apiPlan.mutate()
    onStopResearch(message)
  }

  function handleResearchMessage(researchLog: ApiResearchMessage) {
    onMessageReceive()
    setMessageList((prev) => {
      return append(researchLog, prev)
    })
  }

  function handleReportView() {
    onReportViewClick(message)
  }

  function handleModifySourcesClick() {
    if (message.id) {
      setToStorage<string>('scrollToResearchPlan', message.id)
    }
  }

  async function handleReloadResearch(item: MessageView) {
    if (!item.researchPlanId) {
      return
    }

    try {
      const researchPlan = await apiGetResearchPlan.trigger({
        baseUrl: env.APP_API_BASE_URL,
        researchPlanId: item.researchPlanId
      })
      if (researchPlan.user_message) {
        onReloadResearch(researchPlan.user_message)
      }
    } catch (error) {
      // Capture exception in case the research plan does not exist, or some other error occurs
      captureException(error)
    }
  }

  function getElapseTime(): Maybe<string> {
    try {
      if (isNil(plan?.started_at) || isNil(plan?.completed_at)) {
        return null
      }
      const startedAtDate = parseISO(plan.started_at)
      const completedAtDate = parseISO(plan.completed_at)
      return formatDatePass(startedAtDate, completedAtDate)
    } catch (e) {
      captureException(e)
      return null
    }
  }

  async function handleRecurrenceDialogSave(value: any) {
    if (!message.researchPlanId) {
      return
    }
    try {
      await apiCreateScheduledResearchPlan.trigger({
        researchPlanId: message.researchPlanId,
        schedule: value.cronExpression[0],
        timezone: value.timezone
      })
    } catch (e) {
      captureException(e)
    }
  }

  const researchMessageTitle: string = match(message.type)
    .with('assistant', 'research_assistant', () =>
      match(message.status)
        .with('ERROR', always('Octagon'))
        .with('PENDING', always('Octagon - Generating Research Plan...'))
        .with('SUCCESS', () =>
          match([plan?.status])
            .when(([p]) => p === 'IN_PROGRESS', always('Octagon - Starting Research...'))
            .when(([p]) => p === 'COMPLETED', always('Octagon - Research Completed'))
            .when(([p]) => p === 'CANCELLED', always('Octagon - Research Cancelled'))
            .otherwise(() => 'Octagon - Research Plan Generated')
        )
        .exhaustive()
    )
    .with('user', 'user_input', always('')) // not supported in this component
    .exhaustive()

  if (message.status === 'ERROR') {
    return (
      <ContentContainer dateText={dateView} title="Octagon">
        {message.content && (
          <div className="text-sm bg-white mb-2 p-4 text-black">
            <Markdown>{message.content}</Markdown>
          </div>
        )}
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>There was an error while generating the research plan. Please try again.</AlertDescription>
        </Alert>
      </ContentContainer>
    )
  }

  if (message.loading) {
    return <ChatBotMessageLoading date={message.date}></ChatBotMessageLoading>
  }

  if (!message.researchPlanId) {
    return (
      <ContentContainer
        dateText={dateView}
        title={message.type === 'research_assistant' ? researchMessageTitle : 'Octagon'}
      >
        <div className="text-sm bg-white mb-2 p-4 text-black">
          <Markdown>{message.content}</Markdown>
        </div>
      </ContentContainer>
    )
  }

  if (apiPlan.isLoading) {
    return (
      <ContentContainer dateText={dateView} title={researchMessageTitle}>
        <div className="text-sm bg-white mb-2 p-4 text-black">
          <Markdown>{message.content}</Markdown>
        </div>
        <div className="flex justify-center">
          <ThreeDotsSpinner />
        </div>
      </ContentContainer>
    )
  }

  if (apiPlan.error) {
    return (
      <ContentContainer dateText={dateView} title="Octagon">
        <div className="text-sm bg-white mb-2 p-4 text-black">
          <Markdown>{message.content}</Markdown>
        </div>
        <Alert variant="destructive" className="mb-2">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>There was an error while loading research plan.</AlertDescription>
        </Alert>
      </ContentContainer>
    )
  }

  return (
    <ContentContainer dateText={dateView} title={researchMessageTitle} id={message.id}>
      <div className="text-sm bg-white mb-1 p-4 text-black" style={{ whiteSpace: 'pre-wrap' }}>
        <Markdown>{message.content}</Markdown>
      </div>
      <div className="mb-2 border-l-2 ml-6 pl-2 border-secondary">
        {map((item) => {
          return (
            <div
              key={item.id}
              className="text-sm text-secondary-foreground mb-2"
              style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
            >
              <Markdown>{item.content}</Markdown>
            </div>
          )
        }, messageList)}
      </div>
      {plan &&
        match(plan.status)
          .with('CANCELLED', always(<p className="mb-2 text-center text-sm">Research plan cancelled</p>))
          .with(
            'COMPLETED',
            always(
              <div className="mb-2">
                <p className="text-center text-sm">
                  Research plan completed successfully.{' '}
                  <Button variant="ghost" onClick={handleReportView}>
                    View <ArrowRight size={16} />
                  </Button>
                </p>
                {!isNil(elapseTimeString) && (
                  <>
                    <Separator className="mb-2 mt-2" />
                    <p className="text-center text-sm">Elapsed Time: {getElapseTime()}</p>
                  </>
                )}
              </div>
            )
          )

          .with(
            'FAILED',
            always(
              <div className="text-center">
                <p className="mb-2 text-sm">Research plan failed.</p>
                <Button className="mb-2" variant="secondary" onClick={() => onRetryResearch(message)}>
                  Retry
                </Button>
              </div>
            )
          )
          .with(
            'PENDING',
            always(
              <>
                <div className="flex flex-col lg:flex-row justify-center gap-2">
                  <Button variant="secondary" asChild onClick={handleModifySourcesClick}>
                    <Link
                      to={{
                        pathname: `${routePath.project}/${message.projectId}${routePath.sources}`
                      }}
                    >
                      Modify Sources
                    </Link>
                  </Button>

                  <Button variant="default" onClick={handleStartResearchClick} ref={startResearchButtonRef}>
                    Start Research
                  </Button>

                  <RecurrenceDialog onSubmit={handleRecurrenceDialogSave}>
                    <Button variant="secondary" title="Schedule Research">
                      <CalendarClock className="h-4 w-4" />
                    </Button>
                  </RecurrenceDialog>
                </div>
                <div className="flex justify-between">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleReloadResearch(message)}
                    disabled={apiGetResearchPlan.isMutating}
                  >
                    <RefreshCw size={16} className={cn(apiGetResearchPlan.isMutating && 'animate-spin')} />
                  </Button>
                </div>
              </>
            )
          )
          .with(
            'IN_PROGRESS',
            always(
              <div className="flex justify-center flex-col items-center">
                <ThreeDotsSpinner className="mb-2" />
                <Button variant="secondary" onClick={handleStopResearchClick}>
                  Cancel
                </Button>
                <p className="mb-2 text-center text-sm text-secondary-foreground">
                  <br />
                  You can leave this running; we'll send you an email once the report is ready!
                </p>
              </div>
            )
          )
          .exhaustive()}
    </ContentContainer>
  )
}

type ChatBotMessageLoadingProps = {
  date: Date
}

export function ChatBotMessageLoading({ date }: ChatBotMessageLoadingProps) {
  const dateView =
    formatDateAgo(date, new Date()) === 'less than a minute ago' ? 'just now' : formatDateAgo(date, new Date())
  return (
    <ContentContainer dateText={dateView} title="Octagon - Generating Research Plan...">
      <p className="flex items-center text-sm mb-2 p-4 text-primary">
        <ThreeDotsSpinner className="mr-1 h-8 w-8" />
        <span className="text-primary">Generating...</span>
      </p>
    </ContentContainer>
  )
}

type ContentContainerProps = {
  children: React.ReactNode
  dateText: string
  title: string
  id?: string
}

function ContentContainer({ children, dateText, title, id }: ContentContainerProps) {
  return (
    <div className="mb-6 border table-row rounded p-2" data-testid="message-assistant">
      <div className="table-cell">
        {/* anchor for autoscroll when returning from sources page */}
        {id && <div id={toHtmlId(id)} className="size-0" />}
        <div className="flex flex-nowrap items-center justify-start gap-2 mb-2">
          <Avatar className="w-5 h-5">
            <AvatarImage src={Logo} alt="Octagon" />
          </Avatar>
          <strong className="text-xs">{title}</strong>
        </div>
        {children}
        <p className="text-xs text-slate-500 text-right">{dateText}</p>
      </div>
    </div>
  )
}
