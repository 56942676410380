import { useBreakpoint } from '@/hooks/use-breakpoint'
import { formatDateAgo } from '@/lib/date'
import { mapColumnSortToProjectSort, mapProjectSortToColumnSort } from '@/lib/domain/project'
import { routePath } from '@/router/route-path'
import { Maybe, Project, ProjectListSorting } from '@/types'
import { DotsVerticalIcon } from '@radix-ui/react-icons'
import { ColumnDef, ColumnSort, RowSelectionState, Updater } from '@tanstack/react-table'
import { Trash } from 'lucide-react'
import { chain, complement, F, filter, find, isEmpty, isNil, map, toPairs } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { DataTable } from './data-table'
import { SortableTableHeader } from './data-table-header-sortable'
import { ProjectMenu } from './project-menu'
import { ResearchPlanStatusIcon } from './research-plan-status-icon'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from './ui/alert-dialog'
import { Button } from './ui/button'
import { Checkbox } from './ui/checkbox'

type ProjectTableProps = {
  projectList: Project[]
  loadingMore: boolean
  sorting?: Maybe<ProjectListSorting>
  rowSelection?: RowSelectionState
  onDelete(item: Project): void
  onBulkDelete(itemList: Project[]): void
  onDublicate(item: Project): void
  onUpdate(item: Project): void
  onLoadMore(): void
  onSortingChange(item: ProjectListSorting): void
  onRowSelectionChange(value: Updater<RowSelectionState>): void
}

export function ProjectTable({
  projectList,
  loadingMore,
  sorting,
  rowSelection = {},
  onDelete,
  onDublicate,
  onLoadMore,
  onSortingChange,
  onUpdate,
  onBulkDelete,
  onRowSelectionChange
}: ProjectTableProps) {
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState(0)
  const { isMd } = useBreakpoint()
  const [bulkDeleteOpen, setBulkDeleteOpen] = useState(false)

  const menuOpened: boolean = !isNil(rowSelection) && !isEmpty(filter(Boolean, rowSelection))

  function handleDeleteProject(value: Project) {
    onDelete(value)
  }

  function handleBulkDelete() {
    setBulkDeleteOpen(F)
    onBulkDelete(getSelectedProjectList(rowSelection))
  }

  function getSelectedProjectList(selection: RowSelectionState): Project[] {
    const selectedProjectIdList: string[] = filter(
      complement(isEmpty),
      chain<[string, boolean], string>((pair) => {
        if (pair[1]) {
          return [pair[0]]
        }
        return ['']
      }, toPairs(selection))
    )
    const selectedProjectList: Project[] = filter(
      complement(isNil),
      map((item) => find((p) => p.id === item, projectList), selectedProjectIdList)
    )
    return selectedProjectList
  }

  function handleDuplicateProject(value: Project) {
    onDublicate(value)
  }

  function handleUpdateProject(value: Project) {
    onUpdate(value)
  }

  const columns: ColumnDef<Project>[] = isMd
    ? [
        {
          accessorKey: 'select-col',
          size: 10,
          maxSize: 10,
          header({ table }) {
            return (
              <Checkbox
                checked={table.getIsSomeRowsSelected() ? 'indeterminate' : table.getIsAllRowsSelected()}
                onCheckedChange={(value) => {
                  const event = { target: { checked: value } }
                  const handler = table.getToggleAllRowsSelectedHandler() // or getToggleAllPageRowsSelectedHandler
                  handler(event)
                }}
              />
            )
          },
          cell({ row }) {
            return (
              <div className="px-4">
                <Checkbox
                  checked={row.getIsSelected()}
                  disabled={!row.getCanSelect()}
                  onCheckedChange={row.getToggleSelectedHandler()}
                />
              </div>
            )
          }
        },
        {
          accessorKey: 'title',
          size: 100,
          header({ column }) {
            return <SortableTableHeader column={column}>Project</SortableTableHeader>
          },
          cell({ row }) {
            const { original } = row
            return (
              <div className="flex items-center p-4">
                <Link
                  to={{
                    pathname: `${routePath.project}/${original.id}/chat`
                  }}
                  className="w-full block"
                >
                  {row.getValue('title')}
                </Link>
              </div>
            )
          }
        },
        {
          id: 'status',
          cell({ row: { original } }) {
            return <ResearchPlanStatusIcon status={original.researchPlanStatus} />
          }
        },
        {
          accessorKey: 'updatedAt',
          header({ column }) {
            return <SortableTableHeader column={column}>Last modified</SortableTableHeader>
          },
          cell({ row }) {
            const parsed: Date = row.getValue('updatedAt')
            return <span className="px-4">{formatDateAgo(parsed, new Date())}</span>
          }
        },
        {
          id: 'actions',
          cell(ctx) {
            return (
              <ProjectMenu
                project={ctx.row.original}
                onDelete={handleDeleteProject}
                onDuplicate={handleDuplicateProject}
                onUpdate={handleUpdateProject}
              >
                <Button variant="ghost" size="icon" className="mx-4">
                  <DotsVerticalIcon />
                </Button>
              </ProjectMenu>
            )
          }
        }
      ]
    : [
        {
          accessorKey: 'select-col',
          maxSize: 10,
          header({ table }) {
            return (
              <Checkbox
                checked={table.getIsSomeRowsSelected() ? 'indeterminate' : table.getIsAllRowsSelected()}
                onCheckedChange={(value) => {
                  const event = { target: { checked: value } }
                  const handler = table.getToggleAllRowsSelectedHandler() // or getToggleAllPageRowsSelectedHandler
                  handler(event)
                }}
              />
            )
          },
          cell({ row }) {
            return (
              <div className="px-4">
                <Checkbox
                  checked={row.getIsSelected()}
                  disabled={!row.getCanSelect()}
                  onCheckedChange={row.getToggleSelectedHandler()}
                />
              </div>
            )
          }
        },
        {
          accessorKey: 'title',
          header() {
            return 'All'
          },
          cell({ row }) {
            const { original } = row
            return (
              <div>
                <div className="flex items-center p-4 gap-2">
                  <Link
                    to={{
                      pathname: `${routePath.project}/${original.id}/chat`
                    }}
                    className="pb-0 w-full block"
                  >
                    {row.getValue('title')}
                  </Link>
                  <div className="w-10 flex items-center justify-center">
                    <ResearchPlanStatusIcon status={original.researchPlanStatus} />
                  </div>
                </div>
                <div className="flex justify-between items-center px-4">
                  <span className="text-xs">Updated {formatDateAgo(original.updatedAt, new Date())}</span>
                  <ProjectMenu
                    project={original}
                    onDelete={handleDeleteProject}
                    onDuplicate={handleDuplicateProject}
                    onUpdate={handleUpdateProject}
                  >
                    <Button variant="ghost" size="icon">
                      <DotsVerticalIcon />
                    </Button>
                  </ProjectMenu>
                </div>
              </div>
            )
          }
        }
      ]

  useEffect(() => {
    setTableHeight(() => {
      if (!tableContainerRef.current) {
        return 0
      }
      return tableContainerRef.current.getBoundingClientRect().height
    })
  }, [])

  function handleSortingChange(value: ColumnSort) {
    onSortingChange(mapColumnSortToProjectSort(value))
  }

  function handleRowSelectionChange(valueUpdater: Updater<RowSelectionState>) {
    onRowSelectionChange(valueUpdater)
  }

  return (
    <div className="grow" ref={tableContainerRef}>
      <div className="h-12">
        {menuOpened && (
          <AlertDialog open={bulkDeleteOpen} onOpenChange={setBulkDeleteOpen}>
            <AlertDialogTrigger asChild>
              <Button size="icon" variant="outline">
                <Trash size={18} />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Delete selected projects</AlertDialogTitle>
                <AlertDialogDescription />
              </AlertDialogHeader>
              Selected projects will be permanently removed
              <Button variant="destructive" className="max-md:mb-2" onClick={() => handleBulkDelete()}>
                Delete
              </Button>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </div>
      <DataTable
        className="border bg-white border-white"
        columns={columns}
        data={projectList}
        style={{ height: tableHeight, overflowY: 'auto' }}
        loadingMore={loadingMore}
        sorting={mapProjectSortToColumnSort(sorting)}
        rowSelection={rowSelection}
        onScrollToBottom={onLoadMore}
        onSortingChange={handleSortingChange}
        onRowSelectionChange={handleRowSelectionChange}
      />
    </div>
  )
}
