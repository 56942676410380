import { AuthPageLayout } from '@/components/auth-page-layout'
import { Button } from '@/components/ui/button'
import { routePath } from '@/router/route-path'
import { Link } from 'react-router-dom'

export function EmailVerificationPage() {
  return (
    <AuthPageLayout title="Email verification">
      <div className="h-10" />
      <div className="mb-4">Your email has been successfully verified</div>
      <div className="text-center">
        <Button asChild>
          <Link to={{ pathname: routePath.home }}>Go to Octagon</Link>
        </Button>
      </div>
    </AuthPageLayout>
  )
}
