import { Button } from '@/components/ui/button'
import IconGoogle from '@/assets/icon-google.svg'
import IconMicrosoft from '@/assets/icon-microsoft.svg'
import { Separator } from '@/components/ui/separator'
import { Link, Route, Routes } from 'react-router-dom'
import { routePath } from '@/router/route-path'
import { AuthPageLayout } from '@/components/auth-page-layout'
import { SignupEmailPage } from './signup-email'
import { match } from 'ts-pattern'
import { useContext } from 'react'
import { AuthContext } from '@/components/auth-provider'
import { useEnv } from '@/hooks/use-env'

export function SignupPage() {
  const authClient = useContext(AuthContext)
  const env = useEnv()

  function handleLoginWith(provider: 'microsoft' | 'google') {
    return function () {
      const redirectTo = `${window.origin}${routePath.home}`
      match(provider)
        .with('google', () => {
          authClient?.client?.auth.signInWithOAuth({
            provider: 'google',
            options: {
              redirectTo
            }
          })
        })
        .with('microsoft', () => {
          authClient?.client?.auth.signInWithOAuth({
            provider: 'azure',
            options: {
              redirectTo,
              scopes: 'email'
            }
          })
        })
        .exhaustive()
    }
  }

  return (
    <Routes>
      <Route
        path=""
        element={
          <AuthPageLayout title="Create account" description="Start your Professional plan. Cancel any time">
            <div className="h-10" />
            <Button
              variant="outline"
              className="w-full flex justify-center mb-5"
              onClick={handleLoginWith('microsoft')}
            >
              <img src={IconMicrosoft} className="mr-[-24px] w-5 h-5" />
              <span className="flex-grow">Sign up with Microsoft</span>
            </Button>
            <Button variant="outline" className="w-full flex justify-center mb-5" onClick={handleLoginWith('google')}>
              <img src={IconGoogle} className="mr-[-24px] w-5 h-5" />
              <span className="flex-grow">Sign up with Google</span>
            </Button>
            <div className="flex items-center gap-2 mb-5">
              <Separator className="w-min flex-grow" />
              <div className="text-purple text-xs">or</div>
              <Separator className="flex-grow w-min" />
            </div>

            <Button asChild className="w-full mb-4" data-testid="btn-signup-with-email">
              <Link to={routePath.signupEmail}>Sign up with email</Link>
            </Button>

            <div className="text-sm text-center text-purple mb-3">
              Signing up for an Octagon account means you agree to the{' '}
              <a href={env.APP_PRIVACY_POLICY_URL} target="_blank" className="text-blue-400 underline">
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href={env.APP_TERMS_URL} target="_blank" className="text-blue-400 underline">
                Terms of Service
              </a>
              .
            </div>

            <div className="text-sm text-center text-purple">
              Already have an account?{' '}
              <Link to={routePath.login} className="text-secondary-foreground underline">
                Log in
              </Link>
            </div>
          </AuthPageLayout>
        }
      />
      <Route path="email" element={<SignupEmailPage />} />
    </Routes>
  )
}
