import Counties from '@/assets/counties.json'
import { find } from 'ramda'
import { STATES } from './states'

export type County = {
  County: string
  State: string
  StateCode: string
}

export const COUNTIES: County[] = Counties.map((countyItem) => {
  const state = find((stateItem) => stateItem.Name === countyItem.State, STATES)
  return {
    ...countyItem,
    StateCode: state?.OfficialUSPSCode
  }
}).filter((item): item is County => Boolean(item.County && item.State && item.StateCode))
