import { Maybe } from '@/types'
import cronstrue from 'cronstrue'

export function parseCronExpression(value: string): Maybe<string> {
  try {
    return cronstrue.toString(value)
  } catch (e) {
    return null
  }
}
